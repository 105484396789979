import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationMessageToastStyles } from './informationMessage.style';

export const InformationMessage = ({ group, isChildren }) => {
  const { t } = useTranslation();
  return (
    <InformationMessageToastStyles
      isVisible
      animate={false}
      isChildren={isChildren}
      variant={group.type?.toLowerCase() ?? 'info'}
      header={group.header ? t(group.header) : t(group.text)}
      message={group.header ? t(group.text) : ''}
    />
  );
};
