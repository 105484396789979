import React, { useEffect, useState } from 'react';
import { ChipOption, ErrorMessage, RadioButton } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import { RadioButtonContainer } from '../radioButtonGroup.styles';
import HintModal from '../../hintModal/hintModal';
import { HintFieldContainer } from '../../hintModal/hintModal.styles';

const RadioButtonWrapper = ({
  onChange,
  name,
  required,
  values,
  fieldMeta,
  question,
  onQuestionChange,
  validationHelpers,
  disabled,
  noGroupsRendered,
  isInModal
}) => {
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;
  const [defaultValues, setDefaultValues] = useState(values);
  const [isFirst, setIsFirst] = useState(true);
  const { t } = useTranslation();

  const onChangeHandler = (e, label) => {
    const updatedValues = defaultValues.map((prevValue) => {
      return prevValue.optionLabel === label
        ? {
            value: e.target.checked,
            optionLabel: prevValue.optionLabel,
            conditionalGroup: prevValue.conditionalGroup,
            optionValue: prevValue.optionValue
          }
        : {
            value: false,
            optionLabel: prevValue.optionLabel,
            conditionalGroup: prevValue.conditionalGroup,
            optionValue: prevValue.optionValue
          };
    });
    setDefaultValues(updatedValues);
    onChange(updatedValues);
  };

  useEffect(() => {
    if (isFirst && values) {
      setDefaultValues(values);
      setIsFirst(false);
    }
    onQuestionChange(question, 'add');
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [defaultValues]);

  useEffect(() => setDefaultValues(values), [values]);

  return (
    <HintFieldContainer>
      <RadioButtonContainer noGroupsRendered={noGroupsRendered} row={defaultValues.length <= 2}>
        {defaultValues &&
          defaultValues.map((option) => (
            <ChipOption
              name={name}
              key={option.optionLabel}
              id={question.questionKey}
              onChange={(e) => onChangeHandler(e, option.optionLabel)}
              required={required}
              checked={option.value}
              validationHelpers={validationHelpers}
              disabled={disabled}
              on={isInModal ? 'light' : 'dark'}
            >
              {t(option.optionLabel)}
            </ChipOption>
          ))}
      </RadioButtonContainer>
      <ErrorMessage
        style={{ paddingTop: '0.5rem', marginLeft: '1rem' }}
        hidden={!hasValidationMessages || !showValidationMessage}
      >
        {message}
      </ErrorMessage>
      <HintModal hint={question.hint ? question.hint : ''} />
    </HintFieldContainer>
  );
};

export default RadioButtonWrapper;
