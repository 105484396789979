import styled from 'styled-components';

export const ClientConsentContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  & > :first-child {
    height: 2rem;
    position: absolute;
    width: unset;
    & > label {
      margin-bottom: 1rem;
      & > span {
        & > svg {
          height: unset;
          width: unset;
        }
      }
    }
  }

  & > :not(:first-child) {
    padding-left: 1rem;
  }
`;

export const ClientConsentText = styled.div`
  margin-left: 2rem;

  & > button {
    margin-top: 0px;
  }
`;
