import { OnboardingFlowConstants } from './constants';

export const OnboardingFlowReducer = function (state = {}, action) {
  switch (action.type) {
    case OnboardingFlowConstants.IS_USER_LOGGED_IN_SUCCESS:
      return {
        ...state,
        logged: true
      };
    case OnboardingFlowConstants.IS_USER_LOGGED_IN_ERROR:
      return {
        ...state,
        logged: false
      };

    case OnboardingFlowConstants.SET_DOCUMENT_ID_SUCCESS:
      return {
        ...state,
        documentId: action.payload
      };

    case OnboardingFlowConstants.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    case OnboardingFlowConstants.RESET_QUESTIONNAIRE_FLOW_STATE:
      return {
        ...state,
        logged: false,
        documentId: null
      };

    case OnboardingFlowConstants.SET_TRACE_ID:
      return {
        ...state,
        traceId: action.payload
      };

    default:
      return state;
  }
};
