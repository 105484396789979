import React, { useEffect, useState } from 'react';
import {
  AllCompletedText,
  BackButtonStyle,
  ButtonStyles,
  LoaderStyles,
  LoadingContainer,
  RedirectContentContainer,
  RedirectHeader,
  RedirectLinkStyles,
  RedirectSubheader,
  RepresentativeContent,
  RepresentativeLogo,
  RepresentativeTitle,
  StatusChipPending,
  StatusChipSuccess,
  StepDescriptionStyles,
  StepperCard,
  StepperContainer
} from './Stepper.styles';
import {
  RepresentativeCompany,
  RepresentativePerson,
  XLogo
} from '../../../../Assets/images/logos';
import { Button } from '@single-platform/button';
import { LinkButton, Loader } from '@single-platform/components';
import { Trans, useTranslation } from 'react-i18next';
import { history } from '../../../../Config';

export const Stepper = ({ steps, verificationCallback }) => {
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [countdown, setCountdown] = useState(5);
  const [intervalId, setIntervalId] = useState(null);

  const { t } = useTranslation();

  const getIcon = (step) => {
    switch (step.icon) {
      case 'RepresentativePerson':
        return <RepresentativePerson />;
      default:
        return <RepresentativeCompany />;
    }
  };

  const isBothStepsCompleted = steps.every((step) => step.completed);

  const handleButtonClick = async (buttonLink, action) => {
    if (action === 'redirect') {
      if (buttonLink === 'redirect_login') {
        return (window.location.href = process.env.REACT_APP_LOGIN_HREF);
      }
      setLoading(true);
      await verificationCallback();
    }
  };

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(intervalId);
      window.location.href = redirectUrl;
    }
  }, [countdown, intervalId, redirectUrl]);

  useEffect(() => {
    if (steps[0].buttonLink) {
      setRedirectUrl(steps[0].buttonLink);
      setCountdown(5);
      const id = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setLoading(false);
      setCountdown(5);
    }
  }, [steps]);

  return (
    <>
      {!loading && (
        <StepperContainer>
          {steps.map((step, index) => {
            return (
              <StepperCard isActive={step.isActive} key={index}>
                <RepresentativeContent>
                  <RepresentativeLogo isActive={step.isActive}>{getIcon(step)}</RepresentativeLogo>
                  <RepresentativeTitle isActive={step.isActive}>
                    {t(step.title)}
                  </RepresentativeTitle>
                  {step.completed && <StatusChipSuccess>{t('status_success')}</StatusChipSuccess>}
                  {step.pending && <StatusChipPending>{t('status_pending')}</StatusChipPending>}
                </RepresentativeContent>
                {step.isButtonActive && (
                  <ButtonStyles onClick={() => handleButtonClick(step.buttonLink, 'redirect')}>
                    {t(step.buttonTitle)}
                  </ButtonStyles>
                )}
                {step.description && step.isActive && (
                  <div>
                    <StepDescriptionStyles>{t(step.description)}</StepDescriptionStyles>
                  </div>
                )}
              </StepperCard>
            );
          })}
          {isBothStepsCompleted && (
            <AllCompletedText>{t('representative_business_profile_all_done')}</AllCompletedText>
          )}
        </StepperContainer>
      )}
      {loading && (
        <LoadingContainer>
          <BackButtonStyle>
            <Button onClick={() => handleButtonClick('', 'cancel')}>
              <XLogo />
            </Button>
          </BackButtonStyle>
          <LoaderStyles>
            <Loader />
          </LoaderStyles>
          <RedirectContentContainer>
            <RedirectHeader>{t('representative_business_profile_ondato_header')}</RedirectHeader>
            <RedirectSubheader>
              {t('representative_business_profile_ondato_subheader')}
            </RedirectSubheader>
            <Button>
              <Trans
                t={t}
                i18nKey="representative_business_profile_ondato_button"
                values={{ countdown: countdown }}
              />
            </Button>
          </RedirectContentContainer>
          <RedirectLinkStyles>
            {t('representative_business_profile_ondato_link')}
            <LinkButton
              onClick={() => {
                window.location.href = redirectUrl;
              }}
            >
              {t('representative_business_profile_ondato_link_button')}
            </LinkButton>
          </RedirectLinkStyles>
        </LoadingContainer>
      )}
    </>
  );
};
