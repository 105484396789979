import { LogoLoaderContainer } from '@single-platform/components/dist/components/loader/loader.styles';
import styled from 'styled-components';

export const LoaderTextStyles = styled.p`
  position: relative;
  z-index: 100000;
  color: #4d4d4d;
  font-size: 1rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
`;

export const LoaderContainer = styled.div`
  z-index: 13000;
  position: relative;
  text-align: center;
  height: 100%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  margin: 2rem;
  margin-top: 5rem;

  @media screen and (max-width: 1250px) {
    margin-top: 6rem;
  }

  @media screen and (max-width: 600px) {
    margin-top: 9rem;
  }
`;

export const LoaderWrapper = styled(LogoLoaderContainer)`
  position: relative;
  margin-left: 0rem;
  border-radius: 1rem;
  div {
    div {
      margin-left: 0.5rem;
    }
  }
`;
