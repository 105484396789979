import styled from 'styled-components';

export const LoginBubbleStyles = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.base.primary.shades[10]};
  padding: 6.25rem 6.25rem 6.25rem 8.5rem;
  border-radius: 0.9375rem;
  width: 30.8rem;
  margin: 0px 0px 0px -2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 34.25rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    width: 100%;
    padding: 1.25rem;
    margin: 0;
    margin-top: 10px;
    max-width: none;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    display: none;
  }
`;
