import { colors } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const SectionTitle = styled.h3`
  margin-bottom: ${({ hasSubtitle }) => (hasSubtitle ? '0.5rem' : '1rem')};
  word-break: break-word;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  color: ${() => colors.base.black[90]};

  @media (max-width: 950px) {
    &:nth-child(2) {
      margin-top: 1rem;
    }
  }
`;

export const SectionSubtitle = styled.h4`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${() => colors.base.black[60]};
  margin-bottom: 1rem;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 950px) {
    padding: 0;
  }
`;
