import styled from 'styled-components';
import { Button } from '@single-platform/button';
import { responsiveBreakPoints } from '../../../../Containers/ApplicationKYC/LegalEntityQuestionnaire/styled-theme-styles/styled-components.d';

export const StructureContainerWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  height: auto;
  flex-direction: column;
  padding: 2.5rem 1rem;
  align-items: center;
  justify-content: center;
`;
export const TextWrapper = styled.div`
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  max-width: 16rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const StructureTitle = styled.h1`
  font-size: 1.125rem;
  font-weight: 31.25rem;
  line-height: 1.5rem;
  text-align: center;
`;
export const StructureDescription = styled.p`
  font-size: 1rem;
  font-weight: 25rem;
  line-height: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
`;
export const FullWidthButton = styled(Button)`
  width: 100%;
  max-width: 16rem;

  @media (${responsiveBreakPoints.maxPhone}) {
    width: 100%;
  }
`;
