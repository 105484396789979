import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field } from '@single-platform/components';
import {
  GroupItems,
  GroupsContainer,
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';
import { shouldRenderConditionalGroup } from '../../conditionalGroupsService/conditionalGroups.service';
import { RenderGroup } from '../../screens/renderGroup/renderGroup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CheckBoxGroupTitleStyles } from './checkboxGroup.styles';
import { CheckMarkLogo, SmeBankCheckmark } from '../../../../../Assets/images/logos';
import { CheckboxGroupWrapper } from './checkboxGroupWrapper/checkboxGroupWrapper';

const CheckboxGroup = ({
  options,
  onChange,
  name,
  required,
  value,
  question,
  conditions,
  errors,
  onInputChange,
  onQuestionChange,
  validationHelpers,
  disabled,
  isInModal
}) => {
  const [defaultValues, setDefaultValues] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [errorInfo, setErrorInfo] = useState({});
  const selectedValues = useRef([]);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const { t } = useTranslation();

  const onChangeHandler = (event, label, value) => {
    const updatedValues = defaultValues.map((prevValue) => {
      return prevValue.optionLabel === label
        ? {
            value: event.target.checked,
            optionLabel: prevValue.optionLabel,
            conditionalGroup: prevValue.conditionalGroup,
            optionValue: prevValue.optionValue
          }
        : prevValue;
    });
    setDefaultValues(updatedValues);

    // If clicked checkbox is included in selected Values it is being removed, else it is being added
    if (selectedValues.current.includes(value)) {
      selectedValues.current = selectedValues.current.filter(
        (selectedValue) => selectedValue !== value
      );
    } else {
      selectedValues.current = [...selectedValues.current, value];
    }
    onChange(
      updatedValues
        .filter((option) => option.value && option.optionLabel)
        .map((option) => option.optionValue)
    );
  };

  useEffect(() => {
    onQuestionChange(question, 'add');
    if (isFirst) {
      let condGroup;
      let values = value.split('|');

      setDefaultValues(
        options?.map((option) => {
          condGroup = _.find(
            conditions,
            (conditionalGroup) => conditionalGroup.condition.value === option.value
          );
          return {
            value: values.includes(option.value),
            optionLabel: option.label,
            conditionalGroup: condGroup,
            optionValue: option.value
          };
        })
      );
      if (value) {
        const valueOption = options?.find((option) => option.value === value);
        if (valueOption) selectedValues.current = [valueOption.value];
      }
      setIsFirst(false);
    }
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [defaultValues]);

  const errorInfoCallback = useCallback((showValidationMessage, hasValidationMessages, message) => {
    setErrorInfo({ hasValidationMessages, showValidationMessage, message });
  }, []);

  return (
    <GroupsContainer isCheckboxGroup={true}>
      <GroupItems isGroup={true}>
        {t(question.title) && (
          <CheckBoxGroupTitleStyles>
            {question.required
              ? t(question.title)
              : t(question.title) + t('question_optional_label')}
          </CheckBoxGroupTitleStyles>
        )}
        {(question?.header || question?.subheader) && (
          <QuestionTitleContainer>
            {question?.header && (
              <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
            )}
            {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
          </QuestionTitleContainer>
        )}
        {defaultValues.map((option) => (
          <Fragment key={option.optionLabel}>
            <Field
              Component={CheckboxGroupWrapper}
              variant="checkbox"
              name={name}
              on={isInModal ? 'light' : 'dark'}
              key={option.optionLabel}
              id={question.questionKey}
              question={question}
              onChange={(event) => onChangeHandler(event, option.optionLabel, option.optionValue)}
              errorInfoCallback={errorInfoCallback}
              required={required}
              checked={option.value}
              value={value}
              validationHelpers={validationHelpers}
              disabled={disabled}
              option={option}
            >
              {option.value && (isBank ? <SmeBankCheckmark /> : <CheckMarkLogo />)}
              {t(option.optionLabel)}
            </Field>
            {option.conditionalGroup?.groups.map(
              (group) =>
                option.value &&
                shouldRenderConditionalGroup(
                  option.optionValue,
                  option.conditionalGroup.condition
                ) && (
                  <RenderGroup
                    onInputChange={onInputChange}
                    key={group.id}
                    errors={errors}
                    onQuestionChange={onQuestionChange}
                    group={group}
                    validationHelpers={validationHelpers}
                    isInModal={true}
                  />
                )
            )}
          </Fragment>
        ))}
        <ErrorMessage
          style={{ paddingTop: '0.5rem', marginLeft: '1rem' }}
          hidden={!errorInfo.hasValidationMessages || !errorInfo.showValidationMessage}
        >
          {errorInfo.message}
        </ErrorMessage>
      </GroupItems>
    </GroupsContainer>
  );
};

export default CheckboxGroup;
