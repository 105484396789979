import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import {
  translationReducer,
  KYCReducers,
  ToolsReducers,
  AuthReducers,
  FormStepsReducer,
  QuestionnaireReducer,
  OnboardingFlowReducer
} from '../Services';
import { languageReducer } from '../Local';

export const rootReducer = combineReducers({
  FormStepsReducer,
  translationReducer,
  KYCReducers,
  languageReducer,
  ToolsReducers,
  oidcReducer,
  AuthReducers,
  QuestionnaireReducer,
  OnboardingFlowReducer
});
