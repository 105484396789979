import { createSelector } from 'reselect';

export const selectStepperFormState = createSelector(
  (state) => state,
  (state) => state?.StepperFormReducer
);

export const selectStepperFormDraft = createSelector(
  selectStepperFormState,
  (state) => state.formDraft
);

export const selectIsFormDraftLoading = createSelector(
  selectStepperFormState,
  (state) => state.loading.isFormDraftLoading
);

export const selectIsFormDraftSent = createSelector(
  selectStepperFormState,
  (state) => state.loading.isFormDraftSent
);

export const selectCurrentStep = createSelector(
  selectStepperFormState,
  (state) => state.currentStep
);

export const selectFinishedSteps = createSelector(
  selectStepperFormDraft,
  (state) => state.finishedSteps
);

export const selectisInfoBubbleVisible = createSelector(
  selectStepperFormState,
  (state) => state.showInfo
);
