import { store, AuthActions } from '../Redux';
import { history, routerPath } from '../Config';
import { redirectToSmeGO } from '../PageParts';

export const Logout = async (isError) => {
  const { dispatch } = store;
  const smeGoSession = sessionStorage.getItem('smeGoSession');
  const isMobileApp = sessionStorage.getItem('mobileapp');

  if (smeGoSession && !isMobileApp) {
    redirectToSmeGO(isError);
    sessionStorage.clear();
    dispatch(AuthActions.resetSSOLogin());
    return;
  }

  await dispatch(AuthActions.reset());
  const url = new URL(window.location.href);
  if (url.pathname === routerPath.login || url.pathname === routerPath.redirectEIdEasy) {
    history.push(url.pathname);
  } else {
    history.push(routerPath.login);
  }
};
