import { useEffect, useState } from 'react';
import { lt, enUS } from 'date-fns/locale';
import i18next from 'i18next';

const getLocale = (language) => {
  switch (language) {
    case 'lt-LT':
      return lt;
    case 'en-GB':
      return enUS;
    default:
      return enUS;
  }
};

export const useLocale = () => {
  const [locale, setLocale] = useState(getLocale(localStorage.getItem('language') || 'lt-LT'));

  useEffect(() => {
    const handleLanguageChange = (language) => {
      const newLocale = getLocale(language);
      setLocale(newLocale);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return locale;
};
