import json from '../Config/config';
import { store } from '../Redux';

let language = store.getState().languageReducer.language;

const getLanguage = () => (language = store.getState().languageReducer.language);
store.subscribe(getLanguage);

export const numberFormat = () => ({
  currency: json[language].format.numberFormat.currency,
  currencyWithMinus: json[language].format.numberFormat.currencyWithMinus,
  percent: json[language].format.numberFormat.percent2decimal,
  percent3decimal: json[language].format.numberFormat.percent3decimal,
  percent4decimal: json[language].format.numberFormat.percent4decimal,
  percent5decimal: json[language].format.numberFormat.percent5decimal,
  coefficient: json[language].format.numberFormat.coefficient2decimal,
  coefficient3decimal: json[language].format.numberFormat.coefficient3decimal,
  coefficient4decimal: json[language].format.numberFormat.coefficient4decimal,
  coefficient6decimal: json[language].format.numberFormat.coefficient6decimal,
  coefficient3decimalTrim: json[language].format.numberFormat.coefficient3decimalTrim,
  number: json[language].format.numberFormat.number,
  numberCode: json[language].format.numberFormat.numberCode,
  date: json[language].format.numberFormat.date,
  phone: json[language].format.numberFormat.phone,
  period: json[language].format.numberFormat.period
});

export const dateFormat = () => ({
  date: json[language].format.formatDate.date,
  dateTime: json[language].format.formatDate.dateTime,
  dateTimeSecond: json[language].format.formatDate.dateTimeSecond,
  time: json[language].format.formatDate.time
});

export const groupBy = (xs, f) => {
  return xs.reduce((r, v, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};
