export const STORAGE_ITEMS = {
  LOGIN_TOKEN: 'loginToken',
  SCA_TIMEOUT: 'scaTimeout',
  SIGNIN_TOKEN: 'signinToken',
  LANGUAGE: 'language'
};

export const SESSIONS_STORAGE_ITEMS = {
  USER: 'user'
};
