import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../../../Redux';
import { Loader } from '@single-platform/components';
import { allLoginStatus } from '../../../../Config';
import { Logout } from '../../../../Utilities';
import { OtpForm } from '../otp/otpForm';
import { SigningModal } from '../../../../Components/Signing/SigningModal';

const getLoginMethod = (country) => {
  switch (country) {
    case 'NL':
      return 'idin-login';
    case 'FI':
      return 'ftn-login';
    case 'LV':
      return 'lv-eparaksts-mobile-login';
    default:
      return '';
  }
};

const INITIAL_MODAL_STATE = {
  isOpen: false,
  modalType: null
};

export const EIDeasy = ({ personalData, setSubmitStatus, step }) => {
  const dispatch = useDispatch();
  const [, setIsRedirecting] = useState(false);
  const [requiresOtp, setRequiresOtp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [isLoading, setIsLoading] = useState(false);

  const loginMethod = getLoginMethod(personalData.country);

  const initiateRedirect = async () => {
    try {
      const data = {
        language: '',
        email: personalData.email,
        country: personalData.country,
        loginMethod: loginMethod,
        personalCode: '',
        phoneNumber: ''
      };

      setIsRedirecting(true);

      const result = await dispatch(AuthActions.eideasyInit(data));
      if (result.payload.redirectUrl) {
        setRedirectUrl(result.payload.redirectUrl);
        localStorage.setItem(
          'eidEasy',
          JSON.stringify({
            sessionId: result.payload.sessionId,
            accessToken: result.payload.token,
            step: 'eideasy'
          })
        );
        sessionStorage.setItem('loginSessionId', result.payload.sessionId);
      }
    } catch {
      setIsRedirecting(false);
      setRedirectUrl('');
      onFailHandle();
    }
  };

  const initiateLogin = async () => {
    setIsLoading(true);
    try {
      let redirectParams = JSON.parse(localStorage.getItem('redirectParams'));
      const mappedParams = {
        code: redirectParams.code,
        sessionId: redirectParams.state
      };
      const result = await dispatch(AuthActions.authorizationStatusEIdEasy(mappedParams));

      sessionStorage.setItem(
        'token',
        JSON.stringify({
          accessToken: result.payload.token,
          expiresIn: result.payload.expiresIn,
          refreshToken: result.payload.refreshToken
        })
      );

      const { sessionId } = JSON.parse(localStorage.getItem('eidEasy'));
      sessionStorage.setItem('loginSessionId', sessionId);

      if (result?.payload?.emailOtpRequired) {
        setIsLoading(false);
        setRequiresOtp(true);
      } else if (result) {
        setIsLoading(false);
        await authorizedCompanies();
      }
    } catch (e) {
      setIsLoading(false);
      localStorage.removeItem('eidEasy');
      localStorage.removeItem('redirectParams');
      onFailHandle();
    } finally {
      setIsLoading(false);
      localStorage.removeItem('eidEasy');
      localStorage.removeItem('redirectParams');
    }
  };

  const onFailHandle = async () => {
    sessionStorage.clear();
    setModal(INITIAL_MODAL_STATE);
    await Logout();
    setSubmitStatus({
      isSubmitting: false,
      type: null
    });
  };

  const authorizedCompanies = async () => {
    const result = await dispatch(AuthActions.getAuthorizedCompanies());
    if (result) {
      switch (result.payload.status) {
        case allLoginStatus.noAuthorizeCompany:
          setModal({
            isOpen: true,
            modalType: allLoginStatus.noAuthorizeCompany
          });
          break;
        case allLoginStatus.selectCompany:
          setModal({
            isOpen: true,
            modalType: allLoginStatus.selectCompany
          });
          break;
        default:
          break;
      }
    } else this.resetAll();
  };

  useEffect(() => {
    step === 'eideasy' ? initiateLogin() : initiateRedirect();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {redirectUrl ? <meta http-equiv="Refresh" content={`0; url='${redirectUrl}'`} /> : null}
      {modal.isOpen && <SigningModal type={modal.modalType} onCancel={onFailHandle} />}
      {requiresOtp && <OtpForm otpRequest={authorizedCompanies}></OtpForm>}
    </>
  );
};
