import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RefreshToken } from '../../../../../Utilities/refreshToken';
import { Logout } from '../../../../../Utilities';

export const useCompanyRefresh = (isSelectingCompany, setIsInitialLoad) => {
  const dispatch = useDispatch();
  const [intervalCounter, setIntervalCounter] = useState(0);
  const refreshInterval = isSelectingCompany ? 240000 : null;

  useEffect(() => {
    let intervalId;
    if (refreshInterval !== null) {
      intervalId = setInterval(async () => {
        await dispatch(RefreshToken());
        setIntervalCounter((prevCount) => prevCount + 1);
        setIsInitialLoad(true);
      }, refreshInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch, refreshInterval]);

  useEffect(() => {
    if (intervalCounter > 14) {
      Logout();
      window.location.reload();
    }
  }, [intervalCounter]);

  return intervalCounter;
};
