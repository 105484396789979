import { ToolsConstants } from './constants';

export const ToolsReducers = function (state = {}, action) {
  switch (action.type) {
    case ToolsReducers.POST_GENERATE_MARK_ID_PAYLOAD_REQUEST:
      return {
        ...state,
        sendTools: true,
        markIdPayload: null
      };
    case ToolsReducers.POST_GENERATE_MARK_ID_PAYLOAD_SUCCESS:
      return {
        ...state,
        sendTools: false,
        markIdPayload: action.payload
      };
    case ToolsReducers.POST_GENERATE_MARK_ID_PAYLOAD_ERROR:
      return {
        ...state,
        sendTools: false,
        errors: action.error
      };
    case ToolsConstants.GET_COMPANY_NAMES_REQUEST:
      return {
        ...state,
        sendTools: true,
        companyNames: null
      };
    case ToolsConstants.GET_COMPANY_NAMES_SUCCESS:
      return {
        ...state,
        sendTools: false,
        companyNames: action.payload
      };
    case ToolsConstants.GET_COMPANY_NAMES_ERROR:
      return {
        ...state,
        sendTools: false,
        errors: action.error
      };
    case ToolsConstants.CLEAR_ALL_PAYLOAD:
      return {
        ...state,
        markIdPayload: null,
        ondatoPayload: null
      };
    case ToolsReducers.POST_START_ONDATO_IDENTIFICATION_REQUEST:
      return {
        ...state,
        sendTools: true,
        ondatoPayload: null
      };
    case ToolsReducers.POST_START_ONDATO_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        sendTools: false,
        ondatoPayload: action.payload
      };
    case ToolsReducers.POST_START_ONDATO_IDENTIFICATION_ERROR:
      return {
        ...state,
        sendTools: false,
        errors: action.error
      };
    default:
      return state;
  }
};
