import { logError } from '../../../Utilities';
import { AppConfigConstants } from './constants';
import { AppConfigService } from './service';

const getCountryRiskList = (data, isFiltered = false) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.GET_COUNTRY_RISK_LIST_REQUEST });
  try {
    const response = await AppConfigService.getCountryRiskList(data);
    dispatch({
      type: AppConfigConstants.GET_COUNTRY_RISK_LIST_SUCCESS,
      payload: { ...response, isFiltered }
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.GET_COUNTRY_RISK_LIST_ERROR, error });
  }
};

const updateCountryRisk = (data) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.UPDATE_COUNTRY_RISK_REQUEST });
  try {
    await AppConfigService.updateCountryRisk(data);
    dispatch({
      type: AppConfigConstants.UPDATE_COUNTRY_RISK_SUCCESS,
      payload: data
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.UPDATE_COUNTRY_RISK_ERROR, error });
    throw error;
  }
};

const getRiskList = (data, isFiltered = false) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.GET_RISK_LIST_REQUEST });
  try {
    const response = await AppConfigService.getRiskList(data);
    dispatch({
      type: AppConfigConstants.GET_RISK_LIST_SUCCESS,
      payload: { ...response, isFiltered }
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.GET_RISK_LIST_ERROR, error });
  }
};

const updateRiskItem = (data) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.UPDATE_RISK_ITEM_REQUEST });
  try {
    await AppConfigService.updateRiskItem(data);
    dispatch({
      type: AppConfigConstants.UPDATE_RISK_ITEM_SUCCESS,
      payload: data
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.UPDATE_RISK_ITEM_ERROR, error });
    throw error;
  }
};

const getNaceBlockers = (data, isFiltered = false) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.GET_NACE_BLOCKERS_REQUEST });
  try {
    const response = await AppConfigService.getNaceBlockers(data);
    dispatch({
      type: AppConfigConstants.GET_NACE_BLOCKERS_SUCCESS,
      payload: { ...response, isFiltered }
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.GET_NACE_BLOCKERS_ERROR, error });
  }
};

const updateNaceBlockersItem = (data) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.UPDATE_NACE_BLOCKERS_ITEM_REQUEST });
  try {
    await AppConfigService.updateNaceBlockersItem(data);
    dispatch({
      type: AppConfigConstants.UPDATE_NACE_BLOCKERS_ITEM_SUCCESS,
      payload: data
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.UPDATE_NACE_BLOCKERS_ITEM_ERROR, error });
    throw error;
  }
};

const getAllClientRisksScreen = () => async (dispatch) => {
  dispatch({ type: AppConfigConstants.GET_CALCULATE_ALL_CLIENT_RISKS_REQUEST });
  try {
    const response = await AppConfigService.getCalculateAllClientRisks();
    dispatch({
      type: AppConfigConstants.GET_CALCULATE_ALL_CLIENT_RISKS_SUCCESS,
      payload: response
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.GET_CALCULATE_ALL_CLIENT_RISKS_ERROR, error });
  }
};

const updateApplicationSettings = (data) => async (dispatch) => {
  dispatch({ type: AppConfigConstants.UPDATE_APPLICATION_SETTINGS_REQUEST });
  try {
    const response = await AppConfigService.updateApplicationSettings(data);
    dispatch({
      type: AppConfigConstants.UPDATE_APPLICATION_SETTINGS_SUCCESS,
      payload: response
    });
  } catch (error) {
    logError(error);
    dispatch({ type: AppConfigConstants.UPDATE_APPLICATION_SETTINGS_ERROR, error });
  }
};

export const AppConfigActions = {
  getCountryRiskList,
  getRiskList,
  updateCountryRisk,
  updateRiskItem,
  getNaceBlockers,
  updateNaceBlockersItem,
  getAllClientRisksScreen,
  updateApplicationSettings
};
