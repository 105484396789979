import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, NOTIFICATION_TYPES } from '../../../../../Components';
import PdfDocView from '../../../../../Components/PdfDocument/PdfDocView';
import { APP_STATES, LOGIN_TYPES } from '../../../../../Config';
import { KYCActions, selectKycAppState } from '../../../../../Redux';
import { OnboardingFlowHandlerActions } from '../../../../../Redux/Services/OnboardingFlowHandler/actions';
import { BioDocUploadModal } from '../../../Components/BioDocUploadModal/BioDocUploadModal';
import { RefreshToken } from '../../../../../Utilities/refreshToken';
import { LoaderWithText } from '../../components';
import { AuthenticationDialog } from '@single-platform/components';
import { AuthenticationDialogStyles, Container } from './agreementSigning.style';

const signatureTypes = [LOGIN_TYPES.SMART_ID, LOGIN_TYPES.MOBILE];

export const AgreementSigning = () => {
  const [documentInfo, setDocumentInfo] = useState({});
  const [isBiometricsModalOpen, setIsBiometricsModalOpen] = useState(false);
  const [authType, setAuthType] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [applicationId, setApplicationId] = useState('');
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);
  const [challengeCompleted, setChallengeCompleted] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const company = JSON.parse(sessionStorage.getItem('company'));
  const applicationState = useSelector(selectKycAppState);

  const agreementRequiresSigning = () => {
    return authType.some((type) => signatureTypes.includes(type));
  };

  const reload = async () => {
    setIsWaitingForConfirmation(false);
    setChallengeCompleted(true);
    if (company) await dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
  };

  const handleAgreementSigning = async () => {
    const response = await dispatch(KYCActions.startAgreementSigning(authType));
    if (!response.error) {
      setIsWaitingForConfirmation(true);
    } else {
      setDisabled(false);
      reload();
    }
  };

  const startAgreementSigning = async () => {
    if (agreementRequiresSigning()) handleAgreementSigning();
    else setIsBiometricsModalOpen(true);
  };

  const getRestructuredPollingData = (pollingData) => {
    switch (pollingData.status) {
      case 'Pending':
        return {
          state: 'waiting',
          verificationCode: pollingData.verificationCode,
          sessionId: pollingData.verificationCode,
          validUntil: pollingData.validUntil
        };
      case 'Completed':
        return {
          state: 'ok'
        };
      case 'Canceled':
        Notification({
          type: NOTIFICATION_TYPES.WARNING,
          message: t('document_signing_canceled')
        });
        break;
      default:
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('document_signing_failed')
        });
        break;
    }
    return pollingData;
  };

  const initiatePollingData = async () => {
    if (agreementRequiresSigning()) {
      const pollingData = await dispatch(
        KYCActions.signStatus(applicationId, documentInfo?.documentId)
      );
      const restructuredData = getRestructuredPollingData(pollingData.payload);
      if (restructuredData) {
        return restructuredData;
      }
      if (pollingData.payload.state === 'ok') {
        setIsWaitingForConfirmation(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  };

  useEffect(() => {
    if (isFirst && company && !applicationState) {
      dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
      setIsFirst(false);
    }
    if (applicationState) {
      setAuthType(applicationState.authTypes);
      setApplicationId(applicationState.applicationId);
      if (
        applicationState.state === APP_STATES.AGREEMENT_SIGNING_STARTED &&
        agreementRequiresSigning()
      ) {
        setIsWaitingForConfirmation(true);
      }
    } else {
      const tokenRefresh = async () => {
        await dispatch(RefreshToken());
      };
      tokenRefresh();
    }
    if (applicationState?.applicationId && !documentInfo?.documentId) {
      setApplicationId(applicationState.applicationId);
      const requestDocs = async () => {
        try {
          setShowSpinner(true);
          const documentResponse = await dispatch(
            KYCActions.getApplicationDocumentRequestsToSign(applicationState.applicationId)
          );
          setShowSpinner(false);
          const documentRequests = documentResponse.payload.documentRequests;
          const pdf = documentRequests.filter((document) => document.type === 'Agreements')[0];

          setDocumentInfo({ documentId: pdf.id, fileId: pdf.files[0].id });
        } catch (e) {
          setShowSpinner(false);
        }
      };

      requestDocs();
    }
  }, [documentInfo, applicationState]);

  const pollingConfiguration = {
    init: initiatePollingData,
    complete: initiatePollingData
  };

  return showSpinner ? (
    <LoaderWithText text={t('loading_text')} />
  ) : (
    <Container>
      {documentInfo.documentId && (
        <>
          {isWaitingForConfirmation && (
            <AuthenticationDialogStyles>
              <AuthenticationDialog
                hideBackLink
                testId="doc-sign"
                isWaitingForConfirmation={isWaitingForConfirmation}
                onBackHandle={() => {}}
                onSuccessHandle={reload}
                onFailHandle={reload}
                timerLabel={t('smart_Id_timer_label')}
                infoMessage={t('smart_Id_Info_Message')}
                pollingConfig={pollingConfiguration}
                challengeCompleted={challengeCompleted}
              />
            </AuthenticationDialogStyles>
          )}
          <PdfDocView
            applicationId={applicationId}
            documentInfo={documentInfo}
            onClick={startAgreementSigning}
            showButtons={!isWaitingForConfirmation}
            authTypes={authType}
            disabled={disabled}
            showBackButton={false}
          />
          {isBiometricsModalOpen && (
            <BioDocUploadModal
              onComplete={reload}
              onClose={() => {
                setIsBiometricsModalOpen(false);
                setIsWaitingForConfirmation(false);
              }}
              nextAction={APP_STATES.AGREEMENT_SIGNING_STARTED}
            />
          )}
        </>
      )}
    </Container>
  );
};
