import { Modal } from '@single-platform/components';
import styled from 'styled-components';

export const CompanyDisplayContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompaniesContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;

export const CompanyRepresentativeContainer = styled.div`
  max-height: auto;
`;

export const CompanyDisplayContainerWrapper = styled.div`
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    padding: 1.25rem;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const ModalStyles = styled(Modal)`
  z-index: 8000;
`;
