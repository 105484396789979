export const styles = `
#FillOrgStructureContainer.content {
  width: 100%;
}
#FillOrgStructureContainer.content .panel-spaced {
  padding-top: 38px;
  padding-bottom: 60px;
  padding-right: 50px;
}
#FillOrgStructureContainer.content .panel {
  padding: 15px 30px 15px 35px;
}
#FillOrgStructureContainer.content .panel-shadow {
  box-shadow: 0 10px 40px 0px #eaeaea;
}
#FillOrgStructureContainer.content .panel {
  background: #fff;
  padding: 15px 20px;
  margin: 0 0 20px 0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.owners-form {
  margin: 0 0 60px 0;
}
.owners {
  line-height: 1.4;
}
.owners .top {
  display: flex;
}
.owners .left {
  position: relative;
  width: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.owners .dot-company {
  background: #CABCFD;
}
.owners .dot-person {
  background: #9BE4FE;
}
.owners .dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 31px;
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #9BE4FF;
  margin: 0 10px 0 0;
  display: inline-block;
  position: absolute;
}
.owners .corner {
  width: 18px;
  height: 65px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom-left-radius: 8px;
  position: absolute;
  left: 7px;
  top: 100%;
  margin-top: 5px;
}
.owners .line {
  height: 52px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom-left-radius: 8px;
  position: absolute;
  left: 7px;
  top: 100%;
  margin-top: 5px;
}
.owners .outside {
  left: auto;
  right: 100%;
  margin-right: 5px;
  height: 90px;
  top: auto;
  bottom: 7px;
}
.owners .middle {
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  padding: 13px 0 0 0;
}
.owners .owners-name {
  font-size: 16px;
  padding-top: 5px;
}
.owners .owners-type {
  font-size: 12px;
  color: #b2b2b2;
}
.owners .percent {
  color: #b2b2b2;
}
.owners .right {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 0 auto;
}
.owners .add {
  font-size: 12px;
  color: #7154d6;
  cursor: pointer;
  margin: auto 0 19px 0;
}
.owners .owner-inputs {
  width: 100%;
}
.subowners {
  margin-left: 30px;
}
.subsubowners {
  margin-left: 60px;
}
.subsubsubowners {
  margin-left: 90px;
}
.subsubsubsubowners {
  margin-left: 120px;
}
.subsubsubsubsubowners {
  margin-left: 150px;
}
.subsubsubsubsubsubowners {
  margin-left: 180px;
}
.owner-inputs .form-field-wrapper.big-input .form-field-title {
  top: 3px;
  font-size: 12px;
  left: 32px;
}
.sme .eb_content_wrapper .owner-inputs .form-field-wrapper {
  margin-bottom: 0;
}
#orgStructureForm.owner-inputs select {
  padding: 12px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 100%
}
.sme .eb_content_wrapper #FillOrgStructureContainer .owner-inputs .ant-input {
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5715;
  padding: 19px 12px 5px 18px;
}
.sme .eb_content_wrapper #FillOrgStructureContainer .ant-input:focus, .sme .eb_content_wrapper #FillOrgStructureContainer .ant-select:focus, .sme .eb_content_wrapper #FillOrgStructureContainer .ant-picker:focus {
}
#FillOrgStructureContainer .owner-inputs .form-field-wrapper.big-input .form-field-title {
  left: 16px;
}
#orgStructureForm .ant-select-dropdown {
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 200px;
}
#orgStructureForm .form-field-wrapper.big-input.big-input-empty .form-field-title {
  font-size: 14px;
  top: 14px;
}
.btn-big {
  padding: 17px 30px;
  border-radius: 26px;
  font-size: 18px;
  width: 134px;
}
#FillOrgStructureContainer *, *::before, *::after {
  box-sizing: border-box;
  outline: none;
}
.btn-group-right {
  justify-content: flex-end;
}
.file-org-btn-container{
  @media screen and (max-width: 580px) {
  justify-content: space-between;
  }
}
.org-structure-form-col {
  @media screen and (max-width: 580px) {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
.org-btn-container {
  @media screen and (max-width: 580px) {
    margin: 16px 0 16px 8px;
  }
}
.org-structure-form-select-row {
  @media screen and (max-width: 580px) {
    gap: 16px;
  }
}
.btn-group {
  display: flex;
}
.btn-group-vertical>.btn, .btn-group>.btn {
  flex: none;
}
#removable-button {
  visibility: hidden;
}
body {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
}
`;

export const riskListStyles = `
#FillOrgStructureContainer.content {
  width: 100%;
  padding: 45px 55px;
}
#FillOrgStructureContainer.content .general-info-text-container {
  margin-bottom: 16px;
}
#FileRiskListContainer.content .general-info-answer {
  margin-left: 10px;
}
#FileRiskListContainer.content .risk-table-container {
  width: 100%;
  margin-top: 40px;
}
#FileRiskListContainer.content .risk-table-row {
  border-bottom: 1px solid #000000a6;
}
#FileRiskListContainer.content .risk-table-cell {
  padding: 4px;
  font-size: 12px;
}
#FileRiskListContainer.content .risk-table-header-cell {
  padding: 4px;
  font-size: 16px;
}
#FileRiskListContainer.content .score-cell {
  text-align: center;
  vertical-align: top;
}
#FileRiskListContainer.content .risk-table-row-last {
  border-bottom: none;
}
#FileRiskListContainer.content .risk-table {
  width: 100%;
}
body {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
}
`;
