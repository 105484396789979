import { LinkButton } from '@single-platform/components';
import styled from 'styled-components';

export const AuthenticationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AuthenticationContainerStyle = styled.div`
  max-width: 28.25rem;
  width: 100%;
  padding: 2.5rem 2.25rem 6rem 2.25rem;
  box-shadow: 0 0.625rem 2.5rem 0 #eaeaea;
  border-radius: 0.9375rem;
  border: 1px solid #f0f0f0;
  font-size: 0.875rem;
  line-height: 1rem;
  list-style: none;
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 548px;

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 4rem 2.25rem;
    margin: 0;
    justify-content: flex-start;
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding: 2rem;
  }

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`;

export const LinkButtonModified = styled.div`
  ${LinkButton} {
    color: #5b5b5b;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
`;

export const LoginContainerStyles = styled.div`
  display: flex;
  justify-content: center;
  min-width: 400px;
  max-height: 548px;
  margin-top: 5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
    max-width: 28.25rem;
    margin: 6rem auto;
    padding: 10px;
    justify-content: flex-start;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    margin: 5rem auto;
    min-width: auto;
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const LoginHeaderStyles = styled.h2`
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0.9375rem;
  color: ${({ theme }) => theme.colors.primary.headerColor};
  margin-bottom: 2rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  position: sticky;
  width: 100%;
  top: 0px;
  background-color: #f4f4f5;
  z-index: 1000;
  border-bottom: ${(props) => (props.isAtTop ? 'none' : '1px solid #e0e0e0')};
  padding: 0.5rem 1rem;
`;

export const LanguageSelectStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;

  @media screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;
