export const allLoginStatus = {
  pending: 'Pending',
  selectCompany: 'SelectCompany',
  success: 'Success',
  failed: 'Failed',
  noAuthorizeCompany: 'NoAuthorizedCompanies'
};

export const LOGIN_TYPES = {
  MOBILE: 'Mobile',
  SMART_ID: 'SmartId',
  BIOMETRICS: 'Biometrics',
  EIDEASY: 'EIdEasy'
};
