import React, { useState } from 'react';
import { HintContainerStyle, HintModalStyle, MouseOverDiv } from './hintModal.styles';
import { HintLogo } from '../../../../../Assets/images/logos/index';
import { Trans } from 'react-i18next';
import Icon from '../icon/icon';
import { Link } from '@single-platform/components';

const HintModal = ({ hint, isCheckbox }) => {
  const [isShown, setIsShown] = useState(false);

  const openLink = (link) => {
    window.open(link.target.textContent, '_blank');
  };

  return (
    <MouseOverDiv onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      {hint && (
        <HintContainerStyle isCheckbox={isCheckbox}>
          <Icon>
            <HintLogo />
          </Icon>
        </HintContainerStyle>
      )}
      {isShown && (
        <HintModalStyle isCheckbox={isCheckbox}>
          <Trans i18nKey={hint}>
            Text
            <Link onClick={(e) => openLink(e)}>Link</Link>
            Text
          </Trans>
        </HintModalStyle>
      )}
    </MouseOverDiv>
  );
};

export default HintModal;
