import React from 'react';
import { useTranslation } from 'react-i18next';
import { StructureLogo, StructureBankLogo } from '../../../../Assets/images/logos';
import {
  StructureTitle,
  StructureDescription,
  TextWrapper,
  ButtonWrapper,
  FullWidthButton,
  StructureContainerWrapper
} from './StructureModal.style';
import { ModalStyles } from '../CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer.style';

export const StructureModal = ({ onClose, onForward, isModalOpen }) => {
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  return (
    <ModalStyles isOpen={isModalOpen} hideSecondaryAction={true} maxWidth={'25rem'}>
      <StructureContainerWrapper>
        {isBank ? <StructureBankLogo /> : <StructureLogo />}
        <TextWrapper>
          <StructureTitle>{t('structurePopup_title')}</StructureTitle>
          <StructureDescription>{t('structurePopup_description')}</StructureDescription>
        </TextWrapper>
        <ButtonWrapper>
          <FullWidthButton onClick={onClose}>{t('structurePopup_buttonBack')}</FullWidthButton>
          <FullWidthButton variant="Stroked" color="Black" onClick={onForward}>
            {t('structurePopup_buttonForward')}
          </FullWidthButton>
        </ButtonWrapper>
      </StructureContainerWrapper>
    </ModalStyles>
  );
};
