import { createUserManager } from 'redux-oidc';

import { store } from '../Redux/Config';
import { logError } from './logErrors';

const userManagerConfig = {
  authority: process.env.REACT_APP_IDENTITY, //'https://sme-identity.bbservice.lt',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: 'secret',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback.html`,
  response_type: 'token id_token',
  scope: 'openid bbs.profile bbs.rights localization.api onboarding.api',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/silent_redirect.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  clockSkew: 900,
  accessTokenExpiringNotificationTime: 29 * 60
};

const userManager = createUserManager(userManagerConfig);

userManager.events.addUserSignedOut(() => {
  userManager
    .signinRedirect()
    .then(() => {
      //localStorage.clear();
      sessionStorage.clear();
    })
    .catch((err) => {
      logError(err);
    });
});

export { userManager };

export const logout = () => {
  const state = store.getState();
  userManager
    .signoutRedirect({ id_token_hint: state.loginOIDCReducer.user.id_token })
    .then(() => {
      sessionStorage.clear();
      //localStorage.clear();
    })
    .catch((err) => {
      logError(err);
    });
  userManager.removeUser();
};
