import { AsideMenuTopBar } from '@single-platform/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkPopupContentContainer, LinkPopupHeaderStyles } from '../../linkPopup.style';
import { ParagraphStyles } from '../linkPopupDescription/linkPopupDescription.style';

export const LinkPopupDataExchange = ({ onClose, header }) => {
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const { t } = useTranslation();
  return (
    <>
      <LinkPopupHeaderStyles>
        <AsideMenuTopBar handleClose={onClose} />
        <div>{t(header)}</div>
      </LinkPopupHeaderStyles>
      <LinkPopupContentContainer>
        {isBank ? (
          <ParagraphStyles>
            {t('question_agree_to_sell_data_legal_disclaimer_bank')}
          </ParagraphStyles>
        ) : (
          <ParagraphStyles>
            {t('question_agree_to_sell_data_legal_disclaimer_finance')}
          </ParagraphStyles>
        )}
      </LinkPopupContentContainer>
    </>
  );
};
