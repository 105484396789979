import { LinkButton } from '@single-platform/components';
import { Button } from '@single-platform/button';
import styled from 'styled-components';

export const FormSubheading = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1.25rem;

  ${LinkButton} {
    color: black;
  }
`;

export const FormButton = styled(Button)`
  width: 100%;
  margin-top: 2.5rem;
`;

export const FormStyles = styled.form`
  margin: 0;
`;
