import React from 'react';
import ViewSDKClient from './ViewSDKClient';

export class PdfDocument extends React.Component {
  componentDidMount() {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile('pdf-div', {
        /* Pass the embed mode option here */
        embedMode: 'SIZED_CONTAINER',
        apiKey: process.env.REACT_APP_ADOBE_ID,
        fileId: this.props.file,
        url: `${process.env.REACT_APP_API}Document/Get/${this.props.file}`,
        fileName: this.props.file
      });
    });
  }

  render() {
    return (
      <div
        id="pdf-div"
        className="sized-container-div"
        style={{
          height: '800px',
          width: '100%',
          border: '1px solid #dadada'
        }}
      />
    );
  }
}
