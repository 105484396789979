import React from 'react';
import { ButtonSectionStyle } from './buttonSection.styles';
import { Field } from '@single-platform/components';
import { Button } from '@single-platform/button';
import useWindowDimensions from '../../Utils/window-size.service';
import { useSelector } from 'react-redux';

export const ButtonSection = ({
  firstButtonClick,
  secondButtonClick,
  firstButtonText,
  secondButtonText,
  isRepeatable,
  firstButtonPrimary,
  secondButtonPrimary,
  secondButtonDisabled,
  alignEnd,
  firstButtonDisabled
}) => {
  const { width } = useWindowDimensions();
  const questionnaireState = useSelector((state) => state.QuestionnaireReducer);

  return (
    <ButtonSectionStyle
      small={width < 450}
      oneButton={!firstButtonText || !secondButtonText}
      isRepeatable={isRepeatable}
      alignEnd={alignEnd}
    >
      {firstButtonText && (
        <Field
          disabled={questionnaireState.loading || firstButtonDisabled}
          onClick={firstButtonClick}
          variant={!firstButtonPrimary && 'Stroked'}
          color={!firstButtonPrimary ? 'Black' : 'unset'}
          primary={firstButtonPrimary}
          Component={Button}
        >
          {firstButtonText}
        </Field>
      )}
      {secondButtonText && (
        <Field
          disabled={questionnaireState.loading || secondButtonDisabled}
          onClick={secondButtonClick}
          variant={!secondButtonPrimary && 'Stroked'}
          color={!secondButtonPrimary ? 'Black' : 'unset'}
          primary={secondButtonPrimary}
          type="submit"
          Component={Button}
        >
          {secondButtonText}
        </Field>
      )}
    </ButtonSectionStyle>
  );
};

export default ButtonSection;
