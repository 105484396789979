import React, { useEffect, useState } from 'react';
import { DatePicker } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from '@single-platform/components';
import { format } from 'date-fns';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';

const SelectDate = ({
  question,
  validationHelpers,
  onChange,
  value,
  onQuestionChange,
  disabled
}) => {
  const [minDate, setMinDate] = useState(new Date());
  const { t } = useTranslation();

  const onChangeHandler = (value) => {
    onChange(format(new Date(value), 'yyyy-MM-dd') + 'T00:00:00.000Z');
  };

  useEffect(() => {
    onQuestionChange(question, 'add');
    setMinDate(minDate.setDate(minDate.getDate() + 1));
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, []);

  return (
    <>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={DatePicker}
        key={question.questionKey}
        onQuestionChange={onQuestionChange}
        required={question.required}
        minDate={question.type !== 'DateUntilToday' && minDate}
        maxDate={question.type === 'DateUntilToday' && new Date()}
        showDisabledMonthNavigation
        value={value}
        name={question.questionKey}
        placeholder={t(question.title)}
        onChange={onChangeHandler}
        dateFormat="yyyy-MM-dd"
        validateOnBlur
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
        disabled={disabled}
      />
    </>
  );
};

export default SelectDate;
