import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingFlowHandlerActions, selectKycAppState } from '../../../../../Redux';
import { KycComplete } from '../../../Components/KycComplete';
import { Link } from 'react-router-dom';
import { Result } from 'antd';
import { redirectToSmeGO } from '../../../../../PageParts';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';

export const StatusPages = () => {
  const { t } = useTranslation();
  const applicationState = useSelector(selectKycAppState);
  const [status, setStatus] = useState('Pending');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!applicationState) {
      const company = JSON.parse(sessionStorage.getItem('company'));
      dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
    }

    setStatus(applicationState?.status);
  }, [applicationState]);

  useEffect(() => {
    const isSmeGoSession = storageHandler.getSessionStorage('smeGoSession');
    if (isSmeGoSession && (status === 'OnboardingComplete' || status === 'Pending')) {
      setTimeout(() => redirectToSmeGO('', applicationState), 2000);
    }
  }, [status]);

  return (
    <div>
      {status === 'Pending' && (
        <Result
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          title={t('pending_state_text')}
          icon={<></>}
        />
      )}
      {applicationState?.state === 'RejectedByAmlOfficer' && (
        <Result
          icon={<></>}
          extra={
            <Trans
              t={t}
              values={{
                email: process.env.REACT_APP_REJECTED_STATE_EMAIL,
                phone: process.env.REACT_APP_REJECTED_STATE_PHONE
              }}
              components={{ smeLink: <Link /> }}
              i18nKey={'rejected_state_text'}
            >
              Text
              <Link href={`mailto:${process.env.REACT_APP_REJECTED_STATE_EMAIL}`}>Text</Link>
              Text
              <Link href={`tel:${process.env.REACT_APP_REJECTED_STATE_PHONE}`}>Text</Link>
              Text
            </Trans>
          }
        ></Result>
      )}
      {applicationState?.state === 'OnboardingComplete' && (
        <KycComplete translation={'onboarding_complete'} />
      )}
    </div>
  );
};
