import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormButton, FormStyles } from './countryForm.styles';
import { useTranslation } from 'react-i18next';
import { Field, Fieldset, Input } from '@single-platform/components';
import { useFormValidation } from '../../../ApplicationKYC/LegalEntityQuestionnaire/validation/useFormValidation';
import { LoginHeaderStyles } from '../../../LoginContainer/styles/login.styles';
import { CountriesDropdown } from '../countriesDropdown/countriesDropdown';
import { availableLoginMethodsByCountry } from '../../../LoginContainer/Components/tabs/availableLogonMethods.service';
import countries from '../countriesDropdown/loginCountriesList.json';

const initialValues = {
  country: '',
  email: ''
};

const CountryForm = ({
  formSubheading,
  inputPlaceholder,
  buttonLabel,
  allowCountrySelection,
  onSubmit
}) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    country: Yup.string().required(t('validation_required_field')),
    email: Yup.string()
      .required(t('validation_required_field'))
      .email(t('validation_email_is_not_valid'))
  });
  const [country, setCountry] = useState('');
  const [selectedCountryLoginMethods, setSelectedCountryLoginMethods] = useState([]);
  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormValidation({
    initialValues,
    validationSchema
  });

  const extendedValidationHelpers = {
    ...validationHelpers,
    setValueWithoutValidation: (name, value) => {
      validationHelpers.setValueWithoutValidation(name, value);

      if (name === 'country' && allowCountrySelection) {
        setCountry(value);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') onHandleSubmit();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const onHandleSubmit = async (e) => {
    e?.preventDefault();
    await handleSubmit(
      (data) => onSubmit(data),
      () => setTouchedOnAll()
    )();
  };

  useEffect(() => {
    setSelectedCountryLoginMethods(availableLoginMethodsByCountry(country));
  }, [country]);

  const noLoginMethods = !selectedCountryLoginMethods || selectedCountryLoginMethods.length === 0;

  return (
    <FormStyles>
      <LoginHeaderStyles>{formSubheading}</LoginHeaderStyles>
      <Fieldset>
        {allowCountrySelection ? (
          <>
            <Field
              Component={Input}
              name="email"
              type="email"
              value={getValues('email')}
              validateOnBlur
              placeholder={inputPlaceholder}
              validationHelpers={validationHelpers}
              required
            />
            <CountriesDropdown
              validationHelpers={extendedValidationHelpers}
              getValues={getValues('country')}
              placeholder={t('question_citizenship')}
              countries={countries}
              filterCountries
            />
          </>
        ) : null}
      </Fieldset>
      <FormButton name="login" disabled={noLoginMethods} onClick={(e) => onHandleSubmit(e)}>
        {buttonLabel}
      </FormButton>
    </FormStyles>
  );
};

export default CountryForm;
