import { createSelector } from 'reselect';
import { selectSectorsOptions } from './classificators';

export const selectKycState = createSelector(
  (state) => state,
  (state) => state?.KYCReducers
);

export const selectIsStartKycLoading = createSelector(selectKycState, (state) => state?.sendKYC);

export const selectInstitutions = createSelector(
  selectKycState,
  selectSectorsOptions,
  (state, sectors) => {
    if (!state?.availableInstitutions) {
      return [];
    }

    const mappedInstitutionSectors = [];

    for (const availableInstitution of state.availableInstitutions) {
      for (const institution of sectors) {
        if (mappedInstitutionSectors.length === state.availableInstitution) break;
        if (availableInstitution === institution.value) mappedInstitutionSectors.push(institution);
      }
    }

    return mappedInstitutionSectors;
  }
);

export const selectKycApplication = createSelector(
  selectKycState,
  (state) => state?.applicationKYC
);

export const selectKycAppCompany = createSelector(selectKycApplication, (state) => state?.company);

export const selectAppPrefillData = createSelector(
  selectKycApplication,
  (state) => state?.applicationState?.prefillData
);

export const selectIsFormFilledByCeo = createSelector(
  selectAppPrefillData,
  ({ managerCode, representative: { code: represantativeCode } }) => {
    if (managerCode === undefined) return undefined;
    return managerCode === represantativeCode;
  }
);

export const selectPrefillCompanyTypeList = createSelector(
  selectAppPrefillData,
  (state) => state?.companyTypesList?.companyTypes
);

export const selectKycAppState = createSelector(
  selectKycApplication,
  (state) => state?.applicationState
);

export const selectSortedKycAppDocumentList = createSelector(
  selectKycAppState,
  (state) =>
    state?.applicationDocumentsList?.sort((doc1, doc2) =>
      doc1.completed === doc2.completed ? 0 : doc2.completed ? -1 : 1
    ) || []
);

export const selectKycAppAuthTypes = createSelector(
  selectKycAppState,
  (state) => state?.authTypes || []
);

export const selectKycCurrentState = createSelector(selectKycAppState, (state) => state?.state);

export const selectIsCancelSigninLoading = createSelector(
  selectKycState,
  (state) => state?.cancelSigning
);

export const selectKycTimer = createSelector(selectKycState, (state) => state?.timerInfo || {});

export const selectIsAppSignedUploadLoading = createSelector(
  selectKycState,
  (state) => state?.isAppSignedUploadLoading
);
export const selectAuthState = createSelector(
  (state) => state,
  (state) => state.KYCReducers
);

export const selectIsAppDocsUploading = createSelector(
  selectKycState,
  (state) => state?.isUploadAppDocsLoading || false
);

export const selectIsUploadingSignedApp = createSelector(
  selectKycState,
  (state) => state?.isUploadSignedAppLoading || false
);

export const selectIsUploadingSignedAgreement = createSelector(
  selectKycState,
  (state) => state?.isUploadAgreementLoading || false
);

export const selectIsCancelDocLoading = createSelector(
  selectKycState,
  (state) => state?.isCancelUploadDocLoading || false
);

export const selectIsLoadingInstitutions = createSelector(
  selectKycState,
  (state) => state?.isLoadingInstitutions || false
);
