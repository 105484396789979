import React from 'react';
import { RemoveOwnerComp } from './RemoveOwnerComp';
import { RenderPerson } from './RenderPerson';
import OrgStructureForm from './OrgStructureForm';
import { useTranslation } from 'react-i18next';

export const RenderCompany = ({ data }) => {
  const {
    title,
    share,
    level,
    owners,
    isNotTheLast,
    className,
    index,
    parentIndex,
    id,
    parentId,
    isAddOwnerOn,
    onRemoveClick,
    addOwnerClick,
    submitForm
  } = data;
  const prefix = 'subsubsubsubsubsubsubsubsubsubsubsubsubsubsubsub';
  const orgStructureFormClassName = prefix.substring(0, 3 * level) + 'owners';
  const { t } = useTranslation();

  return (
    <>
      <div key={index} className={'owners ' + className}>
        <div className="top">
          <div className="left">
            <div className="dot dot-company">
              {isAddOwnerOn &&
              isAddOwnerOn.level &&
              isAddOwnerOn.level === level - 1 &&
              ((!isAddOwnerOn.index && isAddOwnerOn.index !== 0) ||
                isAddOwnerOn.index === parentIndex) &&
              isAddOwnerOn.id === parentId ? (
                <div className="corner outside" />
              ) : (
                <div className={isNotTheLast ? 'line' : ''} />
              )}
              <div
                className={
                  owners?.length ||
                  (isAddOwnerOn &&
                    isAddOwnerOn.level &&
                    isAddOwnerOn.level === level &&
                    isAddOwnerOn.index === index &&
                    isAddOwnerOn.id === id)
                    ? 'corner'
                    : ''
                }
              />
            </div>
          </div>
          <div className="middle">
            <div>
              <div className="owners-name">{title}</div>
              <div className="owners-type">{t('question_company_name')}</div>
            </div>
            <div className="right">
              <div className="percent">{share}%</div>
              <div className="row mr-2" data-html2canvas-ignore="true">
                {owners.reduce((count, item) => (count += item.share), 0) < 100 && (
                  <a className="add" onClick={() => addOwnerClick(level, id, index)}>
                    {isAddOwnerOn &&
                    isAddOwnerOn.level &&
                    isAddOwnerOn.level === level &&
                    isAddOwnerOn.index === index &&
                    isAddOwnerOn.id === id
                      ? t('repeatable_group.cancel_button')
                      : t('button_add_owner')}
                  </a>
                )}
                <RemoveOwnerComp id={id} onRemoveClick={onRemoveClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddOwnerOn &&
        isAddOwnerOn.level &&
        isAddOwnerOn.level === level &&
        isAddOwnerOn.index === index &&
        isAddOwnerOn.id === id && (
          <div className={'owners ' + orgStructureFormClassName}>
            <div className="top">
              <div className="left">
                <div className="dot dot-person"></div>
              </div>
              <div className="middle">
                <OrgStructureForm
                  submitForm={(type, title, share, level, parentIndex, parentId) =>
                    submitForm(type, title, share, level, parentIndex, parentId)
                  }
                  level={level}
                  parentIndex={index}
                  parentId={isAddOwnerOn.id}
                />
              </div>
            </div>
          </div>
        )}
      {owners?.length
        ? owners.map((owner, ownerIndex) => {
            const { type } = owner;
            const ownerIsNotTheLast = owners.length - 1 > ownerIndex;
            const ownerLevel = owner.level;
            if (type === 'c') {
              const ownerClassName = prefix.substring(0, 3 * (ownerLevel - 1)) + 'owners';
              return (
                <React.Fragment key={ownerIndex}>
                  <RenderCompany
                    data={{
                      ...owner,
                      isNotTheLast: ownerIsNotTheLast,
                      className: ownerClassName,
                      index: ownerIndex,
                      parentIndex: index,
                      parentId: id,
                      isAddOwnerOn,
                      onRemoveClick,
                      addOwnerClick,
                      submitForm
                    }}
                  />
                </React.Fragment>
              );
            } else {
              const ownerClassName = prefix.substring(0, 3 * ownerLevel) + 'owners';
              return (
                <React.Fragment key={ownerIndex}>
                  <RenderPerson
                    data={{
                      ...owner,
                      isNotTheLast: ownerIsNotTheLast,
                      className: ownerClassName,
                      index: ownerIndex,
                      parentIndex: index,
                      parentId: id,
                      isAddOwnerOn,
                      onRemoveClick
                    }}
                  />
                </React.Fragment>
              );
            }
          })
        : null}
    </>
  );
};
