import React from 'react';
import { Loader } from '@single-platform/components';
import { LoaderContainer, LoaderTextStyles, LoaderWrapper } from './loaderWithText.style';

const LoaderWithText = ({ text, small }) => {
  return (
    <LoaderContainer small={small}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
      {text && <LoaderTextStyles small={small}>{text}</LoaderTextStyles>}
    </LoaderContainer>
  );
};

export default LoaderWithText;
