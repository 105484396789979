import React, { useEffect, useState } from 'react';
import { Dropdown, ErrorMessage, Field } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const CountriesDropdown = ({
  validationHelpers,
  value,
  filterCountries,
  fieldMeta,
  placeholder,
  countries
}) => {
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;
  const environment = process.env.REACT_APP_TRADEMARK;
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const { t } = useTranslation();

  const financeEnvCountryList = ['LT', 'LV', 'EE', 'FI', 'NL'];

  useEffect(() => {
    if (filterCountries && environment.includes('Finance')) {
      const countryList = countries.filter((country) => {
        return financeEnvCountryList.includes(country.value);
      });
      setFilteredCountries(
        countryList.map((country) => {
          return { ...country, label: t(country.label) };
        })
      );
    } else {
      const countriesWithLabel = countries.map((country) => {
        return { ...country, label: t(country.label) };
      });
      setFilteredCountries(countriesWithLabel);
    }
  }, [i18next.language]);

  return (
    <>
      <Field
        Component={Dropdown}
        options={filteredCountries || countries}
        name="country"
        key="country"
        required
        sorted
        id="country"
        value={value || ''}
        validationHelpers={validationHelpers}
        placeholder={placeholder}
      />
      <ErrorMessage
        style={{ paddingTop: '0.5rem', marginLeft: '1rem' }}
        hidden={!hasValidationMessages || !showValidationMessage}
      >
        {message}
      </ErrorMessage>
    </>
  );
};
