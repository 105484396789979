import React from 'react';
import { useTranslation } from 'react-i18next';

export const RemoveOwnerComp = ({ id, onRemoveClick }) => {
  const { t } = useTranslation();
  return (
    <a className="add ml-3" onClick={() => onRemoveClick(id)}>
      {t('remove_link_text')}
    </a>
  );
};
