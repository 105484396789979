import styled from 'styled-components';

export const PlaceholderWrapper = styled.div`
  display: relative;
`;

export const Placeholder = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.primary.inputPlaceholderColor};
  z-index: 999;
  font-size: 11px;
  padding: ${(props) => (props.mobileInput ? '5px 5px 5px 0px' : '5px 5px 5px 0px')};
  margin-left: ${(props) => (props.mobileInput ? '49px' : '12px')};
`;
