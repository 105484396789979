import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  flex-wrap: no-wrap;
  gap: 0.5rem;
  margin-bottom: ${(props) => (props.noGroupsRendered ? '0rem' : '0.5rem')};
  label {
    width: 100%;
  }

  @media (max-width: 401px) {
    flex-direction: column;
  }
`;
