import React from 'react';
import {
  OndatoErrorWrapper,
  OndatoErrorHeader,
  OndatoErrorSubHeader,
  LinkButtonWrapper
} from './OndatoError.style';
import { Trans, useTranslation } from 'react-i18next';

export const OndatoError = () => {
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const phoneNumber = isBank ? '+37060188888' : '+37066974655';
  const email = isBank ? 'info@smebank.lt' : 'onboarding@smego.com';

  return (
    <OndatoErrorWrapper>
      <OndatoErrorHeader>{t('ondato_error_header')}</OndatoErrorHeader>
      <OndatoErrorSubHeader>
        <Trans
          i18nKey="ondato_error_subheader"
          values={{
            email,
            phoneNumber
          }}
        >
          Text
          <LinkButtonWrapper isBank={isBank} as="a" href={`tel:${phoneNumber}`}>
            Link
          </LinkButtonWrapper>
          Text
          <LinkButtonWrapper isBank={isBank} as="a" href={`mailto:${email}`}>
            Link
          </LinkButtonWrapper>
        </Trans>
      </OndatoErrorSubHeader>
    </OndatoErrorWrapper>
  );
};
