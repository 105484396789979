export const routerPath = {
  index: '/',
  login: '/login/',
  smeKyc: '/documentsUpload',
  errorPage: '/error',
  redirectEIdEasy: '/login/redirect',
  status: '/status',
  smego: '/smego',
  representativeChange: '/RepresentativeChange',
  companyIdentification: `/Company`,
  ondatoError: '/ondatoError'
};

export const linkPath = {
  index: { url: '/' },
  login: { url: '/login/' },
  smeKyc: { url: '/newKycSME/' },
  callback: { url: '/callback.html' }
};

export const questionnairePaths = {
  questionnaire: '/questionnaire',
  agreementSigning: '/agreementSigning',
  documentsUpload: '/documentUpload'
};
