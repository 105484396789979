import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDataForm from '../../components/personal-data-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../../../../Redux';
import { AuthenticationDialog } from '@single-platform/components';
import { allLoginStatus } from '../../../../Config';
import { SigningModal } from '../../../../Components/Signing/SigningModal';
import { Logout } from '../../../../Utilities';
import { OtpForm } from '../otp/otpForm';

const INITIAL_MODAL_STATE = {
  isOpen: false,
  modalType: null
};

export const Biometrics = ({
  personalData,
  setSubmitStatus,
  setCurrentStep,
  setActiveTab,
  setStep
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);
  const [requiresOtp, setRequiresOtp] = useState(false);
  const authState = useSelector((state) => state.AuthReducers);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [personalId, setPersonalId] = useState('');

  const submit = (data) => {
    setPersonalId(data.customerId);
    setIsWaitingForConfirmation(true);
  };

  const initiateLogin = async () => {
    const data = {
      personalCode: personalId,
      email: personalData.email,
      country: personalData.country
    };
    if (data.personalCode) {
      const response = await dispatch(AuthActions.biometricsInit(data));
      if (response.payload.redirectUrl) {
        window.open(response.payload.redirectUrl, '_blank');
      }
      setSubmitStatus({
        isSubmitting: true,
        type: 'biometrics'
      });
      return response.payload;
    }
  };

  const completeLogin = async ({ sessionId, token }) => {
    if (!sessionId) {
      return { state: 'failed' };
    }
    sessionStorage.setItem('loginToken', token);
    sessionStorage.setItem('loginSessionId', sessionId);
    const response = await dispatch(AuthActions.authorizationStatus(token));
    return response.payload;
  };

  const onBackHandle = () => {
    authState.isWaitingForConfirmation = false;
    setIsWaitingForConfirmation(false);
    setSubmitStatus({
      isSubmitting: false,
      type: null
    });
    setCurrentStep('loginMethods');
  };

  const onSuccessHandle = async (data) => {
    if (data.emailOtpRequired) {
      setRequiresOtp(true);
    } else {
      const token = {
        refreshToken: data.refreshToken,
        accessToken: data.token,
        expiresIn: data.expiresIn
      };
      sessionStorage.setItem('token', JSON.stringify(token));
      await authorizedCompanies();
    }
  };

  const authorizedCompanies = async () => {
    const result = await dispatch(AuthActions.getAuthorizedCompanies());
    if (result) {
      switch (result.payload.status) {
        case allLoginStatus.noAuthorizeCompany:
          setModal({
            isOpen: true,
            modalType: allLoginStatus.noAuthorizeCompany
          });
          break;
        case allLoginStatus.selectCompany:
          setModal({
            isOpen: true,
            modalType: allLoginStatus.selectCompany
          });
          break;
        default:
          break;
      }
    } else onFailHandle();
  };

  const onFailHandle = async () => {
    sessionStorage.clear();
    setModal(INITIAL_MODAL_STATE);
    await Logout();
    setRequiresOtp(false);
    setIsWaitingForConfirmation(false);
    authState.isWaitingForConfirmation = false;
    setSubmitStatus({
      isSubmitting: false,
      type: null
    });
    setActiveTab('');
    setCurrentStep('personalInfo');
    setStep('personalInfo');
  };

  const pollingConfiguration = {
    init: initiateLogin,
    complete: completeLogin
  };

  useEffect(() => {
    setPersonalId('');
    if (authState.isWaitingForConfirmation !== undefined) {
      setIsWaitingForConfirmation(authState.isWaitingForConfirmation);
    }
  }, [authState.isWaitingForConfirmation]);

  return (
    <>
      {!requiresOtp ? (
        <>
          <AuthenticationDialog
            testId="Biometrics"
            isWaitingForConfirmation={isWaitingForConfirmation}
            onBackHandle={onBackHandle}
            onSuccessHandle={onSuccessHandle}
            onFailHandle={onFailHandle}
            backLinkLabel={t('back')}
            verificationLabel={t('biometrics_Info_Message')}
            pollingConfig={pollingConfiguration}
          >
            <PersonalDataForm displayPhoneInput={false} onSubmit={submit} />
          </AuthenticationDialog>
        </>
      ) : (
        <OtpForm otpRequest={authorizedCompanies}></OtpForm>
      )}
      {modal.isOpen && <SigningModal type={modal.modalType} onCancel={onFailHandle} />}
    </>
  );
};
