import { LinkButton } from '@single-platform/components';
import styled from 'styled-components';

export const LinkButtonStyles = styled(LinkButton)`
  border-bottom: 1px solid;
  height: 1rem;
`;

export const LinkPopupContainer = styled.div`
  margin-top: -1rem;
`;

export const AsideDrawerStyles = styled.div`
  aside:first-child {
    z-index: 7500;
    position: fixed !important;
    max-width: 27.5rem !important;
    width: 27.5rem;

    & > div:first-child {
      width: 27.5rem !important;
    }

    @media (max-width: 1200px) {
      max-height: 100dvh;
      border-radius: unset;
      height: 100%;

      div[id='scrollable-content'] {
        max-height: 100dvh;
      }
    }

    @media (max-width: 540px) {
      width: 100% !important;
      max-width: 100% !important;
      max-height: calc(100vh - 1.5rem);
      border-top-right-radius: 1.5rem;
      border-top-left-radius: 1.5rem;
      height: auto;

      div[id='scrollable-content'] {
        width: 100% !important;
      }
    }
  }
`;

export const BackDrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7399;
`;

export const LinkPopupHeaderStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: sticky;
  top: 0;
  background-color: #fff;

  div:first-child {
    width: unset;
    padding: 0;
    gap: unset;
  }

  div:last-child {
    margin: 0 auto;
  }
`;

export const LinkPopupContentContainer = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  gap: 2rem;
`;
