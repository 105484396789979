export const OnboardingFlowConstants = {
  IS_USER_LOGGED_IN_SUCCESS: 'IS_USER_LOGGED_IN_SUCCESS',
  IS_USER_LOGGED_IN_ERROR: 'IS_USER_LOGGED_IN_ERROR',

  SET_DOCUMENT_ID_SUCCESS: 'SET_DOCUMENT_ID_SUCCESS',
  SET_LANGUAGE: 'SET_LANGUAGE',

  RESET_QUESTIONNAIRE_FLOW_STATE: 'RESET_QUESTIONNAIRE_FLOW_STATE',
  SET_TRACE_ID: 'SET_TRACE_ID'
};
