// options to show on clientProfile.0.data.insSect select field
// others get filtered in mapData file
export const CLASSIFICATOR_INSTTTNL_SCTR_ALLOWED = [
  'S.1311',
  'S.1312',
  'S.1313',
  'S.1314',
  'S.124',
  'S.12401',
  'S.12402',
  'S.125',
  'S.12501',
  'S.12502',
  'S.12503',
  'S.126',
  'S.12601',
  'S.12602',
  'S.12603',
  'S.127',
  'S.12701',
  'S.12702',
  'S.12703',
  'S.128',
  'S.12801',
  'S.12802',
  'S.12803',
  'S.129',
  'S.12901',
  'S.12902',
  'S.12903',
  'S.11001',
  'S.11',
  'S.11002',
  'S.11003',
  'S.143',
  'S.144',
  'S.1441',
  'S.1442',
  'S.1443',
  'S.141',
  'S.142',
  'S.15',
  'S.15002',
  'S.15003',
  'S.122',
  'S.12201',
  'S.12202',
  'S.12203',
  'S.123',
  'S.12301',
  'S.12302',
  'S.12303'
];
