import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { availableLoginMethodsByCountry } from './tabs/availableLogonMethods.service';
import { MobileID, Biometrics, SmartID, EIDeasy } from '../../Auth/methods';
import LoginMethodsList from './loginMethodsList/loginMethodsList';
import { LoginMethodsOptionContainer } from './loginMethodsList/loginMethodsList.styles';
import { AuthenticationHeaderContainer, LinkButtonModified } from '../styles/login.styles';
import { FormSubheading } from '../../Auth/components/countryForm/countryForm.styles';
import { useTranslation } from 'react-i18next';
import Icons from '@single-platform/components/dist/assets/icons';

export const LoginForm = React.memo(
  ({ onSubmit, isSubmitting, personalData, setSubmitStatus, setStep, step }) => {
    const availableLoginMethods = availableLoginMethodsByCountry(personalData.country);
    const [activeTab, setActiveTab] = useState('');
    const [currentStep, setCurrentStep] = useState('');
    const { t } = useTranslation();

    const switchTabHandler = (value) => {
      setActiveTab(value);
      setStep(value);
      setCurrentStep('personalForm');
    };

    const renderSwitch = (param) => {
      switch (param) {
        case 'Smart-ID':
          return (
            <SmartID
              setSubmitStatus={setSubmitStatus}
              personalData={personalData}
              setCurrentStep={setCurrentStep}
              setActiveTab={setActiveTab}
              setStep={setStep}
            />
          );
        case 'Mobile-ID':
          return (
            <MobileID
              setSubmitStatus={setSubmitStatus}
              personalData={personalData}
              setCurrentStep={setCurrentStep}
              setActiveTab={setActiveTab}
              setStep={setStep}
            />
          );
        case 'Biometrics':
          return (
            <Biometrics
              setSubmitStatus={setSubmitStatus}
              personalData={personalData}
              setCurrentStep={setCurrentStep}
              setActiveTab={setActiveTab}
              setStep={setStep}
            />
          );
        case 'eideasy':
        case 'IDIN':
        case 'FTN':
        case 'eParakts':
          return (
            <EIDeasy
              personalData={personalData}
              setCurrentStep={setCurrentStep}
              setSubmitStatus={setSubmitStatus}
              setActiveTab={setActiveTab}
              setStep={setStep}
              step={step}
            />
          );
        default:
          return (
            <SmartID
              setSubmitStatus={setSubmitStatus}
              personalData={personalData}
              setCurrentStep={setCurrentStep}
              setActiveTab={setActiveTab}
              setStep={setStep}
            />
          );
      }
    };

    const onBack = () => {
      setCurrentStep('countrySelect');
      setStep('personalInfo');
      setActiveTab(null);
      setSubmitStatus({
        isSubmitting: false,
        type: null
      });
    };

    return (
      <>
        {step === 'eideasy' && (
          <LoginMethodsOptionContainer>{renderSwitch(step)}</LoginMethodsOptionContainer>
        )}
        <Form
          onSubmit={(values) => onSubmit(values)}
          render={() => {
            return (
              <>
                {!isSubmitting && !activeTab && (
                  <LoginMethodsList
                    loginTypes={availableLoginMethods}
                    onSwitch={switchTabHandler}
                  />
                )}
                {activeTab && (
                  <>
                    <AuthenticationHeaderContainer>
                      {currentStep !== 'countrySelect' && !isSubmitting && (
                        <FormSubheading>
                          <LinkButtonModified onClick={onBack}>
                            <Icons.ArrowLeft />
                            {t('back')}
                          </LinkButtonModified>
                        </FormSubheading>
                      )}
                    </AuthenticationHeaderContainer>
                    <LoginMethodsOptionContainer>
                      {renderSwitch(activeTab)}
                    </LoginMethodsOptionContainer>
                  </>
                )}
              </>
            );
          }}
        />
      </>
    );
  }
);
