export const TranslationsConstant = {
  GET_TRANSLATIONS_CONTEXT_CULTURE_REQUEST: 'GET_TRANSLATIONS_CONTEXT_CULTURE_REQUEST',
  GET_TRANSLATIONS_CONTEXT_CULTURE_SUCCESS: 'GET_TRANSLATIONS_CONTEXT_CULTURE_SUCCESS',
  GET_TRANSLATIONS_CONTEXT_CULTURE_ERROR: 'GET_TRANSLATIONS_CONTEXT_CULTURE_ERROR',

  GET_CREATE_TRANSLATION_REQUEST: 'GET_CREATE_TRANSLATION_REQUEST',
  GET_CREATE_TRANSLATION_SUCCESS: 'GET_CREATE_TRANSLATION_SUCCESS',
  GET_CREATE_TRANSLATION_ERROR: 'GET_CREATE_TRANSLATION_ERROR',

  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE'
};
