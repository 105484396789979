import { AsideMenuTopBar } from '@single-platform/components';
import React from 'react';
import { LinkPopupContentContainer, LinkPopupHeaderStyles } from '../../linkPopup.style';
import { ListStyles } from './linkPopupAml.style';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const LinkPopupAml = ({ onClose, header }) => {
  const { t } = useTranslation();
  const isLtLanguage = i18next.language === 'lt-LT';

  return (
    <>
      <LinkPopupHeaderStyles>
        <AsideMenuTopBar handleClose={onClose} />
        <div>{t(header)}</div>
      </LinkPopupHeaderStyles>
      <LinkPopupContentContainer>
        <ListStyles>
          <b>{t('aml_popup_list_regulated_header')}</b>
          <li>{t('aml_popup_list_financial_institution')}</li>
          <li>{t('aml_popup_list_credit_institution')}</li>
          <li>{t('aml_popup_list_payment_institution')}</li>
          <li>{t('aml_popup_list_e-money_institution')}</li>
          <li>{t('aml_popup_list_currency_institution')}</li>
          <li>{t('aml_popup_list_crowdfunding_institution')}</li>
          <li>{t('aml_popup_list_p-to-p_institution')}</li>
          <li>{t('aml_popup_list_insurance_institution')}</li>
          {isLtLanguage && <li>{t('aml_popup_list_insurance_broker')}</li>}
          <li>{t('aml_popup_list_investments_institution')}</li>
          {isLtLanguage && <li>{t('aml_popup_list_collective_investment')}</li>}
        </ListStyles>
        <ListStyles>
          <b>{t('aml_popup_list_other_entities_header')}</b>
          <li>{t('aml_popup_list_real_estate_agents')}</li>
          <li>{t('aml_popup_list_other_virtual_currency')}</li>
          <li>{t('aml_popup_list_other_custodian')}</li>
          <li>{t('aml_popup_list_other_auditors')}</li>
          <li>{t('aml_popup_list_bailiffs_and_bailiff_representatives')}</li>
          <li>{t('aml_popup_list_other_accounting')}</li>
          <li>{t('aml_popup_list_other_notaries')}</li>
          <li>{t('aml_popup_list_other_judicial_officers')}</li>
          {isLtLanguage && <li>{t('aml_popup_list_other_providers_of_trust_services')}</li>}
          <li>{t('aml_popup_list_other_gaming_companies')}</li>
          <li>{t('aml_popup_list_other_closed_end_investment_companies')}</li>
          <li>{t('aml_popup_list_other_dealers_in_precious_goods')}</li>
          <li>{t('aml_popup_list_other_art')}</li>
        </ListStyles>
      </LinkPopupContentContainer>
    </>
  );
};
