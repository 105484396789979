import { Checkbox, Field } from '@single-platform/components';
import styled from 'styled-components';

export const CheckboxWrapper = styled(Field)`
  margin-bottom: 0.5rem;
`;

export const CheckboxStyles = styled(Checkbox)`
  span {
    background-color: ${({ theme }) => theme.colors.base.primary[100]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.base.primary[20]};
    }
  }
  span > svg {
    height: unset;
    width: unset;
  }
`;

export const CheckboxContentWrapper = styled.div`
  margin-bottom: 0rem;
`;
