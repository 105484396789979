import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterWrapper } from './Footer.styles';
import { useSelector } from 'react-redux';

export const Footer = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.OnboardingFlowReducer.logged);
  const isRepresentativeChange = window.location.pathname.includes('/RepresentativeChange');
  const isExternalPersons = window.location.pathname.includes('/Company/');

  return (
    !isLoggedIn &&
    !isRepresentativeChange &&
    !isExternalPersons && (
      <FooterWrapper>
        {new Date().getFullYear()}. {process.env.REACT_APP_TRADEMARK} {t('all_rights_reserved')}
      </FooterWrapper>
    )
  );
};
