import React from 'react';
import { useTranslation } from 'react-i18next';
import OrgStructureForm from './OrgStructureForm';

export const RenderMainCompany = ({ data }) => {
  const { orgStructureData, isAddOwnerOn, submitForm, handleAddOwner } = data;
  const { title, owners, level, id } = orgStructureData;
  const { t } = useTranslation();

  return (
    <>
      <div className="owners">
        <div className="top">
          <div className="left">
            <div className="dot dot-company">
              <div className={owners && owners.length > 0 ? 'corner' : ''} />
            </div>
          </div>
          <div className="middle">
            <div>
              <div className="owners-name">{title}</div>
              <div className="owners-type">{t('question_company_name')}</div>
            </div>
            <div className="right " data-html2canvas-ignore="true">
              <div className="percent hidden">.</div>
              <div className="row mr-0" data-html2canvas-ignore="true">
                {owners.reduce((count, item) => (count += item.share), 0) < 100 && (
                  <a
                    className="add"
                    data-html2canvas-ignore="true"
                    onClick={() => handleAddOwner(level, id)}
                  >
                    {isAddOwnerOn?.level === level
                      ? t('repeatable_group.cancel_button')
                      : t('button_add_owner')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddOwnerOn?.level === level && (
        <div className="owners subowners">
          <div className="top">
            <div className="left">
              <div className="dot dot-person"></div>
            </div>
            <div className="middle">
              <OrgStructureForm
                submitForm={(type, title, share, level, parentIndex, parentId) =>
                  submitForm(type, title, share, level, parentIndex, parentId)
                }
                level={level}
                parentIndex={0}
                parentId={isAddOwnerOn.id}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
