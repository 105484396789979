import styled from 'styled-components';

export const ModalCompanyHeader = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
`;
