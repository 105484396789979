import PropTypes from 'prop-types';
import { notification } from 'antd';
import { t } from 'i18next';

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const Notification = (props) => {
  notification.open({
    description: props.description
      ? props.noTranslateDescription
        ? props.description
        : t(props.description)
      : null,
    duration: props.duration ? props.duration : 5,
    icon: props.icon,
    message: props.message ? (props.noTranslateMessage ? props.message : t(props.message)) : null,
    placement: props.placement ? props.placement : 'bottomRight',
    type: props.type
  });
};

PropTypes.propTypes = {
  description: PropTypes.string,
  duration: PropTypes.number,
  icon: PropTypes.bool,
  message: PropTypes.string,
  placement: PropTypes.string,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired
};
