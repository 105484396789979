import { Typography } from '@single-platform/components';
import styled from 'styled-components';

export const Button = styled.div.attrs({ role: 'button' })`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  min-height: 4rem;
  gap: 0.625rem;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  transition: border 0.15s ease-in;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.base.primary.shades[50]};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.colors.base.primary.shades[100]};
  }
`;

export const LoginLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const LoginMethodName = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const LoginPoweredByText = styled(Typography.Body).attrs({ size: 'S', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
  font-size: 0.625rem;
  line-height: 0.875rem;
`;
