import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea as SpTextArea, Field } from '@single-platform/components/';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';

export const TextArea = ({
  question,
  name,
  validationHelpers,
  onChange,
  value,
  onQuestionChange,
  disabled
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isFirstTouch, setIsFirstTouch] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value);
    if (value) setIsFirstTouch(false);
    onQuestionChange(question, 'add');
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [value]);

  const onBlurHandler = (e) => {
    setIsFirstTouch(false);
    onChange(e?.target?.value);
  };

  return (
    <Fragment key={question.questionKey + question.name}>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={SpTextArea}
        autoHeight={true}
        maxHeight={288}
        name={name}
        id={name}
        validateOnBlur
        value={inputValue}
        placeholder={t(question.title)}
        maxLength={question.maxLength}
        key={question.id}
        counter={true}
        onBlur={(e) => onBlurHandler(e)}
        onChange={(e) => !isFirstTouch && onChange(e)}
        validationHelpers={validationHelpers}
        disabled={disabled}
      />
    </Fragment>
  );
};
