export const AuthConstants = {
  START_LOGIN_REQUEST: 'START_LOGIN_REQUEST',
  START_LOGIN_SUCCESS: 'START_LOGIN_SUCCESS',
  START_LOGIN_ERROR: 'START_LOGIN_ERROR',

  GET_AUTHORISED_COMPANIES_REQUEST: 'GET_AUTHORISED_COMPANIES_REQUEST',
  GET_AUTHORISED_COMPANIES_SUCCESS: 'GET_AUTHORISED_COMPANIES_SUCCESS',
  GET_AUTHORISED_COMPANIES_ERROR: 'GET_AUTHORISED_COMPANIES_ERROR',

  SELECT_IDENTIFICATION_REQUEST: 'SELECT_IDENTIFICATION_REQUEST',
  SELECT_IDENTIFICATION_SUCCESS: 'SELECT_IDENTIFICATION_SUCCESS',
  SELECT_IDENTIFICATION_ERROR: 'SELECT_IDENTIFICATION_ERROR',

  RESET_LOGIN: 'RESET_LOGIN',

  GET_IDENTIFICATION_URL_REQUEST: 'GET_IDENTIFICATION_URL_REQUEST',
  GET_IDENTIFICATION_URL_SUCCESS: 'GET_IDENTIFICATION_URL_SUCCESS',
  GET_IDENTIFICATION_URL_ERROR: 'GET_IDENTIFICATION_URL_ERROR',

  BIOFACE_LOGIN_SIDE_EFFECTS: 'BIOFACE_LOGIN_SIDE_EFFECTS',

  SMARTID_REQUEST: 'SMARTID_REQUEST',
  SMARTID_SUCCESS: 'SMARTID_SUCCESS',
  SMARTID_ERROR: 'SMARTID_ERROR',

  BIOMETRICS_REQUEST: 'BIOMETRICS_REQUEST',
  BIOMETRICS_SUCCESS: 'BIOMETRICS_SUCCESS',
  BIOMETRICS_ERROR: 'BIOMETRICS_ERROR',

  MOBILEID_REQUEST: 'MOBILEID_REQUEST',
  MOBILEID_SUCCESS: 'MOBILEID_SUCCESS',
  MOBILEID_ERROR: 'MOBILEID_ERROR',

  AUTHORIZATION_STATUS_REQUEST: 'AUTHORIZATION_STATUS_REQUEST',
  AUTHORIZATION_STATUS_SUCCESS: 'AUTHORIZATION_STATUS_SUCCESS',
  AUTHORIZATION_STATUS_ERROR: 'AUTHORIZATION_STATUS_ERROR',

  OTP_AUTORIZATION_REQUEST: 'OTP_AUTORIZATION_REQUEST',
  OTP_AUTORIZATION_SUCCESS: 'OTP_AUTORIZATION_SUCCESS',
  OTP_AUTORIZATION_ERROR: 'OTP_AUTORIZATION_ERROR',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  EIDEASY_INIT_REQUEST: 'EIDEASY_INIT_REQUEST',
  EIDEASY_INIT_SUCCESS: 'EIDEASY_INIT_SUCCESS',
  EIDEASY_INIT_ERROR: 'EIDEASY_INIT_ERROR',

  GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR: 'GET_COMPANIES_ERROR',

  DATA_EXCHANGE_REQUEST: 'DATA_EXCHANGE_REQUEST',
  DATA_EXCHANGE_SUCCESS: 'DATA_EXCHANGE_SUCCESS',
  DATA_EXCHANGE_ERROR: 'DATA_EXCHANGE_ERROR',

  EIDEASY_STATUS_REQUEST: 'EIDEASY_STATUS_REQUEST',
  EIDEASY_STATUS_SUCCESS: 'EIDEASY_STATUS_SUCCESS',
  EIDEASY_STATUS_ERROR: 'EIDEASY_STATUS_ERROR',

  ADD_EMPTY_IDENTIFICATION_REQUEST: 'NEW_IDENTIFICATION_REQUEST',

  INITIATED_SSO_LOGIN: 'INITIATED_SSO_LOGIN',

  RESET_SSO_LOGIN: 'RESET_SSO_LOGIN',

  GET_REPRESENTATIVE_INFORMATION_REQUEST: 'GET_REPRESENTATIVE_INFORMATION_REQUEST',
  GET_REPRESENTATIVE_INFORMATION_SUCCESS: 'GET_REPRESENTATIVE_INFORMATION_SUCCESS',
  GET_REPRESENTATIVE_INFORMATION_ERROR: 'GET_REPRESENTATIVE_INFORMATION_ERROR',

  GET_EXTERNAL_PERSON_INFORMATION_REQUEST: 'GET_EXTERNAL_PERSON_INFORMATION_REQUEST',
  GET_EXTERNAL_PERSON_INFORMATION_SUCCESS: 'GET_EXTERNAL_PERSON_INFORMATION_SUCCESS',
  GET_EXTERNAL_PERSON_INFORMATION_ERROR: 'GET_EXTERNAL_PERSON_INFORMATION_ERROR',

  START_PERSON_VERIFICATION_REQUEST: 'START_PERSON_VERIFICATION_REQUEST',
  START_PERSON_VERIFICATION_SUCCESS: 'START_PERSON_VERIFICATION_SUCCESS',
  START_PERSON_VERIFICATION_ERROR: 'START_PERSON_VERIFICATION_ERROR',

  START_REPRESENTATIVE_VERIFICATION_REQUEST: 'START_REPRESENTATIVE_VERIFICATION_REQUEST',
  START_REPRESENTATIVE_VERIFICATION_SUCCESS: 'START_REPRESENTATIVE_VERIFICATION_SUCCESS',
  START_REPRESENTATIVE_VERIFICATION_ERROR: 'START_REPRESENTATIVE_VERIFICATION_ERROR'
};
