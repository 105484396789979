const breakpointSizes = {
  phoneSmall: 650,
  phone: 768,
  tablet: 1000,
  smallDesktop: 1201,
  desktop: 1400,
  desktopBig: 1600
};

export const paperStyles = {
  minHeight: '57rem',
  maxWidth: '92rem'
};

export default breakpointSizes;
