import { AsideMenuTopBar } from '@single-platform/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LinkPopupContentContainer, LinkPopupHeaderStyles } from '../../linkPopup.style';
import { ParagraphStyles } from '../linkPopupDescription/linkPopupDescription.style';
import { ListStyles } from '../linkPopupAml/linkPopupAml.style';
import { BoldedTextStyles, GroupedItemsStyles } from './linkPopupWhoIsPep.style';

const qualifyForPepTranslationList = [
  'link_popup_who_is_pep_description_qualify_for_pep_first',
  'link_popup_who_is_pep_description_qualify_for_pep_second',
  'link_popup_who_is_pep_description_qualify_for_pep_third',
  'link_popup_who_is_pep_description_qualify_for_pep_fourth',
  'link_popup_who_is_pep_description_qualify_for_pep_fifth',
  'link_popup_who_is_pep_description_qualify_for_pep_sixt',
  'link_popup_who_is_pep_description_qualify_for_pep_seventh',
  'link_popup_who_is_pep_description_qualify_for_pep_eigth',
  'link_popup_who_is_pep_description_qualify_for_pep_ninth',
  'link_popup_who_is_pep_description_qualify_for_pep_tenth',
  'link_popup_who_is_pep_description_qualify_for_pep_eleventh'
];

const whoIsPepTranslationList = [
  'link_popup_who_is_pep_description_close_helper_first',
  'link_popup_who_is_pep_description_close_helper_second',
  'link_popup_who_is_pep_description_close_helper_third'
];

export const LinkPopupWhoIsPep = ({ onClose, header }) => {
  const { t } = useTranslation();
  return (
    <>
      <LinkPopupHeaderStyles>
        <AsideMenuTopBar handleClose={onClose} />
        <div>{t(header)}</div>
      </LinkPopupHeaderStyles>
      <LinkPopupContentContainer>
        <ParagraphStyles>{t('link_popup_who_is_pep_description_header')}</ParagraphStyles>
        <GroupedItemsStyles>
          <BoldedTextStyles>
            {t('link_popup_who_is_pep_description_close_member_title')}
          </BoldedTextStyles>
          <ParagraphStyles>
            {t('link_popup_who_is_pep_description_close_member_header')}
          </ParagraphStyles>
        </GroupedItemsStyles>
        <GroupedItemsStyles>
          <BoldedTextStyles>
            {t('link_popup_who_is_pep_description_close_helper_title')}
          </BoldedTextStyles>
          <ListStyles>
            {whoIsPepTranslationList.map((item, index) => {
              return (
                <li key={index}>
                  <Trans i18nKey={item} />
                </li>
              );
            })}
          </ListStyles>
        </GroupedItemsStyles>
        <GroupedItemsStyles>
          <BoldedTextStyles>
            {t('link_popup_who_is_pep_description_qualify_for_pep_title')}
          </BoldedTextStyles>
          <ListStyles listStyleType="number">
            {qualifyForPepTranslationList.map((item, index) => {
              return (
                <li key={index}>
                  <Trans i18nKey={item} />
                </li>
              );
            })}
          </ListStyles>
        </GroupedItemsStyles>
      </LinkPopupContentContainer>
    </>
  );
};
