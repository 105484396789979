import styled from 'styled-components';

export const ButtonContainerStyles = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: #f4f4f5;
  flex: 1;
  width: 100%;
  padding: 16px 48px 32px 48px;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  z-index: 1;

  @media screen and (max-width: 600px) {
    & > :not(:only-child) {
      flex-direction: column-reverse;
    }

    & > :not(:only-child):first-child {
      position: relative;
      bottom: 35px;
    }
  }

  @media screen and (max-width: 425px) {
    & > :first-child {
      bottom: 0px;
    }
  }
`;
