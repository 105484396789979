import { TranslationsConstant } from './constant';

export const translationReducer = function (state = { translations: [] }, action) {
  switch (action.type) {
    case TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_REQUEST:
      return {
        ...state,
        send: true
      };
    case TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_SUCCESS:
      return {
        ...state,
        send: false,
        translations: [...state.translations, action.payload]
      };
    case TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_ERROR:
      return {
        ...state,
        send: false,
        errors: action.error
      };
    default:
      // sessionStorage.clear();
      return state;
  }
};
