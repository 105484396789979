import {
  aPIPaths,
  handleResponse,
  methods,
  requestJSON,
  requestWithToken,
  responseTypes
} from '@redux/Config';

export const KYCServices = {
  sendKYCApplication,
  getListKYC,
  modifyKYCApplication,
  approveKYCApplication,
  deleteKYCApplication,
  getApplication,
  uploadPowerOfAttorney,
  uploadOrganizationalChart,
  uploadArticlesOfAssociation,
  uploadBeneficiaryDocuments,
  uploadWolfsbergQuestionnaire,
  submitApplication,
  startApplicationSigning,
  checkSigningSca,
  cancelSigning,
  cancelUploadDocument,
  continueApplication,
  checkState,
  startAgreementSigning,
  uploadAdditionalDocuments,
  startOndatoBeneficiaryIdentification,
  uploadApplicationDocument,
  deleteApplicationDocumentFile,
  startApplicationSignedUpload,
  uploadSignedApplication,
  uploadSignedAgreement,
  renderPdfFromHtml,
  uploadKycPdf,
  validateData,
  getInstitution,
  getCompanyClassification,
  returnToUserFillingApplication,
  createApplication,
  signApplication,
  getApplicationDocumentRequests,
  getApplicationDocumentRequestsToSign,
  confirmApplicationDocumentsUpload,
  signStatus
};

function sendKYCApplication(data) {
  return requestJSON(aPIPaths.post_sendKycApplication, {
    method: methods.post,
    data: data
  }).then(handleResponse);
}

function getListKYC(data) {
  return requestJSON(aPIPaths.post_getListKYC, {
    method: methods.post,
    data: data
  }).then(handleResponse);
}

function modifyKYCApplication(data) {
  return requestJSON(aPIPaths.post_modifyKycApplication, {
    method: methods.put,
    data: data
  }).then(handleResponse);
}

function approveKYCApplication(kycId) {
  return requestJSON(aPIPaths.post_approveKycApplication + '?KycId=' + kycId, {
    method: methods.post
  }).then(handleResponse);
}

function deleteKYCApplication(kycId) {
  return requestJSON(aPIPaths.delete_deleteKycApplication + '?KycId=' + kycId, {
    method: methods.delete
  }).then(handleResponse);
}

function getApplication() {
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  const companyIdentificationId = JSON.parse(sessionStorage.getItem('company'))?.identificationId;
  requestJSON.defaults.headers['Authorization'] = `Bearer ${sessionToken.accessToken}`;
  requestJSON.defaults.headers.common['X-IdentificationId'] = companyIdentificationId;
  return requestJSON(aPIPaths.getApplication).then(handleResponse);
}

function createApplication(data) {
  return requestWithToken(aPIPaths.createLegalEntityApplication, {
    method: methods.post,
    data: JSON.stringify(data)
  }).then(handleResponse);
}

function uploadPowerOfAttorney(data) {
  return requestJSON(aPIPaths.uploadPowerOfAttorney, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function uploadOrganizationalChart(data) {
  return requestJSON(aPIPaths.uploadOrganizationalChart, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function uploadApplicationDocument(data, applicationId, documentRequestId) {
  return requestJSON(
    aPIPaths.uploadApplicationDocument
      .replace('{applicationId}', applicationId)
      .replace('{requestId}', documentRequestId),
    {
      data: data,
      method: methods.post
    }
  ).then(handleResponse);
}

function deleteApplicationDocumentFile(applicationId, documentRequestId, fileId) {
  return requestJSON(
    aPIPaths.deleteApplicationDocumentFile
      .replace('{applicationId}', applicationId)
      .replace('{requestId}', documentRequestId)
      .replace('{fileId}', fileId),
    {
      method: methods.delete
    }
  ).then(handleResponse);
}

function uploadSignedAgreement(data) {
  return requestJSON(aPIPaths.uploadSignedAgreement, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function getCompanyClassification({ companyType, sectorCode, naceCode }) {
  return requestJSON(
    aPIPaths.getCompanyClassification
      .replace('{companyType}', companyType)
      .replace('{institutionalSectorCode}', sectorCode)
      .replace('{naceCode}', naceCode),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function uploadSignedApplication(data, documentId) {
  return requestJSON(
    aPIPaths.uploadSignedApplication.replace('{applicationDocumentId}', documentId),
    {
      data: data,
      method: methods.post
    }
  ).then(handleResponse);
}

function uploadArticlesOfAssociation(data) {
  return requestJSON(aPIPaths.uploadArticlesOfAssociation, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function uploadBeneficiaryDocuments(data) {
  return requestJSON(aPIPaths.uploadBeneficiaryDocuments, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function uploadWolfsbergQuestionnaire(data) {
  return requestJSON(aPIPaths.uploadWolfsbergQuestionnaire, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function submitApplication(applicationId) {
  return requestJSON(aPIPaths.submitApplication.replace('{applicationId}', applicationId), {
    data: {},
    method: methods.post
  }).then(handleResponse);
}

function startApplicationSigning(authType) {
  return requestJSON(`${aPIPaths.startApplicationSigning}?authType=${authType}`, {
    method: methods.post
  }).then(handleResponse);
}

function cancelUploadDocument() {
  return requestJSON(aPIPaths.cancelUploadDocument, {
    method: methods.get
  }).then(handleResponse);
}

function startApplicationSignedUpload(data) {
  return requestJSON(aPIPaths.startApplicationSigning, {
    method: methods.post,
    data
  }).then(handleResponse);
}

function checkSigningSca(signingToken, documentId) {
  return requestJSON(aPIPaths.checkSigningSca, {
    method: methods.post,
    data: JSON.stringify({
      signingToken,
      documentId
    })
  }).then(handleResponse);
}

function cancelSigning(documentId) {
  return requestWithToken(aPIPaths.cancelSigning, {
    method: methods.post,
    data: JSON.stringify({
      documentId
    })
  }).then(handleResponse);
}

function continueApplication() {
  return requestJSON(aPIPaths.continueApplication, {
    method: methods.post
  }).then(handleResponse);
}

function checkState() {
  return requestJSON(aPIPaths.checkState).then(handleResponse);
}

function startAgreementSigning(authType) {
  return requestJSON(`${aPIPaths.startAgreementSigning}?authType=${authType}`, {
    method: methods.post
  }).then(handleResponse);
}

function uploadAdditionalDocuments(data) {
  return requestJSON(aPIPaths.uploadAdditionalDocuments, {
    data: data,
    method: methods.post
  }).then(handleResponse);
}

function startOndatoBeneficiaryIdentification() {
  return requestJSON(aPIPaths.startOndatoBeneficiaryIdentification, {
    method: methods.post
  }).then(handleResponse);
}

function renderPdfFromHtml(htmlStr) {
  return requestJSON(aPIPaths.renderPdfFromHtml, {
    method: methods.post,
    data: `"${htmlStr}"`,
    responseType: responseTypes.arraybuffer
  }).then(handleResponse);
}

function uploadKycPdf(file) {
  return requestJSON(aPIPaths.uploadKycPdf, {
    method: methods.post,
    data: file
  }).then(handleResponse);
}

function validateData(data) {
  return requestJSON(aPIPaths.validateData, {
    method: methods.post,
    data
  }).then(handleResponse);
}

function getInstitution(legalForm, nace) {
  return requestJSON(
    aPIPaths.institutionalCodeMap.replace('{legalForm}', legalForm).replace('{nace}', nace),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function signApplication(applicationId, consent) {
  return requestJSON(aPIPaths.signApplication.replace('{applicationId}', applicationId), {
    method: methods.post
  }).then(handleResponse);
}

function returnToUserFillingApplication(applicationId) {
  return requestJSON(
    aPIPaths.returnToUserFillingApplication.replace('{applicationId}', applicationId),
    {
      method: methods.post
    }
  );
}

function getApplicationDocumentRequests(applicationId) {
  return requestJSON(
    aPIPaths.getApplicationDocumentRequests.replace('{applicationId}', applicationId),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function getApplicationDocumentRequestsToSign(applicationId) {
  return requestJSON(
    aPIPaths.getApplicationDocumentRequestsToSign.replace('{applicationId}', applicationId),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function confirmApplicationDocumentsUpload(applicationId) {
  return requestJSON(
    aPIPaths.confirmApplicationDocumentsUpload.replace('{applicationId}', applicationId),
    {
      method: methods.post
    }
  ).then(handleResponse);
}

function signStatus(applicationId, documentId) {
  return requestJSON(
    aPIPaths.signStatus
      .replace('{applicationId}', applicationId)
      .replace('{documentId}', documentId),
    {
      method: methods.get
    }
  ).then(handleResponse);
}
