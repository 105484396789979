import React, { useEffect, useState } from 'react';
import { Field } from '@single-platform/components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { RenderGroup } from '../../screens/renderGroup/renderGroup';
import { shouldRenderConditionalGroup } from '../../conditionalGroupsService/conditionalGroups.service';
import RadioButtonWrapper from './radioButtonWrapper/radioButtonWrapper';
import { RenderRepeatableGroup } from '../../screens/renderRepeatableGroup/renderRepeatableGroup';
import { TextGroup } from '../textGroup/textGroup';
import { CheckBoxGroupTitleStyles } from '../checkboxGroup/checkboxGroup.styles';
import { InformationMessage } from '../informationMessage/informationMessage';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';
import { LinkPopup } from '../linkPopup/linkPopup';

const RadioButtonGroup = ({
  options,
  onChange,
  name,
  required,
  value,
  errors,
  question,
  conditions,
  onInputChange,
  initialQuestionValues,
  onQuestionChange,
  validationHelpers,
  disabled,
  isInModal,
  onRepeatableGroupChange
}) => {
  const [defaultValues, setDefaultValues] = useState([]);
  const [repeatableGroups, setRepeatableGroups] = useState([]);
  const [conditionalGroups, setConditionalGroups] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [inputValue, setInputValue] = useState(value);
  const { t } = useTranslation();

  const onChangeHandler = (updatedValues) => {
    const inputValue = updatedValues
      .filter((option) => option.value && option.optionLabel)
      .map((option) => option.optionValue);
    setRepeatableGroups([]);
    setConditionalGroups([]);
    setDefaultValues(updatedValues);
    setInputValue(inputValue[0]);
    onChange(inputValue);
  };

  const getValue = () => {
    const inputValue = defaultValues.filter((option) => option.value && option.optionValue)?.[0]
      ?.optionValue;
    if (inputValue) setInputValue(inputValue);
  };

  useEffect(() => {
    getValue();
    onQuestionChange(question, 'add');
    if (isFirst) {
      setDefaultValues(
        options?.map((option) => {
          const condGroup = _.find(
            conditions,
            (conditionalGroup) => conditionalGroup.condition.value === option.value
          );
          return {
            value: value === option.value,
            optionLabel: option.label,
            conditionalGroup: condGroup,
            optionValue: option.value
          };
        })
      );
      setIsFirst(false);
    } else {
      conditions.forEach((conditionGroup) => {
        if (shouldRenderConditionalGroup(inputValue, conditionGroup.condition)) {
          conditionGroup.groups[0]?.baseGroup
            ? setRepeatableGroups(conditionGroup.groups)
            : setConditionalGroups(conditionGroup.groups);
        }
      });
    }
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [defaultValues, inputValue, conditions]);

  useEffect(() => {
    setDefaultValues(
      options?.map((option) => {
        const condGroup = _.find(
          conditions,
          (conditionalGroup) => conditionalGroup.condition.value === option.value
        );
        return {
          value: value === option.value,
          optionLabel: option.label,
          conditionalGroup: condGroup,
          optionValue: option.value
        };
      })
    );
  }, [value]);

  const renderHandler = (group) => {
    switch (group.groupType) {
      case 'TextGroup':
        return <TextGroup key={group.id} group={group} />;
      case 'RepeatableGroup':
        return (
          <RenderRepeatableGroup
            initialQuestionValues={initialQuestionValues}
            onInputChange={onInputChange}
            onQuestionChange={onQuestionChange}
            key={group.id}
            group={group}
            errors={errors}
            validationHelpers={validationHelpers}
            isInModal={true}
            onRepeatableGroupChange={onRepeatableGroupChange}
          />
        );
      case 'InformationMessage':
        return <InformationMessage key={group.id} group={group} isChildren={true} />;
      case 'LinkPopup':
        return <LinkPopup isInModal={isInModal} key={group.id} group={group} />;
      default:
        return (
          <RenderGroup
            onInputChange={onInputChange}
            key={group.id}
            onQuestionChange={onQuestionChange}
            initialQuestionValues={initialQuestionValues}
            group={group}
            errors={errors}
            validationHelpers={validationHelpers}
            isInModal={true}
            onRepeatableGroupChange={onRepeatableGroupChange}
          />
        );
    }
  };

  return (
    <>
      {question.title && (
        <CheckBoxGroupTitleStyles>
          {required ? t(question.title) : t(question.title) + t('question_optional_label')}
        </CheckBoxGroupTitleStyles>
      )}
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && <QuestionTitle>{t(question.header)}</QuestionTitle>}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={RadioButtonWrapper}
        name={name}
        id={question.questionKey}
        onChange={onChangeHandler}
        required={required}
        noGroupsRendered={!repeatableGroups.length && !conditionalGroups.length}
        value={inputValue}
        validateOnBlur
        validationHelpers={validationHelpers}
        question={question}
        values={!isFirst && defaultValues}
        onQuestionChange={onQuestionChange}
        disabled={disabled}
        isInModal={isInModal}
      />
      {conditionalGroups &&
        conditionalGroups.map((group) => {
          return renderHandler(group);
        })}
      {repeatableGroups &&
        repeatableGroups.map((group) => {
          return renderHandler(group);
        })}
    </>
  );
};

export default RadioButtonGroup;
