import axios from 'axios';
import { api, apiTranslate, apiIdentity, questionnaireApi } from './paths';

export const contentTypes = {
  ajax: 'application/json',
  form: 'application/x-www-form-urlencoded; charset=UTF-8',
  jsonPatch: 'application/json-patch+json',
  file: false
};

export const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
};

export const axiosLanguage = function (language) {
  requestJSON.defaults.headers.common['Accept-Language'] =
    language ?? localStorage.getItem('language');
  requestTranslate.defaults.headers.common['Accept-Language'] =
    language ?? localStorage.getItem('language');
};

export const responseTypes = {
  arrayBuffer: 'arraybuffer'
};

export const requestIdentity = axios.create({
  baseURL: apiIdentity,
  headers: {
    'Content-Type': 'application/json, application/json-patch+json',
    Accept: 'application/json, text/plain, */*'
  },
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    }
  ]
});

export const requestJSON = axios.create({
  baseURL: api,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  }
});

requestJSON.interceptors.request.use(function (config) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  config.headers.Authorization = token ? `Bearer ${token.accessToken}` : '';
  return config;
});
export const requestQuestionnaire = axios.create({
  withCredentials: true,
  baseURL: questionnaireApi,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': 'https://localhost:3000'
  }
});

requestQuestionnaire.interceptors.request.use(function (config) {
  const token = JSON.parse(
    sessionStorage.getItem('token') || sessionStorage.getItem('SSOLoginToken')
  );
  config.headers.Authorization = token ? `Bearer ${token.accessToken}` : '';
  return config;
});

export const requestAuth = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    credentials: 'include',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': 'https://localhost:3000'
  }
});

export const requestBO = axios.create({
  baseURL: api,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  }
});

export const requestFile = axios.create({
  baseURL: api,
  withCredentials: true,
  headers: {
    Accept: 'application/json, text/plain, */*'
  }
});
export const requestJSONSca = axios.create({
  baseURL: api,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  }
});

export const requestTranslate = axios.create({
  baseURL: apiTranslate,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  }
});

export const requestWithToken = axios.create({
  baseURL: api,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  }
});
