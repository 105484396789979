import { history, routerPath } from '../../Config';
import { logError } from '../../Utilities';

export function handleResponse(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.data;
  }
  if (response.status >= 300 && response.status <= 499) logError(response.statusText);
  if (response.status === 500) history.push(routerPath.errorPage);
  logError(response.statusText);
  return Promise.reject(response.statusText);
}
