import React, { useEffect, useState } from 'react';

import AsyncSelect from 'react-select/async';

import { customInputStyles, customStyles, SelectContainer, StyledLabel } from './select.styled';
import { ErrorMessage } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import { colors } from '@single-platform/components/dist/design/designVariables';

const InputWithLoadableOptions = ({
  setValue,
  defaultValue,
  shouldClearValue,
  name,
  fieldMeta,
  placeholder,
  onValueChange,
  onLoadOptions
}) => {
  const { t } = useTranslation();
  const { error } = fieldMeta || {};
  const [selectValue, setSelectValue] = useState(defaultValue || null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectValue) setValue?.(name, selectValue.value);
  }, [selectValue, name, setValue]);

  useEffect(() => {
    if (shouldClearValue) setSelectValue(null);
  }, [setInputValue, shouldClearValue]);

  return (
    <div>
      <SelectContainer hasError={!!error}>
        <AsyncSelect
          isClearable={true}
          placeholder=""
          value={selectValue}
          styles={{
            ...customStyles,
            ...customInputStyles,
            control: (provided) => ({
              ...provided,
              fontSize: '16px',
              lineHeight: '24px',
              height: '48px',
              padding: '0px 8px',
              borderRadius: '8px',
              outline: 'none',
              boxShadow: 'none',
              border: `1px solid ${
                error ? colors.base.red.shades[120] : colors.base.black.shades[10]
              }`,
              '&:focus-within': {
                borderColor: `${
                  error ? colors.base.red.shades[120] : colors.base.primary.shades[100]
                }`
              },
              '&:hover': {
                padding: '0px 8px',
                borderColor: colors.base.black.shades[40]
              }
            })
          }}
          loadOptions={async (inputValue) => {
            const options = await onLoadOptions?.(inputValue);
            if (options && options?.length > 0) {
              return options;
            } else {
              return [{ value: inputValue, label: inputValue }];
            }
          }}
          onChange={(v) => {
            setSelectValue(v);
            onValueChange?.(v);
          }}
          onInputChange={(v) => {
            setInputValue(v);
          }}
          noOptionsMessage={() => (
            <>Atitikmenų nerasta arba rasta per daug. Įveskite tikslesnį pavadinimą.</>
          )}
        />

        <StyledLabel
          htmlFor={placeholder}
          hasError={!!error}
          hasValue={!!(inputValue || selectValue?.label)}
        >
          {placeholder}
        </StyledLabel>
      </SelectContainer>
      <ErrorMessage style={{ paddingTop: '0.5rem', marginLeft: '1rem' }} hidden={false}>
        {t(error?.message || '')}
      </ErrorMessage>
    </div>
  );
};

export default InputWithLoadableOptions;
