import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import input from '@single-platform/components/dist/components/input/input';
import { Checkbox, Field, LinkButton, Toast } from '@single-platform/components';
import {
  FieldsetStyle,
  PersonalDataFormContainer
} from '../../../../components/personal-data-form/personalDataForm.styles';

import { useDispatch } from 'react-redux';
import {
  InputWithLoadableOptions,
  ButtonSection
} from '../../../../../ApplicationKYC/LegalEntityQuestionnaire/components';
import { AuthActions } from '../../../../../../Redux';
import { CountriesDropdown } from '../../../../components/countriesDropdown/countriesDropdown';
import { useFormValidation } from '../../../../../ApplicationKYC/LegalEntityQuestionnaire/validation/useFormValidation';
import { ClientConsentContainer, ClientConsentText } from './CompanyRepresentativeForm.style';
import countries from '../../../../components/countriesDropdown/countriesOfRegistrationList.json';
import { ModalStyles } from '../CompanyDisplayContainer.style';

export const CompanyRepresentativeForm = ({
  onSubmit,
  backButtonHandler,
  isLoading,
  companyExistsError
}) => {
  const [companyError, setCompanyError] = useState(companyExistsError);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchDataRef = useRef();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const validationSchema = Yup.object({
    country: Yup.string().required(t('validation_required_field')),
    companyName: Yup.string()
      .required(t('validation_required_field'))
      .test('test', t('validation_field_too_big', { maxLength: 100 }), (text) => {
        return text?.length <= 100;
      }),
    companyCode: Yup.string()
      .matches(/^[a-zA-Z0-9-]*$/, 'validation_company_code_is_not_valid')
      .required(t('validation_required_field')),
    TermsAndConditionsConsent: Yup.string()
      .test('match-true', 'validation_required_field', (value) => value === 'true')
      .required(t('validation_required_field')),
    MarketingDataUseConsent: Yup.string().optional(),
    MarketingNewsletterConsent: Yup.string().optional()
  });

  const initialValues = {
    country: 'LT',
    companyName: '',
    companyCode: '',
    TermsAndConditionsConsent: false,
    FetchOtherCompanyDataConsent: false,
    MarketingDataUseConsent: false,
    MarketingNewsletterConsent: false
  };

  const {
    validationHelpers,
    getValues,
    setTouchedOnAll,
    handleSubmit,
    validationHelpers: { setValue }
  } = useFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async () => {
    await handleSubmit(
      (data) => {
        localStorage.setItem(
          'companyRepresentativeData',
          JSON.stringify({
            companyName: getValues('companyName'),
            companyCode: getValues('companyCode')
          })
        );
        onSubmit?.(data);
      },
      // on invalid case
      (data) => setTouchedOnAll()
    )();
  };

  const handleLoadOptions = useCallback(async (inputVal) => {
    const data = await dispatch(AuthActions.getCompanies(inputVal));
    if (!data.payload) return [];
    if (data.payload) {
      const selectOptions = data.payload.map((companyData) => ({
        value: companyData.title,
        label: companyData.title,
        code: companyData.code
      }));
      searchDataRef.current = selectOptions;
      setCompanyError(null);
      return selectOptions;
    }
  }, []);

  const handleCompanyNameSelect = useCallback(
    (selectedOption) => {
      if (!selectedOption) {
        setValue('companyName', '');
        setValue('companyCode', '');
      }
      if (searchDataRef.current) {
        const selectedCompany = searchDataRef.current.filter(
          (companyData) => companyData.title === selectedOption?.label
        );
        if (selectedCompany[0]) {
          setValue('companyCode', selectedCompany[0].code);
          setValue('companyName', selectedCompany[0].label);
        } else if (selectedCompany) {
          setValue('companyCode', selectedOption?.code);
          setValue('companyName', selectedOption?.label);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTermsAndConditionsClick = () => {
    const language = localStorage.getItem('language');
    if (isBank) {
      language === 'en-GB'
        ? window.open('https://smebank.lt/en/general-agreements/', '_blank', 'noopener')
        : window.open('https://smebank.lt/bendrosios-sutarciu-salygos/', '_blank', 'noopener');
    } else {
      language === 'en-GB'
        ? window.open('https://smego.com/terms-conditions/', '_blank', 'noopener')
        : window.open('https://smego.com/lt/terms-and-conditions/', '_blank', 'noopener');
    }
  };

  const handlePrivacyPolicyClick = () => {
    const language = localStorage.getItem('language');
    if (isBank) {
      language === 'en-GB'
        ? window.open('https://smebank.lt/en/privacy-policy/', '_blank', 'noopener')
        : window.open('https://smebank.lt/privatumo-politika/', '_blank', 'noopener');
    } else {
      language === 'en-GB'
        ? window.open('https://smego.com/privacy-policy/', '_blank', 'noopener')
        : window.open('https://smego.com/lt/privacy-policy/', '_blank', 'noopener');
    }
  };

  useEffect(() => setCompanyError(companyExistsError), [companyExistsError]);

  return (
    <ModalStyles
      label={t('represented_company')}
      onCancel={backButtonHandler}
      maskClosable={false}
      isOpen={true}
      maxWidth={'36.75rem'}
      hideSecondaryAction={true}
      footer={
        <ButtonSection
          alignEnd={true}
          firstButtonClick={backButtonHandler}
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          firstButtonText={t('back')}
          secondButtonText={t('flow_selection_button')}
          secondButtonDisabled={!getValues('TermsAndConditionsConsent') || isLoading}
        />
      }
    >
      <PersonalDataFormContainer>
        <FieldsetStyle>
          <CountriesDropdown
            validationHelpers={validationHelpers}
            getValues={getValues}
            filterCountries={false}
            placeholder={t('question_country')}
            countries={countries}
          />
          {getValues('country') === 'LT' ? (
            <Field
              Component={InputWithLoadableOptions}
              name="companyName"
              defaultValue={undefined}
              placeholder={t('company_name')}
              onLoadOptions={handleLoadOptions}
              onValueChange={handleCompanyNameSelect}
              validationHelpers={validationHelpers}
              required
              validateOnChange
            />
          ) : (
            <Field
              Component={input}
              name="companyName"
              id="companyName"
              value={getValues('companyName')}
              placeholder={t('company_name')}
              validationHelpers={validationHelpers}
              required
              validateOnChange
            />
          )}

          <Field
            Component={input}
            name="companyCode"
            id="companyCode"
            value={getValues('companyCode')}
            placeholder={t('company_code')}
            validationHelpers={validationHelpers}
            validateOnChange
          />

          {companyError && (
            <Toast
              isVisible
              variant="error"
              header={
                <Trans
                  i18nKey={t(companyError)}
                  values={{
                    email: isBank ? 'info@smebank.lt' : 'onboarding@smego.com'
                  }}
                />
              }
            />
          )}

          <ClientConsentContainer>
            <Field
              Component={Checkbox}
              name="MarketingNewsletterConsent"
              id="MarketingNewsletterConsent"
              value={getValues('MarketingNewsletterConsent')}
              validationHelpers={validationHelpers}
              validateOnChange
            />
            <ClientConsentText>{t('marketing_news_letter_consent')}</ClientConsentText>
          </ClientConsentContainer>

          <ClientConsentContainer>
            <Field
              Component={Checkbox}
              name="MarketingDataUseConsent"
              id="MarketingDataUseConsent"
              value={getValues('MarketingDataUseConsent')}
              validationHelpers={validationHelpers}
              validateOnChange
            />
            <ClientConsentText>{t('marketing_data_use_consent')}</ClientConsentText>
          </ClientConsentContainer>

          <ClientConsentContainer>
            <Field
              Component={Checkbox}
              name="TermsAndConditionsConsent"
              id="TermsAndConditionsConsent"
              value={getValues('TermsAndConditionsConsent')}
              validationHelpers={validationHelpers}
              validateOnChange
            />
            <ClientConsentText>
              <Trans i18nKey="client_consent_text">
                Text
                <LinkButton onClick={handleTermsAndConditionsClick}>Link</LinkButton>
                Text
                <LinkButton onClick={handlePrivacyPolicyClick}>Link</LinkButton>
              </Trans>
            </ClientConsentText>
          </ClientConsentContainer>
        </FieldsetStyle>
      </PersonalDataFormContainer>
    </ModalStyles>
  );
};
