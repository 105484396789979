import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { RequestedDocsListItem } from '../Elements/RequestedDocsListItem';
import { Trans, useTranslation } from 'react-i18next';
import { ScalableButton } from '../../IndexContainer/indexContainer.styles';
import { Button } from '@single-platform/button';
import { KYCActions, OnboardingFlowHandlerActions, selectKycAppState } from '../../../Redux';
import { RefreshToken } from '../../../Utilities/refreshToken';
import './FillOrgStructure.less';

const { Panel } = Collapse;

export const UploadRequestedAppDocs = () => {
  const [fileList, setFileList] = useState([]);
  const [everyDocumentCompleted, setEveryDocumentCompleted] = useState(false);
  const [company, setCompany] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationState = useSelector(selectKycAppState);
  const documentState = useSelector((state) => state?.KYCReducers?.documents);

  const checkIfEveryDocumentCompleted = (documents) => {
    setEveryDocumentCompleted(
      documents.every((document) => {
        return document.files.length > 0;
      })
    );
  };

  const uploadSubmit = async () => {
    await dispatch(KYCActions.confirmApplicationDocumentsUpload(applicationState.applicationId));
    await dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
  };

  useEffect(() => {
    checkIfEveryDocumentCompleted(fileList);
  }, [fileList]);

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem('company'));
    dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
    setCompany(company);

    const tokenRefresh = async () => {
      await dispatch(RefreshToken());
    };
    tokenRefresh();
  }, []);

  const getApplicationDocumentRequests = async () => {
    if (applicationState?.applicationId)
      await dispatch(KYCActions.getApplicationDocumentRequests(applicationState.applicationId));
  };

  useEffect(() => {
    getApplicationDocumentRequests();
  }, [applicationState?.applicationId]);

  useEffect(() => {
    if (documentState) {
      setFileList(
        documentState.documentRequests.sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1))
      );
    }
  }, [documentState]);

  return (
    <div className="w-100">
      <div className="w-100">
        <h2 className="eb_fs_16">{t('upload_application_documents_header')}</h2>
      </div>
      <div className="w-100 mb-3 upload_card">
        <Collapse defaultActiveKey={['1']} accordion>
          {fileList.map(({ type, files, id, employeeComment }, i) => (
            <Panel
              header={<Trans i18nKey={t(`Upload${type}`)} />}
              key={i + 1}
              extra={files.length > 0 && <CheckCircleFilled className="check-icon" />}
              collapsible={true}
            >
              <RequestedDocsListItem
                documentRequestId={id}
                fileList={files}
                documentType={type}
                employeeComment={employeeComment}
                onFileUpload={getApplicationDocumentRequests}
                onFileDelete={getApplicationDocumentRequests}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className="w-100 text-right">
        <p className="agree-on-correct-document">{t('agree_on_correct_document')}</p>
        <ScalableButton>
          <Button disabled={!everyDocumentCompleted} onClick={uploadSubmit}>
            {t('upload')}
          </Button>
        </ScalableButton>
      </div>
    </div>
  );
};
