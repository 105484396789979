import React from 'react';
import { LoginBubbleStyles } from './LoginBubble.style';
import { ImageWrapper } from '../../../IndexContainer/indexContainer.styles';

export const LoginBubble = React.memo(() => {
  return (
    <LoginBubbleStyles>
      <ImageWrapper>
        <img src="/images/slider/onboarding_login.png" alt="start onboarding icon" />
      </ImageWrapper>
    </LoginBubbleStyles>
  );
});
