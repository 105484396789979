import styled from 'styled-components';

export const MobileInputWrapper = styled.div`
  .phoneInputButton .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -1px;
    left: 1.2rem;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
    scale: unset;
  }

  .phoneInputContainer .special-label {
    top: 0.8rem;
  }
`;
