import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const ValidationHelpers = {
  setValue: () => {},
  setTouched: () => {},
  getFieldState: () => {}
};

const useFormValidation = ({ initialValues, validationSchema }) => {
  const valueSetOptions = { shouldValidate: true, shouldTouch: true, shouldDirty: true };
  const valueSetWithoutValidationOptions = {
    shouldValidate: false,
    shouldTouch: false,
    shouldDirty: false
  };

  const {
    trigger,
    setValue,
    getValues,
    register,
    handleSubmit,
    getFieldState,
    control,
    formState: { errors, touchedFields: touched }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => Object.keys(initialValues).forEach((key) => register(key)), [initialValues]);

  const setValueWithValidation = (name, value) => setValue(name, value, valueSetOptions);
  const setValueWithoutValidation = (name, value) =>
    setValue(name, value, valueSetWithoutValidationOptions);

  const setTouched = (name) => setValueWithValidation(name, getValues(name));

  const setTouchedOnAll = () => Object.keys(initialValues).forEach((key) => setTouched(key));

  return {
    trigger,
    getValues,
    getFieldState,
    setTouchedOnAll,
    handleSubmit,
    control,
    errors,
    register,
    touched,
    validationHelpers: {
      setValue: setValueWithValidation,
      setValueWithoutValidation,
      setTouched,
      getFieldState
    }
  };
};

export { useFormValidation };
