import { Field, Fieldset } from '@single-platform/components';
import { ToastListContainer } from '@single-platform/components/dist/components/toast/toast.styles';
import styled from 'styled-components';

export const ContainerStyle = styled.div`
  margin-top: 2.5rem;
`;

export const PersonalDataFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${ToastListContainer} {
    margin-bottom: 0.625rem;
  }

  button {
    margin-top: 20px;
  }
`;

export const FieldsetStyle = styled(Fieldset)`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;

  div:has(> :first-child > label) {
    position: relative;
  }

  div {
    margin-bottom: 0 !important;
    label {
      margin-bottom: 0;
    }
  }
`;
