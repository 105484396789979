import React, { useEffect, useState } from 'react';
import { Notification } from '../../../Components/Notification';
import { printComponent } from '../../../Utilities';
import { Button } from '@single-platform/button';
import { styles } from '../Utilities/stylesForHtml';
import { RenderPerson } from './RenderPerson';
import { RenderCompany } from './RenderCompany';
import { RenderMainCompany } from './RenderMainCompany';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './FillOrgStructure.less';

let initData = {
  id: new Date().valueOf().toString(),
  type: 'c',
  level: 1,
  title: 'init', //to be prefilled
  owners: []
};

export const FillOrgStructure = ({ applicationCompany, orgStructure, beforeUpload }) => {
  const [orgStructureData, setOrgStructureData] = useState(initData);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const [isAddOwnerOn, setIsAddOwnerOn] = useState(null);
  const divRef = React.useRef();
  const divRef2 = React.useRef();
  const { t } = useTranslation();
  const traceId = useSelector((state) => state.OnboardingFlowReducer.traceId);

  useEffect(() => {
    if (applicationCompany && applicationCompany.companyCode && applicationCompany.companyName) {
      initData = {
        ...initData,
        id: applicationCompany.companyCode,
        title: applicationCompany.companyName
      }; //this changes id and title of main company
    }
    setOrgStructureData(initData);
  }, [applicationCompany, orgStructure]);

  useEffect(() => {
    if (orgStructure !== orgStructureData && !isAddOwnerOn) {
      setIsSaveButtonActive(true);
    } else {
      setIsSaveButtonActive(false);
    }
  }, [orgStructure, orgStructureData, isAddOwnerOn]);

  const handleSave = async () => {
    let htmlBody = divRef.current.outerHTML;
    const htmlWrapper = `<html><head><style>${styles}</style></head><body>${htmlBody}</body></html>`;
    const blob = await printComponent(divRef2.current, 'getblob', 'orgStructure.pdf');
    beforeUpload({
      orgStructure: orgStructureData,
      orgStructureHtml: htmlWrapper,
      orgStructurePdf: blob
    });
  };

  const updateDataRecursive = (data, id, newData) => {
    if (data.id === id) {
      const countTotalShare = data.owners.reduce(
        (count, item) => (count += item.share),
        newData.share
      );
      if (countTotalShare > 100) {
        Notification({
          type: 'error',
          message: t('company_shares_can_not_exceed_100'),
          description: traceId
        });
        return 'find';
      }
      data.owners.push(newData);
      return 'find';
    }
    if (data?.owners?.length) {
      for (let i = 0; i < data.owners.length; i++) {
        const stop = updateDataRecursive(data.owners[i], id, newData);
        if (stop) {
          break;
        }
      }
    }
  };

  const submitForm = (type, title, share, level, parentIndex, parentId) => {
    let newData = orgStructureData;
    const newId = new Date().valueOf().toString();

    if (type) {
      updateDataRecursive(newData, parentId, {
        id: newId,
        type: type,
        level: level + (type === 'c' || level === 0 ? 1 : 0),
        title: title,
        share: share,
        owners: []
      });
    }
    setOrgStructureData({ ...newData });
    setIsAddOwnerOn(null);
    initData = {
      ...initData,
      owners: []
    };
  };

  const handleAddOwner = (level, id, index) => {
    if (
      !isAddOwnerOn ||
      level !== isAddOwnerOn.level ||
      index !== isAddOwnerOn.index ||
      id !== isAddOwnerOn.id
    ) {
      setIsAddOwnerOn({ level, index, id });
    } else {
      setIsAddOwnerOn(null);
    }
  };

  const handleRemoveOwner = (id) => {
    let newData = orgStructureData;
    removeDataRecursive(newData, id);
    setOrgStructureData({ ...newData });
  };

  const removeDataRecursive = (data, id) => {
    if (data.owners.find((item) => item.id === id)) {
      data.owners = data.owners.filter((item) => item.id !== id);
      return 'find';
    }
    if (data?.owners?.length) {
      for (let i = 0; i < data.owners.length; i++) {
        const stop = removeDataRecursive(data.owners[i], id);
        if (stop) {
          break;
        }
      }
    }
  };

  return (
    <div ref={divRef} id="FillOrgStructureContainer" className="content">
      <div className="panel panel-shadow panel-spaced">
        <div ref={divRef2} className="owners-form">
          {orgStructureData &&
            orgStructureData.title &&
            orgStructureData.level &&
            orgStructureData.type && (
              <RenderMainCompany
                data={{ orgStructureData, isAddOwnerOn, submitForm, handleAddOwner }}
              />
            )}
          {orgStructureData &&
            orgStructureData.owners &&
            orgStructureData.owners.length > 0 &&
            orgStructureData.owners.map((owner, index) => {
              const { type } = owner;
              const isNotTheLast = orgStructureData.owners.length - 1 > index;
              const className = 'subowners';
              if (type && type === 'c') {
                return (
                  <React.Fragment key={index}>
                    <RenderCompany
                      data={{
                        ...owner,
                        isNotTheLast,
                        className,
                        index,
                        parentIndex: 0,
                        parentId: orgStructureData.id,
                        isAddOwnerOn,
                        onRemoveClick: handleRemoveOwner,
                        addOwnerClick: handleAddOwner,
                        submitForm
                      }}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <RenderPerson
                      data={{
                        ...owner,
                        isNotTheLast,
                        className,
                        index,
                        parentIndex: 0,
                        parentId: orgStructureData.id,
                        isAddOwnerOn,
                        onRemoveClick: handleRemoveOwner
                      }}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>

        <div id="removable-button">
          <div className="btn-group btn-group-right file-org-btn-container">
            <Button
              variant="Stroked"
              color="Black"
              onClick={() =>
                printComponent(
                  divRef2.current,
                  'download',
                  `${orgStructureData.title || 'KYC'}.pdf`
                )
              }
              className="mr-3"
              disabled={!isSaveButtonActive || !orgStructureData.owners.length}
            >
              {t('button_print')}
            </Button>
            <Button
              onClick={handleSave}
              disabled={!isSaveButtonActive || !orgStructureData.owners.length}
            >
              {t('button_save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
