import { createSelector } from 'reselect';

export const selectAppConfigState = createSelector(
  (state) => state,
  (state) => state?.AppConfigReducer
);

export const selectClientRiskCraScreen = createSelector(
  selectAppConfigState,
  (state) => state.allClientRiskCraScreen
);

export const selectCountriesConfig = createSelector(
  selectAppConfigState,
  (state) => state?.countriesRisksConfig
);

export const selectCountryConfigById = createSelector(
  [selectCountriesConfig, (_state, params) => params?.countryId || ''],
  (countryConfigs, countryId) => {
    return countryConfigs.items?.find((config) => config.id === countryId);
  }
);

export const selectRisksConfig = createSelector(selectAppConfigState, (state) => state.risksConfig);

export const selectRisksConfigLoading = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.riskList
);

export const selectNaceBlockersConfig = createSelector(
  selectAppConfigState,
  (state) => state.naceBlokersConfig
);

export const selectCraRisksLoading = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.allClientRiskCraScreen
);

export const selectNaceBlockersConfigLoading = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.naceBlockers
);

export const selectNaceBlockerUpdating = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.updateNaceBlockersItem
);

export const selectCountryRiskUpdating = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.updateCountryRisk
);

export const selectIsCountriesConfigLoading = createSelector(
  selectAppConfigState,
  (state) => state.isLoading.countriesRiskList
);
