import { ChipOption } from '@single-platform/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckMarkLogo, SmeBankCheckmark } from '../../../../../../Assets/images/logos';

export const CheckboxGroupWrapper = ({
  question,
  required,
  onChange,
  validationHelpers,
  disabled,
  name,
  on,
  fieldMeta,
  errorInfoCallback,
  option: { value, optionLabel, optionValue }
}) => {
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  useEffect(() => {
    errorInfoCallback(showValidationMessage, hasValidationMessages, message);
  }, [message, showValidationMessage, hasValidationMessages]);

  return (
    <>
      <ChipOption
        variant="checkbox"
        name={name}
        key={optionLabel}
        id={question.questionKey}
        onChange={(e) => onChange(e, optionLabel, optionValue)}
        required={required}
        checked={value}
        value={value}
        on={on}
        validationHelpers={validationHelpers}
        disabled={disabled}
      >
        {value && (isBank ? <SmeBankCheckmark /> : <CheckMarkLogo />)}
        {t(optionLabel)}
      </ChipOption>
    </>
  );
};
