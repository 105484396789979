import { createSelector } from 'reselect';
import { CLASSIFICATOR_INSTTTNL_SCTR_ALLOWED } from '@config/translationOverrides';

export const selectTranslationState = createSelector(
  (state) => state,
  (state) => state?.translationReducer
);

const selectLanguage = createSelector(
  (state) => state,
  (state) => state?.languageReducer.language || 'lt-LT'
);

export const selectClassificators = createSelector(
  selectTranslationState,
  selectLanguage,
  (translations, language) =>
    translations.translations?.find(
      (tr) => tr.context === 'BBS.Classificators' && tr.culture === language
    )?.Groups
);

export const selectLegalFirmsClassificator = createSelector(
  selectClassificators,
  (state) => state?.LGL_FRM
);

export const selectAvailableCountryOptions = createSelector(selectClassificators, (state) => {
  const order = ['LT', 'LV', 'EE', '.*'];

  const countryOptions = Object.keys(state?.CISO || {}).map((countryKey) => ({
    value: countryKey,
    label: state?.CISO[countryKey]
  }));

  countryOptions.sort((a, b) => {
    const aI = order.findIndex((element) => a.value.match(element));
    const bI = order.findIndex((element) => b.value.match(element));
    return aI - bI;
  });

  return countryOptions;
});

export const selectWhoOpenAccountOptionsAll = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CMP || {}).map((key) => ({ label: state.CMP[key], value: key }))
);

export const selectWhoOpenAccountOptions = createSelector(selectWhoOpenAccountOptionsAll, (state) =>
  state.filter((opt) => opt.value !== 'CMP1')
);

export const selectDocTypeOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.DOCS || {})
    .filter((key) => key === 'DOCS1' || key === 'DOCS6')
    .map((key) => ({ label: state.DOCS[key], value: key }))
);

export const selectPepList = createSelector(selectClassificators, (state) =>
  Object.keys(state?.PEPLIST || {})
    .filter((key) => key === 'PEPLIST1' || key === 'PEPLIST2')
    .map((key) => ({ label: state.PEPLIST[key], value: key }))
);

export const selectDocsAllOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.DOCS || {})
    .filter((key) => key !== 'DOCSA8' || key !== 'DOCSA9')
    .map((key) => ({ label: state.DOCS[key], value: key }))
);

export const selectSWCOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.SWC || {})
    .map((key) => ({ label: state.SWC[key], value: key }))
    .sort((a, b) => {
      if (a.label.length > b.label.length) return 1;
      if (a.label.length < b.label.length) return -1;
      return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
    })
);

export const selecetBeneficators = createSelector(selectClassificators, (state) =>
  Object.keys(state?.BENEF || {}).map((key) => ({ label: state.BENEF[key], value: key }))
);

export const selectBasicBusinessOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CBB || {}).map((key) => ({ label: state.CBB[key], value: key }))
);

export const selectBrokenDownGroups = createSelector(selectClassificators, (state) => {
  const businessGroups = {};
  const businessSubGroups = {};

  for (const key of Object.keys(state?.CBB || {})) {
    // means its a main group
    if (key.split('.').length === 2) {
      businessGroups[key] = { label: state.CBB[key], value: key, subGroups: [] };
    } else {
      businessSubGroups[key] = { label: state.CBB[key], value: key };
    }
  }

  for (const group of Object.keys(businessGroups)) {
    for (const subGroup of Object.keys(businessSubGroups)) {
      if (subGroup.includes(group)) {
        businessGroups[group].subGroups.push(businessSubGroups[subGroup]);
      }
    }
  }
  return businessGroups;
});

export const selectBasicBusinessOptionsGroups = createSelector(selectBrokenDownGroups, (state) =>
  Object.keys(state)
    .map((key) => ({ label: state[key].label, value: state[key].value }))
    .sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    })
);

const getGroup = (_, group) => group;

export const selectBasicBusinessSubGroupOptions = createSelector(
  selectBrokenDownGroups,
  getGroup,
  (businessGroups, group) => businessGroups?.[group]?.subGroups || []
);

export const selectSectorsOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.INSTTTNL_SCTR || {})
    .filter((key) => CLASSIFICATOR_INSTTTNL_SCTR_ALLOWED.includes(key))
    .map((key) => ({ label: state.INSTTTNL_SCTR[key], value: key }))
);

export const selectClientSizeOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CSIZE || {}).map((key) => ({ label: state.CSIZE[key], value: key }))
);

export const selectIncomingPaymentOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.INCPM || {}).map((key) => ({ label: state.INCPM[key], value: key }))
);

export const selectIncomingTaxesOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.INCRM || {}).map((key) => ({ label: state.INCRM[key], value: key }))
);

export const selectPaymentsByCashOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CPFSCH || {}).map((key) => ({ label: state.CPFSCH[key], value: key }))
);

export const selectPaymentsByCryptoOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CPFSC || {}).map((key) => ({ label: state.CPFSC[key], value: key }))
);

export const selectFundsRecCountrys = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CFURC || {}).map((key) => ({ label: state.CFURC[key], value: key }))
);

export const selectPEN = createSelector(selectClassificators, (state) =>
  Object.keys(state?.PEN || {}).map((key) => ({ label: state.PEN[key], value: key }))
);

export const selectPaymentsOut = createSelector(selectClassificators, (state) =>
  Object.keys(state?.OUTPM || {}).map((key) => ({ label: state.OUTPM[key], value: key }))
);

export const selectPaymentsOutAmount = createSelector(selectClassificators, (state) =>
  Object.keys(state?.OUTRM || {}).map((key) => ({ label: state.OUTRM[key], value: key }))
);

export const selectFundsSentCountries = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CFUSC || {}).map((key) => ({ label: state.CFUSC[key], value: key }))
);

export const selectBusinessPartners = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CBP || {}).map((key) => ({ label: state.CBP[key], value: key }))
);

export const selectCurrencyExchangeOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CUREX || {}).map((key) => ({ label: state.CUREX[key], value: key }))
);

export const selectLoanSumOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.LNAM || {}).map((key) => ({ label: state.LNAM[key], value: key }))
);

export const moneyInAccOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.MCA || {}).map((key) => ({ label: state.MCA[key], value: key }))
);

export const selectClientChannelOptions = createSelector(selectClassificators, (state) =>
  Object.keys(state?.CCHAN || {}).map((key) => ({ label: state.CCHAN[key], value: key }))
);
