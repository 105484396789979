import { DownloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AmlQuestionnaireTemplateDownload = ({ documentType }) => {
  const [fileData, setFileData] = useState({});
  const { t } = useTranslation();
  const language = localStorage.getItem('language') || 'lt-LT';

  const getFileData = () => {
    setFileData({
      href:
        language === 'lt-LT'
          ? `/doc/Klausimynas pagal AML ir CTF įstatymus ipareigotojo subjekto_SMEB_LT.docx`
          : `/doc/Questionnaire under AML and CTF law Obliged Subjects_SMEB_EN.docx`,
      download: language === 'lt-LT' ? 'Klausimynas.docx' : 'Questionnaire.docx'
    });
  };

  useEffect(() => getFileData(), []);

  return (
    <>
      <p>{t('UploadAmlQuestionnaire_template_description')}</p>
      <div>
        <a href={fileData.href} download={fileData.download}>
          <DownloadOutlined className="mr-2" />
          <b>{t('UploadAmlQuestionnaire_template')}</b>
        </a>
      </div>
    </>
  );
};
