import styled from 'styled-components';

export const ButtonSectionStyle = styled.div`
  height: auto;
  display: flex;
  justify-content: space-around;
  gap: ${(props) => props.alignEnd && '1rem'};
  @media only screen and (max-width: 470px) {
    gap: 0.5rem;
    button {
      width: 100%;
    }
  }

  @media only screen and (min-width: 471px) {
    justify-content: ${(props) =>
      props.oneButton || props.alignEnd ? 'flex-end' : 'space-between'};
    height: auto;
    flex-direction: row;
    margin-top: 1rem;
  }
`;
