let temp = [];
let initValues = [];

const createValidationSchema = (
  questions,
  action,
  schema,
  t,
  questionnaireChoices,
  questionnaireState
) => {
  let prevAction;
  if (action !== prevAction) {
    temp = [];
    initValues = [];
  }

  prevAction = action;

  questions.forEach((question) => {
    const value = question.value === undefined ? '' : question.value;
    temp = [
      ...temp,
      [
        Object.assign({}, { [question.questionKey]: value }),
        question.type,
        questionnaireChoices[question.choiceKey],
        question.required,
        question.minValue,
        question.maxValue,
        question.maxLength,
        question.minLength
      ]
    ];
  });
  if (temp.length > 0) {
    initValues = temp.map(
      ([fields, fieldType, choices, required, minValue, maxValue, maxLength, minLength]) => {
        if (required) {
          Object.keys(fields).forEach((key) => {
            schema.build(key, fieldType, choices, t, minValue, maxValue, maxLength, minLength);
          });
        }
        return fields;
      }
    );
  }
  const validationSchema = schema.create();
  const initialValues = Object.assign({}, ...initValues);

  return {
    validationSchema,
    initialValues
  };
};

export { createValidationSchema };
