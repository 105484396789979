import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  LoginLabelContainer,
  LoginMethodName,
  LoginPoweredByText
} from './loginMethodButton.styles';
import eParaksts from './pictograms/eParaksts.svg';
import smartId from './pictograms/smartId.svg';
import mobileId from './pictograms/mobileId.svg';
import idin from './pictograms/idin.svg';
import ftn from './pictograms/ftn.svg';

const pictograms = {
  eParakts: eParaksts,
  'Smart-ID': smartId,
  FTN: ftn,
  IDIN: idin,
  'Mobile-ID': mobileId
};

const sublabel = {
  eparakts: 'poweredByEIDEasy',
  smartId: '',
  FTN: '',
  IDIN: '',
  mobilId: ''
};

export const LoginMethodButton = ({ method, onClick }) => {
  const imgSrc = pictograms[method];
  const { t } = useTranslation();

  return (
    <Button onClick={() => onClick(method)}>
      {imgSrc ? <img src={imgSrc} alt={t(method)} /> : null}
      <LoginLabelContainer>
        <LoginMethodName> {t(method)}</LoginMethodName>
        {sublabel[method] ? <LoginPoweredByText>{t(sublabel[method])}</LoginPoweredByText> : null}
      </LoginLabelContainer>
    </Button>
  );
};
