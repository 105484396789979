export const checkJsonProp = function (object, key) {
  try {
    let keys = key.split('.');
    object = object[keys[0]];
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]];
    }
    if (object === undefined) {
      return null;
    }
    return object;
  } catch (e) {
    return null;
  }
};

const SIZES = {
  Micro: 'CSIZE1',
  Small: 'CSIZE2',
  Medium: 'CSIZE3',
  Large: 'CSIZE4',
  Default: 'CSIZE'
};

export const checkClientSize = (value) => SIZES?.[value] || SIZES.Default;

export const checkJsonExist = function (object, key) {
  try {
    let keys = key.split('.');
    object = object[keys[0]];
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]];
    }
    return object !== undefined;
  } catch (e) {
    return null;
  }
};

export const checkJsonTranslateProp = function (object, key) {
  try {
    let keys = key.replace(/\./, '-i18-').split('-i18-');
    object = object[keys[0]];
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]];
    }
    if (object === undefined) {
      return null;
    }
    return object;
  } catch (e) {
    return null;
  }
};

export const checkJsonValid = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// https://stackoverflow.com/a/35002237
export const checkIsBase64String = (str) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(str);
};
