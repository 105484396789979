export default function FillLocalStorage(input) {
  const answers = localStorage.getItem('answers');
  if (answers) {
    const prevAnswers = JSON.parse(answers).filter(
      (answer) => answer.inputName !== input.inputName
    );
    const newAnswers = [...prevAnswers, input];
    localStorage.setItem('answers', JSON.stringify(newAnswers));
  } else {
    localStorage.setItem('answers', JSON.stringify([input]));
  }
}
