import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';
import { shouldRenderConditionalGroup } from '../../conditionalGroupsService/conditionalGroups.service';
import { RenderGroup } from '../../screens/renderGroup/renderGroup';
import { MultiselectWrapper } from './multiselectWrapper';
import { Field } from '@single-platform/components';

const Multiselect = ({
  options,
  placeholder,
  onChange,
  type,
  onBlur,
  required,
  value,
  question,
  onQuestionChange,
  disabled,
  onInputChange,
  errors,
  validationHelpers,
  name
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const { t } = useTranslation();

  const getValues = (value) => {
    setSelectedValue(value);
  };

  return (
    <>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={MultiselectWrapper}
        placeholder={placeholder}
        validationHelpers={validationHelpers}
        required={required}
        options={options}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        question={question}
        name={name}
        onQuestionChange={onQuestionChange}
        value={value}
        valuesCallback={getValues}
      />
      {question.conditionalGroups?.map(
        (conditionalGroup) =>
          shouldRenderConditionalGroup(selectedValue, conditionalGroup.condition) &&
          conditionalGroup.groups.map((group) => (
            <RenderGroup
              onInputChange={onInputChange}
              key={group.id}
              errors={errors}
              onQuestionChange={onQuestionChange}
              group={group}
              validationHelpers={validationHelpers}
              isInModal={true}
            />
          ))
      )}
    </>
  );
};

export default Multiselect;
