import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { Logout, secondsToMinutesWithSeconds } from '@utilities';
import { useTranslation } from 'react-i18next';
import { RefreshToken } from '../../Utilities/refreshToken';

const TIMER_IN_SECONDS = 60;

export const UserActive = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [timer, setTimer] = useState(TIMER_IN_SECONDS);

  useEffect(() => {
    const logoutTimer = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }

      if (timer === 0) {
        Logout();
        onClose();
      }
    }, 1000);
    return () => {
      clearInterval(logoutTimer);
    };
  }, [timer]);

  const onContinue = async () => {
    const parsedItem = JSON.parse(sessionStorage.getItem('token'));
    const refreshToken = parsedItem ? parsedItem.refreshToken : null;
    if (refreshToken) {
      await dispatch(RefreshToken());
    }
    onClose();
  };

  const onCancel = () => {
    Logout();
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      maskClosable={false}
      closable={false}
      title={t('inactive_refresh_modal_title')}
      okText={t('button_continue')}
      onCancel={onCancel}
      cancelText={t('button_logout')}
      onOk={onContinue}
      zIndex={1000000000000}
      centered={true}
    >
      {`${t('inactive_refresh_modal_you_will_be_logged_out_text')} ${secondsToMinutesWithSeconds(
        timer
      )}`}
    </Modal>
  );
};
