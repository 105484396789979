import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCompanyHeader } from '../../Containers/Auth/Modals/CompanyModal/CompanyModal.style';
import { Button } from '@single-platform/button';
import {
  UnauthorizedCompanyModalButtonContainer,
  UnauthorizedCompanyModalSubTextStyle
} from './UnauthorizedCompanyModal.style';
import CreateEmptyCompanyButton from '../../Containers/Auth/Modals/CompanyModal/CompanyDisplayContainer/CreateEmptyCompanyButton/CreateEmptyCompanyButton';
import { Modal } from '@single-platform/components';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../Redux';
import { getFormattedLanguageFromStorage, logError } from '../../Utilities';

export const UnauthorizedCompanyModal = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getIdentificationUrl = async () => {
    const windowReference = window.open();
    const language = getFormattedLanguageFromStorage();
    try {
      const response = await dispatch(AuthActions.getIdentificationUrl(language));
      windowReference.location = response?.payload?.redirectUrl;
    } catch (e) {
      logError(e);
    }
  };

  return (
    <Modal onCancel={onCancel} maskClosable={false} isOpen={true} hideSecondaryAction={true}>
      <ModalCompanyHeader>{t('no_authorized_company_text')}</ModalCompanyHeader>
      {(process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'STAGE') && (
        <CreateEmptyCompanyButton />
      )}
      <UnauthorizedCompanyModalSubTextStyle>
        {t('no_authorized_company_sub_text')}
      </UnauthorizedCompanyModalSubTextStyle>
      <UnauthorizedCompanyModalButtonContainer>
        <Button variant="Stroked" color="Black" onClick={onCancel}>
          {t('button_cancel')}
        </Button>
        <Button onClick={getIdentificationUrl}>
          {t('no_authorized_company_button_verify_identity')}
        </Button>
      </UnauthorizedCompanyModalButtonContainer>
    </Modal>
  );
};
