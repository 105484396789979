import React, { useEffect, useState } from 'react';
import {
  CompanyInfoCardContainer,
  CompanyInitialsContainer,
  CompanyNameCodeWrapperStyles
} from './CompanyInfoCard.style';
import {
  CompanyCodeStyles,
  CompanyNameStyles
} from '../../../CompanyModal/CompanyDisplayContainer/CompanyContainer/CompanyContainer.style';

export const CompanyInfoCard = () => {
  const [firstLetter, setFirstLetter] = useState('');
  const [secondLetter, setSecondLetter] = useState('');
  const { companyName, companyCode } = JSON.parse(
    localStorage.getItem('companyRepresentativeData')
  );

  useEffect(() => {
    createInitials(companyName);
  }, [companyName]);

  const createInitials = (companyName) => {
    const wordsArray = companyName
      .trim()
      .split(/\s+/)
      .map((word) => word.replace(/[^a-zA-Z]/g, ''));
    if (wordsArray.length >= 2) {
      setFirstLetter(wordsArray[0][0]);
      setSecondLetter(wordsArray[1][0]);
    } else {
      setFirstLetter(wordsArray[0][0]);
      setSecondLetter(wordsArray[0][1]);
    }
  };

  return (
    <CompanyInfoCardContainer>
      <CompanyInitialsContainer>{`${firstLetter}${secondLetter}`}</CompanyInitialsContainer>
      <CompanyNameCodeWrapperStyles>
        <CompanyNameStyles>{companyName}</CompanyNameStyles>
        <CompanyCodeStyles>{companyCode}</CompanyCodeStyles>
      </CompanyNameCodeWrapperStyles>
    </CompanyInfoCardContainer>
  );
};
