import { LanguageConstant } from './constants';

export const LanguageActions = {
  setLanguage
};

function setLanguage(culture) {
  return (dispatch) => {
    dispatch(changeLanguage(culture));
  };

  function changeLanguage(data) {
    return { type: LanguageConstant.CHANGE_LANGUAGE, payload: data };
  }
}
