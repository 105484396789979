import styled from 'styled-components';

export const ButtonSectionStyle = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: start;
  gap: 20px;

  @media only screen and (min-width: 481px) {
    height: auto;
    flex-direction: row;
  }
`;
