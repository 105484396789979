import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 5rem;
  margin-bottom: 7rem;
  text-align: center;
`;

export const AuthenticationDialogStyles = styled.div`
  display: flex;
  justify-content: center;
`;
