import { createSelector } from 'reselect';
import { selectLegalFirmsClassificator } from './classificators';
import { selectPrefillCompanyTypeList } from './KYC';

export const selectLegalFirmsOptions = createSelector(
  selectPrefillCompanyTypeList,
  selectLegalFirmsClassificator,
  (prefillFirms, classificatorFirms) => {
    if (prefillFirms) return prefillFirms;
    return Object.keys(classificatorFirms || {}).map((firmKey) => ({
      companyDescription: classificatorFirms[firmKey],
      companyCode: firmKey
    }));
  }
);
