export const mobileApp = () => (next) => (action) => {
  if (!action) return;
  const result = next(action);
  const smeGoSession = sessionStorage.getItem('smeGoSession');

  if (
    action?.payload?.applicationState?.state === 'WaitingForAmlOfficerKycReview' &&
    !!smeGoSession
  ) {
    window.postMessage('navigation_from_ob_flow_DASHBOARD');
  }
  return result;
};
