import { createSelector } from 'reselect';

//Onboarding flow selectors

export const OnboardingFlowState = createSelector(
  (state) => state,
  (state) => state.OnboardingFlowReducer
);

export const languageSelector = createSelector(
  (state) => state,
  (state) => state.OnboardingFlowReducer.language
);

//Questionnaire selectors

export const QuestionnaireState = createSelector(
  (state) => state,
  (state) => state.QuestionnaireReducer
);

export const formKeyInEdit = createSelector(QuestionnaireState, (state) => state.formKeyInEdit);
