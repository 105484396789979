import React from 'react';
import { LoginMethodButton } from '../loginMethodButton/loginMethodButton';
import { List } from './loginMethodsList.styles';

const LoginMethodsList = ({ loginTypes, onSwitch, renderSwitch }) => {
  const onClickHandler = (tab) => {
    onSwitch(tab);
  };

  return (
    <List>
      {loginTypes.map((type, index) => {
        return <LoginMethodButton method={type} onClick={onClickHandler} key={index} />;
      })}
    </List>
  );
};

export default LoginMethodsList;
