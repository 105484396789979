import React, { useEffect, useState } from 'react';
import { UserIdentification } from '../../UserIdentification';
import { AuthActions } from '../../../../Redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logError } from '../../../../Utilities';

const ExternalPersonsInitialStepper = [
  {
    title: 'representative_business_profile_verify_identity',
    completed: false,
    icon: 'RepresentativePerson',
    buttonTitle: 'representative_business_profile_verify_identity_button',
    buttonLink: '',
    isButtonActive: false,
    isActive: true
  },
  {
    title: 'external_persons_profile_get_login_header',
    completed: false,
    icon: 'RepresentativeCompany',
    buttonTitle: '',
    buttonLink: '',
    isButtonActive: false,
    isActive: false,
    pending: false,
    description: 'external_persons_profile_get_login_subheader'
  }
];

const ExternalPersonsTitle = {
  header: 'external_persons_header',
  subheader: 'external_persons_subheader'
};

export const ExternalPersons = () => {
  const [groupId, setGroupId] = useState('');
  const [externalPersonId, setExternalPersonId] = useState('');
  const [error, setError] = useState(false);
  const [steps, setSteps] = useState(ExternalPersonsInitialStepper);
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const updateSteps = (data) => {
    setSteps((currentSteps) => {
      const updatedSteps = [...currentSteps];

      if (!data) {
        return ExternalPersonsInitialStepper;
      }

      if (data.companyName) {
        setCompanyName(data.companyName);
      }

      if (data.ondatoRedirect) {
        updatedSteps[0] = {
          ...updatedSteps[0],
          buttonLink: data.ondatoRedirect
        };
        return updatedSteps;
      }

      if (data.ondatoStatus === 'NotFound') {
        updatedSteps[0] = {
          ...updatedSteps[0],
          isButtonActive: true
        };
        return updatedSteps;
      }

      if (data.ondatoStatus === 'Completed') {
        updatedSteps[0] = {
          ...updatedSteps[0],
          completed: true,
          isActive: true,
          isButtonActive: false
        };
        updatedSteps[1] = {
          ...updatedSteps[1],
          completed: false,
          isActive: true,
          isButtonActive: false,
          pending: true
        };
        return updatedSteps;
      }
      return currentSteps;
    });
  };

  const statusCallback = async () => {
    try {
      const { payload } = await dispatch(
        AuthActions.getExternalPersonInformation(groupId, externalPersonId, i18n.language)
      );
      updateSteps(payload);
    } catch (e) {
      updateSteps();
      setError(true);
    }
  };

  const verificationCallback = async () => {
    try {
      const { payload } = await dispatch(
        AuthActions.startExternalPersonVerification(groupId, externalPersonId, i18n.language)
      );
      updateSteps(payload);
    } catch (e) {
      logError(e);
    }
  };

  useEffect(() => {
    const regex = /\/Company\/([0-9a-fA-F-]+)\/ExternalPersons\/([0-9a-fA-F-]+)\/Status/;
    const match = window.location.pathname.match(regex);

    if (match) {
      setGroupId(match[1]);
      setExternalPersonId(match[2]);
    } else {
      setError(true);
    }
  }, []);

  return (
    <UserIdentification
      actionInfo={{
        error,
        groupId,
        externalPersonId,
        userIdentificationAction: 'ExternalPerson'
      }}
      steps={steps}
      statusCallback={statusCallback}
      verificationCallback={verificationCallback}
      companyName={companyName}
      title={ExternalPersonsTitle}
    />
  );
};
