import styled from 'styled-components';

export const OrgStuctureFormStyles = styled.form`
  display: flex;
  flex: 1;
  justify-content: space-around;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;
