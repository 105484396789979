import { aPIPaths, handleResponse, methods, requestBO, requestJSON } from '../../Config';

const getCountryRiskList = async (data) => {
  const response = await requestBO(aPIPaths.countryRiskList, {
    method: methods.post,
    data
  });
  return handleResponse(response);
};

const updateCountryRisk = async (data) => {
  const response = await requestBO(aPIPaths.updateCountryRisk, {
    method: methods.put,
    data
  });
  return handleResponse(response);
};

const getRiskList = async (data) => {
  const response = await requestBO(aPIPaths.riskList, {
    method: methods.post,
    data
  });
  return handleResponse(response);
};

const getCalculateAllClientRisks = async () => {
  const response = await requestBO(aPIPaths.calculateAllClientsRiskCraScreen, {
    method: methods.get
  });
  return handleResponse(response);
};

const updateRiskItem = async (data) => {
  const response = await requestBO(aPIPaths.updateRiskItem, {
    method: methods.put,
    data
  });
  return handleResponse(response);
};

const getNaceBlockers = async (data) => {
  const response = await requestBO(aPIPaths.naceBlockers, {
    method: methods.post,
    data
  });
  return handleResponse(response);
};

const updateNaceBlockersItem = async (data) => {
  const response = await requestBO(aPIPaths.updateNaceBlockersItem, {
    method: methods.put,
    data
  });
  return handleResponse(response);
};

const updateApplicationSettings = async (data) => {
  const response = await requestJSON(aPIPaths.updateApplicationSettings, {
    method: methods.post,
    data: JSON.stringify(data)
  });
  return handleResponse(response);
};

export const AppConfigService = {
  getCountryRiskList,
  getRiskList,
  updateCountryRisk,
  updateRiskItem,
  getNaceBlockers,
  updateNaceBlockersItem,
  getCalculateAllClientRisks,
  updateApplicationSettings
};
