import React, { useCallback, useState, useMemo, useEffect, memo } from 'react';
import { ButtonSection } from '../../components';
import { RenderSection } from '../renderSection/renderSection';
import { Stepper } from '@single-platform/components';
import { useFormValidation } from '../../validation/useFormValidation';
import CreateSchema from '../../validation/schemaBuilder';
import {
  ApplicationConsentCheckboxStyles,
  ButtonSectionWrapperStyle,
  Container,
  FormStyles,
  PageHeader,
  PageHeaderContainer,
  PageSubtitle,
  QuestionnaireWrapper,
  StepperWrapper
} from './renderPage.style';
import useWindowDimensions from '../../Utils/window-size.service';
import breakpointSizes from '../../styled-theme-styles/global-style-variables.style';
import { createValidationSchema } from '../../hooks/createValidationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionnaireActions } from '@redux';
import { KYCActions, OnboardingFlowHandlerActions, selectKycAppState } from '../../../../../Redux';
import { useTranslation } from 'react-i18next';
import {
  QuestionnaireState,
  formKeyInEdit
} from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import FillLocalStorage from '../../Utils/answersToLocalStorage';
import _ from 'lodash';
import LoaderWithText from '../../components/loaderWithText/loaderWithText';
import { setUrlQueryParameter } from '../../hooks/setUrlQueryParameter';
import { StructureModal } from '../../../../Auth/Modals/StructureModal/StructureModal';

export const RenderPage = memo(
  ({
    page,
    totalPages,
    steps,
    handlePrevStep,
    handleNextStep,
    handleNotCompletedStep,
    handleSteps,
    allPagesCompleted,
    pageFunctionalProps: { wasStructuralModalOpened, setWasStructuralModalOpened }
  }) => {
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [applicationConsent, setApplicationConsent] = useState(false);
    const [repeatableGroupsCompleted, setRepeatableGroupsCompleted] = useState(true);
    const [repeatableGroups, setRepeatableGroups] = useState([]);
    const { width } = useWindowDimensions();
    const { handleSubmit, validationHelpers, setTouchedOnAll, errors } = useFormValidation({
      initialValues,
      validationSchema
    });
    const dispatch = useDispatch();
    const questionnaireState = useSelector(QuestionnaireState);
    const applicationState = useSelector(selectKycAppState);
    const { t } = useTranslation();
    const [isStructuralModalOpen, setIsStructuralModalOpen] = useState(false);

    const isStructurePage = page.title === 'page_company_structure';

    const questionnaireChoices = useSelector((state) => state.QuestionnaireReducer.choices);
    const allRepeatableGroupsCompleted = useSelector(
      (state) => state.QuestionnaireReducer.allRepeatableGroupsCompleted
    );
    const selectFormKeyInEdit = useSelector(formKeyInEdit);

    const handleHeight = () => {
      setIsAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight - 20);
    };

    useEffect(() => {
      handleHeight();
      document.addEventListener('scroll', handleHeight);
      return () => document.removeEventListener('scroll', handleHeight);
    }, [page.position]);

    const handleStepForward = (event) => {
      if (isStructurePage && !wasStructuralModalOpened) {
        setIsStructuralModalOpen(true);
        setWasStructuralModalOpened(true);
        return;
      }
      event.preventDefault();
      handleSubmit(
        // on valid case
        async (data) => {
          delete data.undefined;
          const restructuredData = Object.keys(initialValues)
            .map((key) => {
              return data[key] !== undefined && { questionKey: key, answer: data[key] };
              //filter removes null values
            })
            .filter((questionData) => questionData);
          if (page.position === totalPages) {
            setShowSpinner(true);
            dispatch(
              QuestionnaireActions.saveQuestionnaireAnswers(
                questionnaireState.questionnaireId,
                restructuredData
              )
            ).then(async () => {
              try {
                await dispatch(KYCActions.submitApplication(applicationState.applicationId));
                //for clearing local stored answers after application submit
                localStorage.removeItem('answers');
                const company = JSON.parse(sessionStorage.getItem('company'));
                company &&
                  (await dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company)));
              } catch {
                handleNotCompletedStep();
                setShowSpinner(false);
              }
            });
          } else {
            dispatch(
              QuestionnaireActions.saveQuestionnaireAnswers(
                questionnaireState.questionnaireId,
                restructuredData
              )
            ).then((data) => {
              page.position !== totalPages && handleNextStep();
              //for clearing local stored answers after page submit
              localStorage.removeItem('answers');
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            });
          }
        },
        // on invalid case
        (data) => {
          setTouchedOnAll();
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      )();
    };

    const handleStepBack = (event) => {
      event.preventDefault();
      handlePrevStep();
    };

    const handleOnInputChange = useCallback((name, value) => {
      value !== undefined &&
        value !== '' &&
        FillLocalStorage({
          inputName: name,
          inputValue: value
        });
      validationHelpers.setValue(name, value);
    }, []);
    let tempQuestions = [];
    let tempRepeatableGroups = [];

    const onRepeatableGroupChange = useCallback((group, action) => {
      if (action === 'delete') {
        tempRepeatableGroups = tempRepeatableGroups.filter(
          (tempGroup) => tempGroup.id !== group.id
        );
        setRepeatableGroups(tempRepeatableGroups);
        return;
      } else if (action === 'add') {
        tempRepeatableGroups = [...tempRepeatableGroups, group];
        setRepeatableGroups(tempRepeatableGroups);
        return;
      }
    }, []);

    useEffect(() => {
      let allGroupsCompleted = true;
      dispatch(QuestionnaireActions.totalRepeatableGroups(repeatableGroups.length));
      repeatableGroups.forEach((group) => {
        if (group.groupType === 'RepeatableGroup') {
          if (group.entries.length < group.minEntries) allGroupsCompleted = false;
          if (group.entries.length > group.maxEntries) allGroupsCompleted = false;
          if (group.entries.some((entry) => !entry.completed)) allGroupsCompleted = false;
        } else {
          if (!group.completed) allGroupsCompleted = false;
        }
      });
      dispatch(QuestionnaireActions.allRepeatableGroupsCompleted(allGroupsCompleted));
    }, [repeatableGroups]);

    const onQuestionChange = useCallback((question, action) => {
      const schema = new CreateSchema();
      if (action === 'delete') {
        tempQuestions = tempQuestions.filter((tempQuestion) => tempQuestion !== question);
      } else {
        tempQuestions = [...tempQuestions, question];
      }
      const { validationSchema, initialValues } = createValidationSchema(
        tempQuestions,
        'page',
        schema,
        t,
        questionnaireChoices,
        questionnaireState
      );

      setInitialValues(initialValues);
      setValidationSchema(validationSchema);
      dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
    }, []);

    useEffect(() => {
      setUrlQueryParameter(page.title);
    }, [page]);

    useEffect(() => {
      setRepeatableGroupsCompleted(!!allRepeatableGroupsCompleted);
    }, [allRepeatableGroupsCompleted]);

    const handleApplicationConsent = (event) => {
      setApplicationConsent(event);
    };

    const memoizedValidationHelpers = useMemo(() => validationHelpers, [JSON.stringify(errors)]);

    const handleCloseModal = () => {
      setIsStructuralModalOpen(false);
    };
    const handleForward = () => {
      handleCloseModal();
      handleNextStep();
    };

    return showSpinner ? (
      <LoaderWithText />
    ) : (
      <>
        <Container
          small={width < breakpointSizes.phone}
          medium={width < breakpointSizes.smallDesktop}
          isLastPage={page.position === totalPages}
        >
          <StepperWrapper>
            <Stepper
              switchToAlternateViewFrom={breakpointSizes.desktopBig}
              steps={steps.map((step) => {
                return {
                  ...step,
                  label: t(step.label)
                };
              })}
            />
          </StepperWrapper>

          <QuestionnaireWrapper
            small={width < breakpointSizes.phone}
            medium={width < breakpointSizes.smallDesktop}
          >
            <FormStyles small={width < breakpointSizes.phone}>
              <PageHeaderContainer>
                {page.title && <PageHeader>{t(page.title)}</PageHeader>}
                {page.subtitle && <PageSubtitle>{t(page.subtitle)}</PageSubtitle>}
              </PageHeaderContainer>
              {page.sections.map((section) => (
                <RenderSection
                  onQuestionChange={onQuestionChange}
                  onInputChange={handleOnInputChange}
                  onRepeatableGroupChange={onRepeatableGroupChange}
                  key={section.id}
                  section={section}
                  currentPage={page.position}
                  errors={errors}
                  validationHelpers={memoizedValidationHelpers}
                />
              ))}
            </FormStyles>
          </QuestionnaireWrapper>
        </Container>
        <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
          {page.position === totalPages && (
            <ApplicationConsentCheckboxStyles
              name={'applicationConsent'}
              label={t('consent_for_signing_text')}
              id={'applicationConsent'}
              onChange={handleApplicationConsent}
              onInputChange={handleApplicationConsent}
              checked={applicationConsent}
            />
          )}
          <ButtonSection
            firstButtonClick={handleStepBack}
            secondButtonClick={handleStepForward}
            secondButtonPrimary={true}
            firstButtonText={t('previous_page_button')}
            secondButtonText={t('button_continue')}
            secondButtonDisabled={
              (page.position === totalPages && (!applicationConsent || !allPagesCompleted)) ||
              !repeatableGroupsCompleted
            }
          />
        </ButtonSectionWrapperStyle>
        <StructureModal
          onClose={handleCloseModal}
          onForward={handleForward}
          isModalOpen={isStructuralModalOpen}
        />
      </>
    );
  }
);
