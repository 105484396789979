import React from 'react';
import { WelcomeContainerCardPaper } from './PartContent.styles';
import { useSelector } from 'react-redux';

export const PartContent = (props) => {
  const isLoggedIn = useSelector((state) => state.OnboardingFlowReducer.logged);

  return (
    <WelcomeContainerCardPaper isLoggedIn={isLoggedIn}>{props.children}</WelcomeContainerCardPaper>
  );
};
