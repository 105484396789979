export const AppConfigConstants = {
  GET_COUNTRY_RISK_LIST_REQUEST: 'GET_COUNTRY_RISK_LIST_REQUEST',
  GET_COUNTRY_RISK_LIST_SUCCESS: 'GET_COUNTRY_RISK_LIST_SUCCESS',
  GET_COUNTRY_RISK_LIST_ERROR: 'GET_COUNTRY_RISK_LIST_ERROR',

  UPDATE_COUNTRY_RISK_REQUEST: 'UPDATE_COUNTRY_RISK_REQUEST',
  UPDATE_COUNTRY_RISK_SUCCESS: 'UPDATE_COUNTRY_RISK_SUCCESS',
  UPDATE_COUNTRY_RISK_ERROR: 'UPDATE_COUNTRY_RISK_ERROR',

  GET_RISK_LIST_REQUEST: 'GET_RISK_LIST_REQUEST',
  GET_RISK_LIST_SUCCESS: 'GET_RISK_LIST_SUCCESS',
  GET_RISK_LIST_ERROR: 'GET_RISK_LIST_ERROR',

  UPDATE_RISK_ITEM_REQUEST: 'UPDATE_RISK_ITEM_REQUEST',
  UPDATE_RISK_ITEM_SUCCESS: 'UPDATE_RISK_ITEM_SUCCESS',
  UPDATE_RISK_ITEM_ERROR: 'UPDATE_RISK_ITEM_ERROR',

  GET_NACE_BLOCKERS_REQUEST: 'GET_NACE_BLOCKERS_REQUEST',
  GET_NACE_BLOCKERS_SUCCESS: 'GET_NACE_BLOCKERS_SUCCESS',
  GET_NACE_BLOCKERS_ERROR: 'GET_NACE_BLOCKERS_ERROR',

  UPDATE_NACE_BLOCKERS_ITEM_REQUEST: 'UPDATE_NACE_BLOCKERS_ITEM_REQUEST',
  UPDATE_NACE_BLOCKERS_ITEM_SUCCESS: 'UPDATE_NACE_BLOCKERS_ITEM_SUCCESS',
  UPDATE_NACE_BLOCKERS_ITEM_ERROR: 'UPDATE_NACE_BLOCKERS_ITEM_ERROR',

  GET_CALCULATE_ALL_CLIENT_RISKS_REQUEST: 'GET_CALCULATE_ALL_CLIENT_RISKS_REQUEST',
  GET_CALCULATE_ALL_CLIENT_RISKS_SUCCESS: 'GET_CALCULATE_ALL_CLIENT_RISKS_SUCCESS',
  GET_CALCULATE_ALL_CLIENT_RISKS_ERROR: 'GET_CALCULATE_ALL_CLIENT_RISKS_ERROR',

  UPDATE_APPLICATION_SETTINGS_REQUEST: 'UPDATE_APPLICATION_SETTINGS_REQUEST',
  UPDATE_APPLICATION_SETTINGS_SUCCESS: 'UPDATE_APPLICATION_SETTINGS_SUCCESS',
  UPDATE_APPLICATION_SETTINGS_ERROR: 'UPDATE_APPLICATION_SETTINGS_ERROR'
};
