import { FormList } from '@single-platform/components';
import { colors } from '@single-platform/components/dist/design/designVariables';
import styled, { css } from 'styled-components';

const defaultPaper = css`
  background-color: ${() => colors.base.black.shades[5]};
  min-height: ${(props) => props.minHeight || 'auto'};
  border-radius: 1.5rem;
  box-sizing: border-box;
  padding: 1rem;
`;

export const RepeatablePaperStyle = styled.div`
  ${defaultPaper} {
    width: 100%;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 470px) {
      &:last-child {
        margin-bottom: 1.5rem;
      }
    }
  }
`;

export const RepeatableCardStyle = styled.div`
  width: 100%;
  padding: 1rem 0.875rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
`;

export const FormListStyles = styled(FormList)`
  margin-bottom: 0rem;
  flex-direction: column-reverse;

  h6 {
    white-space: unset;
    text-align: left;
  }
`;
