export const allowedOrigins = [
  'https://dev.onboarding.sp.smefinance.eu',
  'https://onboarding.stage.smego.com',
  'https://onboarding.smego.com',
  'https://test.my.sme-go.com',
  'https://my.stage.smego.com',
  'https://my.smego.com',
  'https://dev.sp.smefinance.eu',
  'http://localhost:3000'
];
