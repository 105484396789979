import React, { useEffect, useState } from 'react';
import { RenderGroup } from '../renderGroup/renderGroup';
import { RenderRepeatableGroup } from '../renderRepeatableGroup/renderRepeatableGroup';
import { SectionSubtitle, SectionTitle, SectionWrapper } from './renderSection.style';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TextGroup } from '../../components/textGroup/textGroup';
import { InformationMessage } from '../../components/informationMessage/informationMessage';
import { LinkPopup } from '../../components/linkPopup/linkPopup';

export const RenderSection = ({
  section,
  onInputChange,
  onRepeatableGroupChange,
  validationHelpers,
  initialQuestionValues,
  currentPage,
  onQuestionChange,
  errors
}) => {
  const [newErrors, setNewErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (_.isEqual(errors, newErrors)) {
      return;
    } else {
      setTimeout(() => {
        setNewErrors(errors);
      });
    }
  }, [errors]);

  const renderHandler = (group) => {
    if (group?.summary)
      return (
        <SectionWrapper key={group.id} isRepeatable>
          <RenderRepeatableGroup
            onInputChange={onInputChange}
            key={group.id}
            onQuestionChange={onQuestionChange}
            initialQuestionValues={initialQuestionValues}
            group={group}
            errors={newErrors}
            currentPage={currentPage}
            allGroups={section.groups}
            validationHelpers={validationHelpers}
            onRepeatableGroupChange={onRepeatableGroupChange}
            hasNoDeleteButton={true}
            isInModal={true}
          />
        </SectionWrapper>
      );
    switch (group.groupType) {
      case 'TextGroup':
        return (
          <SectionWrapper key={group.id}>
            <TextGroup group={group} />
          </SectionWrapper>
        );
      case 'RepeatableGroup':
        return (
          <SectionWrapper key={group.id} isRepeatable>
            <RenderRepeatableGroup
              onInputChange={onInputChange}
              key={group.id}
              onQuestionChange={onQuestionChange}
              initialQuestionValues={initialQuestionValues}
              group={group}
              errors={newErrors}
              currentPage={currentPage}
              allGroups={section.groups}
              validationHelpers={validationHelpers}
              onRepeatableGroupChange={onRepeatableGroupChange}
              isInModal={true}
            />
          </SectionWrapper>
        );
      case 'InformationMessage':
        return <InformationMessage key={group.id} group={group} />;
      case 'LinkPopup':
        return <LinkPopup key={group.id} group={group} />;
      default:
        return (
          <SectionWrapper key={group.id}>
            <RenderGroup
              onInputChange={onInputChange}
              onQuestionChange={onQuestionChange}
              key={group.id}
              initialQuestionValues={initialQuestionValues}
              group={group}
              errors={newErrors}
              currentPage={currentPage}
              allGroups={section.groups}
              validationHelpers={validationHelpers}
              onRepeatableGroupChange={onRepeatableGroupChange}
            />
          </SectionWrapper>
        );
    }
  };

  return (
    <>
      {section.title && (
        <SectionTitle hasSubtitle={section.subtitle}>{t(section.title)}</SectionTitle>
      )}
      {section.subtitle && <SectionSubtitle>{t(section.subtitle)}</SectionSubtitle>}
      {section.groups.map((group) => renderHandler(group))}
    </>
  );
};
