import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const printComponent = async (component, action, fileName) => {
  const OPTIONS = {
    jsPDF: {
      orientation: 'p',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: false,
      compress: true,
      precision: 2,
      userUnit: 1.0
    },
    html2canvas: {
      allowTaint: false,
      backgroundColor: '#ffffff',
      canvas: null,
      foreignObjectRendering: false,
      imageTimeout: 15000,
      logging: false,
      onclone: null,
      proxy: null,
      removeContainer: true,
      scale: window.devicePixelRatio,
      useCORS: false
    }
  };

  let totalHeight = component.offsetHeight;
  const pdf = new jsPDF(OPTIONS.jsPDF.orientation, OPTIONS.jsPDF.unit, OPTIONS.jsPDF.format, true);
  const pdfWidth = Math.floor(pdf.internal.pageSize.width) - 20;
  const pdfHeight = Math.floor(pdf.internal.pageSize.height) - 20;
  window.scrollTo(0, 0);
  try {
    const canvas = await html2canvas(component, OPTIONS.html2canvas);
    if (canvas) {
      const widthRatio = pdfWidth / canvas.width;
      const sX = 0;
      const sWidth = Math.floor(canvas.width);
      const sHeight = Math.floor(pdfHeight + (pdfHeight - pdfHeight * widthRatio) / widthRatio);
      const dX = 20;
      const dY = 0;
      const dWidth = sWidth - dX;
      const dHeight = sHeight;
      let pageCnt = 1;
      while (totalHeight > 0) {
        totalHeight -= sHeight;
        let sY = sHeight * (pageCnt - 1);
        const childCanvas = document.createElement('CANVAS');
        childCanvas.setAttribute('width', sWidth);
        childCanvas.setAttribute('height', sHeight);
        const childCanvasCtx = childCanvas.getContext('2d');
        childCanvasCtx.fillStyle = '#FFFFFF';
        childCanvasCtx.fillRect(0, 0, sWidth, sHeight);
        childCanvasCtx.drawImage(canvas, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
        if (pageCnt > 1) {
          pdf.addPage();
        }
        pdf.setPage(pageCnt);
        pdf.addImage(
          childCanvas.toDataURL('image/jpeg'),
          'jpeg',
          0,
          0,
          canvas.width * widthRatio,
          0,
          '',
          'FAST'
        );
        pageCnt++;
      }

      //
    }
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    if (action === 'download') {
      return pdf.save(fileName);
    } else if (action === 'getblob') {
      return pdf.output('blob');
    }
  } catch (exception) {
    console.log(exception); //check if 3rd party error logger is available with Raimundas
  }
};
