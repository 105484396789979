import React, { useEffect, useState } from 'react';
import { Input, Field } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import { HintFieldContainer } from '../hintModal/hintModal.styles';
import HintModal from '../hintModal/hintModal';
import formatThousands from 'format-thousands';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';

export const NumberInput = ({
  question,
  name,
  validationHelpers,
  onChange,
  value,
  onQuestionChange,
  disabled
}) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState(value === undefined ? '' : value);

  const handleChange = (value) => {
    setNewValue(value);
    onChange(value, question.type);
  };

  const handleKeyPress = (event) => {
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      return true;
    }

    if (isNaN(parseInt(event.key)) && event.key !== '.' && event.key !== '-') {
      event.preventDefault();
      return false;
    }
  };

  const handleValue = () => {
    let changedValue = String(newValue);
    let floatingPoint = String(changedValue).split('.')[1];
    if (typeof changedValue === 'string') changedValue = parseInt(changedValue.replace(/,/g, ''));
    if (floatingPoint) changedValue += `.${floatingPoint}`;
    setNewValue(formatThousands(changedValue, ','));
  };

  useEffect(() => {
    onQuestionChange(question, 'add');
    handleValue();
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [value]);

  return (
    <HintFieldContainer>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={Input}
        name={name}
        id={name}
        disabled={question.disabled || disabled}
        onChange={handleChange}
        validateOnBlur
        onBlur={handleValue}
        value={newValue}
        onKeyDown={handleKeyPress}
        placeholder={
          question.required ? t(question.title) : t(question.title) + t('question_optional_label')
        }
        required={question.required}
        validationHelpers={validationHelpers}
        key={question.id}
      />
      <HintModal hint={question.hint ? question.hint : ''} />
    </HintFieldContainer>
  );
};
