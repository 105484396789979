import { createSelector } from 'reselect';

export const selectAuthState = createSelector(
  (state) => state,
  (state) => state.AuthReducers
);

export const selectGetAuthorizedCompanies = createSelector(
  selectAuthState,
  (state) => state?.GetAuthorizedCompanies
);

export const selectGetAuthorizedCompaniesAuthorizations = createSelector(
  selectGetAuthorizedCompanies,
  (state) => state?.authorizations
);

export const selectIsLoadingSelectId = createSelector(
  selectAuthState,
  (state) => state?.isLoadingSelectId
);

export const selectLoginRequestInfo = createSelector(
  selectAuthState,
  (state) => state?.startLogin || {}
);

export const selectIsStartLoginloading = createSelector(
  selectAuthState,
  (state) => state?.sendStartLogin
);

export const selectIsRefreshTokenLoading = createSelector(
  selectAuthState,
  (state) => state?.isRefreshTokenLoading || false
);
