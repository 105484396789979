import styled from 'styled-components';
import { CompanyNameCodeWrapper } from '../../../CompanyModal/CompanyDisplayContainer/CompanyContainer/CompanyContainer.style';
import { colors } from '@single-platform/components/dist/design/designVariables';

export const CompanyInitialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: ${colors.base.primary.shades[100]};
  background-color: ${colors.base.black.shades[5]};
  border-radius: 9px;
`;

export const CompanyInfoCardContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 1.5rem;
`;

export const CompanyNameCodeWrapperStyles = styled(CompanyNameCodeWrapper)`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
