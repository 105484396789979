import { Paper } from '@single-platform/components';
import styled from 'styled-components';

export const WelcomeContainerCardPaper = styled(Paper)`
  background: #fff;
  max-height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  flex-grow: 0;
  margin: 0 auto;
  max-width: 1000px;
  min-width: 60vw;
  min-height: 300px;
  margin-top: 5rem;

  @media (max-width: 1150px) {
    margin: 5rem 10rem;
    img {
      width: 20rem;
    }
  }

  @media (max-width: 950px) {
    img {
      display: none;
    }
    margin: 5rem;
  }

  @media (max-width: 768px) {
    margin: 5rem 1rem;
    padding: 1rem;
  }
`;
