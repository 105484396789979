import 'styled-components';
import { colors } from '@single-platform/components/dist/design/designVariables';

//These are temporary, until Single platform team figures out a way to export them properly

export const bankColors = {
  ...colors,

  base: {
    ...colors.base,
    primary: {
      shades: {
        5: '#F8F6FD',
        10: '#F1EEFB',
        20: '#5A43AB',
        30: '#4F3B96',
        50: '#B8AAEB',
        100: '#7154D6'
      },
      tints: {
        5: '#F4F7FF',
        10: '#EAEFFE',
        20: '#E3DDF7',
        30: '#BFD1FC',
        50: '#95B2FA',
        70: 'rgba(107, 146, 248, 1)',
        80: 'rgba(85, 131, 247, 1)'
      }
    }
  },
  primary: {
    ...colors.primary,
    buttonBackground: '#7154D6',
    hoverButtonBackground: '#5A43AB',
    linkText: '#7154D6',
    infoTextColor: '#7154D6',
    inputBorder: '#7154D6'
  }
};

export const layers = {
  sessionTimeoutModal: 99999999999,
  dropdown: 9999999999,
  overlay: 999999999,
  modal: 999999999
};

export const spacings = {
  gap: '1.5rem',
  gap2x: '3rem'
};

export const shadows = {
  medium: '0px 0.625rem 2.5rem rgb(0 0 0 / 10%)'
};

export const status = {
  gray: '#E2E2E2',
  red: '#FF706466',
  blue: '#BFD0FC',
  lightBlue: '#E9EFFE',
  white: '#FFFFFF',
  green: '#A4E69466',
  yellow: '#F2D75A66'
};

export const responsiveBreakPoints = {
  maxPhone: '(max-width: 768px)',
  maxTablet: '(max-width: 1024px)',
  maxDesktop: '(max-width: 1200px)',
  maxDesktopXL: '(max-width: 1399px)'
};

export const pageContentLayouts = {
  maxWidth: '87.5rem',
  minWidth: '5rem'
};

export const pageContentLayoutsType = typeof status;

export const fontSizes = {
  h1: '1.375rem', // 22px
  h2: '1.25rem', // 20px
  h3: '1.125rem', // 18px
  h4: '1rem', // 16px
  h5: '0.875rem', // 14px
  h6: '0.75rem', // 12px
  link: '1rem'
};

export const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800
};

const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

export default {
  theme: 'light',
  colors: isBank ? bankColors : colors,
  media: responsiveBreakPoints,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  status: status,
  shadows,
  pageContentLayouts,
  spacings,
  layers
};
