import styled from 'styled-components';

export const BoldedTextStyles = styled.b`
  font-size: 1rem;
  font-weight: 500;
`;

export const GroupedItemsStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
