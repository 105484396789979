import _ from 'lodash';

export const shouldRenderConditionalGroup = (value, condition) => {
  if (condition.conditionType === 'Equals') {
    if (value === condition.value) return true;
    if (value?.toString() === condition.value) return true;
  }
  if (condition.conditionType === 'Contains') {
    if (value.includes(condition.value)) {
      return true;
    }
  }
  if (condition.conditionType === 'NotEqual') {
    if (value !== condition.value) return true;
    if (value?.toString() !== condition.value) return true;
  }

  return false;
};

export const extractQuestions = (group) => {
  return group.reduce((allQuestions, currentGroup) => {
    if (currentGroup.groupType !== 'RepeatableGroup') {
      allQuestions = [...allQuestions, ...currentGroup.questions];
      return allQuestions;
    } else {
      const newQuestions = currentGroup.entries.reduce((innerQuestions, innerGroup) => {
        innerQuestions = [...innerQuestions, ...innerGroup.questions];
        return innerQuestions;
      }, []);
      allQuestions = [...allQuestions, ...newQuestions];
      return allQuestions;
    }
  }, []);
};

export const addConditionalQuestionsForScheme = (questions, conditionalGroup) => {
  return _.union(questions, extractQuestions(conditionalGroup));
};

export const deleteConditionalQuestionsForScheme = (questions, conditionalGroup) => {
  return questions.filter((question) => !extractQuestions(conditionalGroup).includes(question));
};
