import { colors } from '@single-platform/components/dist/design/designVariables';
import styled, { css } from 'styled-components';

export const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    border: `1px solid ${colors.primary.inputBorder}`,
    marginTop: '4px',
    padding: '5px 16px '
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '24px',
    height: '48px',
    padding: '0px 8px',
    borderRadius: '8px',
    border: `1px solid ${
      state.isFocused ? colors.base.primary.shades[100] : colors.base.black.shades[10]
    }`,
    outline: 'none',
    boxShadow: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    fontSize: '16px',
    lineHeight: '24px',
    margin: 0
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    scale: '70%',
    color: '#999',
    transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? '#f1f1f1' : '#fff',
    borderRadius: '8px',
    color: 'black',
    marginBottom: '4px'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '24px',
    color: `${colors.primary.inputPlaceholderColor}`
  })
};

export const customInputStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    fontSize: '16px',
    lineHeight: '24px',
    margin: 0,
    marginTop: '11px'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    marginTop: '14px',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'black'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '8px'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '14px',
    textAlign: 'left'
  })
};

export const focusedLabel = css`
  top: 2px;
  left: 16px;
  font-size: 12px;
  line-height: 20px;
`;

export const SelectContainer = styled.div`
  position: relative;
  height: 48px;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.1s ease-out;
  &:focus-within {
    label {
      ${focusedLabel};
      color: ${(props) =>
        props.hasError
          ? props.theme.colors.base.red.shades[120]
          : props.theme.colors.base.primary.shades[100]};
    }
  }

  [class*='-control']:has(+ [class*='-menu']) {
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.base.red.shades[120] : theme.colors.base.primary.shades[100]};
  }

  [class*='-menu'] {
    border: none;
  }

  [class*='-IndicatorsContainer'] {
    display: none;
  }
`;

const regularLabel = css`
  top: 12px;
  left: 16px;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledLabel = styled.label`
  pointer-events: none;
  position: absolute;
  color: ${(props) => props.theme.colors.primary.inputPlaceholderColor};
  transition: all 0.1s ease-out;
  top: ${(props) => (props.hasValue ? '2px' : '12px')};
  left: 16px;
  font-size: ${(props) => (props.hasValue ? '12px' : '14px')};
  line-height: ${(props) => (props.hasValue ? '20px' : '24px')};
`;
