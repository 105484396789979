import styled from 'styled-components';

export const HintContainerStyle = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: ${({ isCheckbox }) => (isCheckbox ? '1rem' : '50%')};
  left: 95%;
  fill: #666666;
  z-index: 100;
  padding: 1rem;
`;

export const HintModalStyle = styled.div`
  position: absolute;
  right: 1.5rem;
  bottom: ${({ isCheckbox }) => !isCheckbox && '2.5rem'};
  top: ${({ isCheckbox }) => isCheckbox && '-5.5rem'};
  z-index: 999999;
  background-color: #fff;
  padding: 16px;
  border-radius: 10px 10px 0px 10px;
  color: #000;
  box-shadow: 0px 0.625rem 2.5rem rgb(0 0 0 / 10%);
  font-size: 12px;
  line-height: 20px;
`;

export const HintFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const MouseOverDiv = styled.div`
  padding: 0px;
`;
