import React from 'react';
import { CompanyModal } from '..';

import { allLoginStatus } from '../../Config';
import { UnauthorizedCompanyModal } from './UnauthorizedCompanyModal';

export const SigningModal = ({ type, onCancel }) => {
  const modalTypes = {
    [allLoginStatus.noAuthorizeCompany]: <UnauthorizedCompanyModal onCancel={onCancel} />,
    [allLoginStatus.selectCompany]: <CompanyModal onCancel={onCancel} isOpen={true} />
  };

  if (!type) return null;

  return modalTypes[type];
};
