import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './sme-locales/locales/en-GB.json';
import lt from './sme-locales/locales/lt-LT.json';

const resources = {
  lt: { translation: lt },
  en: { translation: en }
};

i18next.use(initReactI18next).init({
  lng: undefined,
  keySeparator: false,
  interpolation: { escapeValue: false },
  detection: {
    lookupQuerystring: 'language',
    lookupLocalStorage: 'language',
    order: ['queryParameter', 'localStorage', 'navigator'],
    caches: []
  },
  fallbackLng: 'lt-LT',
  resources
});
