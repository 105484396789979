import React, { useEffect, useState } from 'react';
import { Field, PhoneField } from '@single-platform/components';
import { MobileInputWrapper } from './mobileInput.style';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';
import { useTranslation } from 'react-i18next';

const MobileInput = ({
  value,
  onChange,
  required,
  placeholder,
  name,
  onQuestionChange,
  question,
  onBlurHandler,
  validationHelpers,
  disabled
}) => {
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  const handleChange = (event) => {
    setInputValue(event);
    onChange?.(event);
  };

  const extractNumbers = (phoneNumber, dialCodeLength) => {
    const startIndex = phoneNumber.indexOf('+') + 1;
    const endIndex = startIndex + dialCodeLength;
    return phoneNumber.substring(startIndex, endIndex);
  };

  const handleOnBlur = (event, data) => {
    const { value } = event.target;
    onBlurHandler?.(isPossiblePhoneNumber(value, data.countryCode.toUpperCase()));
    if (!isPossiblePhoneNumber(value, data.countryCode.toUpperCase())) {
      if (extractNumbers(value, data.dialCode.length) !== data.dialCode)
        setInputValue(`+${data.dialCode}`);
    }
  };

  useEffect(() => {
    setInputValue(value);
    onQuestionChange?.(question, 'add');
    return () => {
      onQuestionChange?.(question, 'delete');
    };
  }, [value]);

  return (
    <MobileInputWrapper>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={PhoneField}
        validateOnBlur
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
        name={name}
        onBlur={handleOnBlur}
        disabled={disabled}
        validationHelpers={validationHelpers}
      />
    </MobileInputWrapper>
  );
};

export default MobileInput;
