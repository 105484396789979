import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { OtpCodeDialog } from '@single-platform/components';
import { history, routerPath } from '../../../../Config';
import { Notification, NOTIFICATION_TYPES } from '../../../../Components';
import { AuthActions } from '../../../../Redux';

export const OtpForm = ({ otpRequest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [refreshAllowed, setRefreshAllowed] = useState(true);

  const onNextClickHandle = async (otp) => {
    try {
      const opt = otp;
      const response = await dispatch(AuthActions.otpAuthorization(opt));
      if (response.payload.state === 'ok') {
        const token = {
          refreshToken: response.payload.refreshToken,
          accessToken: response.payload.token,
          expiresIn: response.payload.expiresIn
        };
        sessionStorage.removeItem('loginToken');
        sessionStorage.setItem('token', JSON.stringify(token));
        otpRequest();
      }
      if (response.payload.state === 'wrong_otp_code') {
        throw new Error(t('wrong_OTP'));
      }
      if (response.payload.state === 'too_many_retries') {
        setRefreshAllowed(false);
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('otp_too_many_attemps_error')
        });
        setTimeout(() => {
          history.push(routerPath.index);
        }, 5000);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const resendOTP = () => {
    if (refreshAllowed) {
      const token = sessionStorage.getItem('loginToken');
      if (token) dispatch(AuthActions.authorizationStatus(token));
      setRefreshAllowed(false);
    }
  };

  return (
    <OtpCodeDialog
      leftAlign={true}
      wrongOtpWarningLabel={t('wrong_OTP')}
      wrongOtpWarningExceededRetriesLabel={t('otp_too_many_attemps_error')}
      otpHeaderLabel={t('enter_verification_code_otp')}
      resendOtpLinkLabel={t('time_remaining_otp')}
      resendOneTimePasswordLable={t('resend_OTP')}
      onSubmit={onNextClickHandle}
      onRefresh={resendOTP}
      onSuccess={otpRequest}
    />
  );
};
