export const APP_STATES = {
  WAITING_FOR_OFFICER_APPROVAL: 'WaitingForAmlOfficerApproval',
  ONBOARDING_COMPLETE: 'OnboardingComplete',
  CHECKING_BENEFICIARY_VERIFICATION_COMPLETE: 'CheckingBeneficiaryVerificationComplete',
  WAITING_SANCTION_LIST_APPROVAL: 'WaitingForSanctionListApproval',
  WAITING_PRODUCT_AGREEMENT_SIGNATURE: 'WaitingForProductAgreementsSignature',
  WAITING_FOR_OFFICER_REVIEW: 'WaitingForAmlOfficerKycReview',
  WAITING_FOR_OFFICER_DOC_REVIEW: 'WaitingForAmlOfficerDocumentReview',
  WAITING_FOR_APP_DOCS: 'WaitingForApplicationDocuments',
  WAITING_FOR_ADDITIONAL_DOCS: 'WaitingForAdditionalDocuments',
  WAITING_FOR_BENEFICIARY_DOCS: 'WaitingForBeneficiaryDocuments',
  PENDING: 'Pending',
  APPLICATION_SIGNED: 'ApplicationSigned',
  START_APPLICATIONS_SIGNING: 'StartApplicationSigning',
  AGREEMENTS_SIGNED: 'AgreementsSigned',
  UPLOAD_POWER_ATTORNEY: 'UploadPowerOfAttorney',
  SHOW_APP_DOCUMENTS: 'ShowApplicationDocument',
  AGREEMENT_SIGNING_STARTED: 'AgreementSigningStarted',
  APP_SUBMITTED: 'ApplicationSubmitted',
  WAITING_FOR_POWER_ATTORNEY: 'WaitingForPowerOfAttorney',
  USER_FILLING_APPLICATION: 'UserFillingApplication',
  RENEW_COMPLETE: 'RenewedKycCompleted',
  CHOOSING_ONBOARDING_FLOW: 'UserChoosingOnboardingFlow'
};

export const APP_ACTIONS = {
  REQUEST_ADDITIONAL_DOCS: 'RequestAdditionalDocuments',
  APPROVE_BENEFICIERES: 'ApproveBeneficiaries',
  REJECT_APP: 'RejectApplication',
  ADD_RISK: 'AddRisk',
  APPROVE_RISK: 'ApproveRisks',
  REJECT_DUE_SANCTIONS: 'rejectDueSanctions',
  ALL_SANCTIONS_REVIEWED: 'AllSanctionsReviewed',
  UPLOAD_SIGNED_APP: 'UploadSignedApplication',
  CONFIRM_UPLOAD_AGREEMENT: 'ConfirmingUploadedAgreement',
  REJECT_UPLOAD_AGREEMENT: 'RejectUploadedAgreement'
};

export const COUNTRY_FLAGS = {
  NONE: 'None',
  EUROPEAN_UNION: 'EuropeanUnion',
  EURO_ZONE: 'EuroZone',
  EUROPE_ECONOMIC_AREA: 'EuropeEconomicArea',
  HIGH_RISK: 'HighRisk',
  OFF_SHORE: 'Offshore',
  SANCTIONED: 'Sanctioned'
};

export const RISK_TYPES = {
  OWNERSHIP_STRUCTURE: 'OwnershipStructureRisk',
  CUSTOMER_FACTORS: 'CustomerRiskFactors',
  HIGH_BUSSINESS_ACTIVITY_STOPPER: 'HighRiskBusinessActivityStopper',
  DELIVERY_CHANNEL: 'DeliveryChannelRisk',
  OTHER: 'OtherRisk'
};

export const CONFIGS = {
  NACE_BLOCKERS: 'naceBlockers',
  COUNTRIES: 'countries',
  RISKS: 'risks'
};
