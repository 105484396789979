import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadUser } from 'redux-oidc';

import { userManager } from '../../Utilities/userManager';
import { rootReducer } from './rootReducer';
import { serverErrorsMiddleware } from '../Middleware';
import { successNotifier } from '../Middleware/Success/Success';
import { mobileApp } from '../Middleware/MobileApp/mobileApp';

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware, serverErrorsMiddleware, successNotifier, mobileApp)
  )
);

loadUser(store, userManager);

export { store };
