import React, { useEffect, useState, memo } from 'react';
import { RenderQuestion } from './renderQuestion/renderQuestion';
import {
  GroupItems,
  GroupSubtitle,
  GroupTitle,
  GroupTitleContainer,
  GroupsContainer
} from './renderGroup.style';
import _ from 'lodash';
import {
  CheckBox,
  MobileInput,
  Multiselect,
  SelectDate,
  CheckboxGroup,
  RadioButtonGroup
} from '../../components/index';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../components/numberInput';
import { useSelector } from 'react-redux';
import { TextArea } from '../../components/textarea/textarea';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';
import { logError } from '../../../../../Utilities';

export const RenderGroup = memo(
  ({
    group,
    validationHelpers,
    onInputChange,
    initialQuestionValues,
    onQuestionChange,
    errors,
    isInModal,
    onRepeatableGroupChange
  }) => {
    const [hasInitValuesBeenSet, setHasInitValuesBeenSet] = useState(false);
    const [localAnswers, setLocalAnswers] = useState([]);
    const createOptions = (choice) =>
      !choice.label ? { label: choice.title, value: choice.value } : choice;
    const { t } = useTranslation();
    const questionnaireChoices = useSelector((state) => state.QuestionnaireReducer.choices);

    const onChangeHandler = (name) => (value, type) => {
      let changedValue = value;
      if (!type) {
        changedValue = '';
      }
      if (_.isString(value) || _.isBoolean(value)) {
        changedValue = value;
      }
      if (_.isArray(value)) {
        changedValue = value.join('|');
      }
      onInputChange(name, changedValue);
    };

    const renderQuestionsByType = (question) => {
      const condition = question.conditionalGroups;
      let prefilledLocalValue;
      if (localAnswers)
        localAnswers.forEach((answer) => {
          if (answer.inputName === question.questionKey) {
            prefilledLocalValue = answer.inputValue;
            return;
          }
        });
      switch (question.type) {
        case 'Phone':
          return (
            <MobileInput
              onChange={onChangeHandler(question.questionKey)}
              name={question.questionKey}
              key={question.id}
              placeholder={t(question.title)}
              value={prefilledLocalValue ?? question.value ?? ''}
              required={question.required}
              onQuestionChange={onQuestionChange}
              question={question}
              validationHelpers={validationHelpers}
            />
          );
        case 'Dropdown':
          return (
            <Multiselect
              type="dropdown"
              question={question}
              value={prefilledLocalValue ?? question.value ?? ''}
              onQuestionChange={onQuestionChange}
              name={question.questionKey}
              onChange={onChangeHandler(question.questionKey)}
              key={question.id}
              options={questionnaireChoices?.[question.choiceKey]?.map(createOptions)}
              placeholder={question.title}
              validateOnBlur
              validationHelpers={validationHelpers}
              required={question.required}
              errors={errors}
              onInputChange={onInputChange}
            />
          );
        case 'Multiselect':
          return (
            <Multiselect
              type="multi"
              value={prefilledLocalValue ?? question.value ?? ''}
              name={question.questionKey}
              onQuestionChange={onQuestionChange}
              question={question}
              onChange={onChangeHandler(question.questionKey)}
              key={question.id}
              options={questionnaireChoices?.[question.choiceKey]?.map(createOptions)}
              placeholder={question.title}
              validateOnBlur
              validationHelpers={validationHelpers}
              required={question.required}
              errors={errors}
              onInputChange={onInputChange}
            />
          );
        case 'DateFromToday':
        case 'DateUntilToday':
          return (
            <SelectDate
              key={question.questionKey}
              question={question}
              onQuestionChange={onQuestionChange}
              required={question.required}
              value={prefilledLocalValue ?? question.value ?? ''}
              placeholder={
                question.required
                  ? t(question.title)
                  : t(question.title) + t('question_optional_label')
              }
              onChange={onChangeHandler(question.questionKey)}
              validationHelpers={validationHelpers}
            />
          );
        case 'RadioButton':
          return (
            <RadioButtonGroup
              key={question.id}
              conditions={condition}
              onQuestionChange={onQuestionChange}
              question={question}
              errors={errors}
              name={question.questionKey}
              value={prefilledLocalValue || question.value}
              options={questionnaireChoices?.[question.choiceKey]?.map(createOptions)}
              onChange={onChangeHandler(question.questionKey)}
              required={question.required}
              onInputChange={onInputChange}
              initialQuestionValues={initialQuestionValues}
              validationHelpers={validationHelpers}
              onRepeatableGroupChange={onRepeatableGroupChange}
              isInModal={isInModal}
            />
          );
        case 'RepeatingInformationCheckbox':
        case 'Checkbox':
          return (
            <CheckBox
              name={question.questionKey}
              key={question.id}
              label={
                question.required
                  ? t(question.title)
                  : t(question.title) + t('question_optional_label')
              }
              id={question.questionKey}
              onChange={onChangeHandler(question.questionKey)}
              conditions={condition}
              onQuestionChange={onQuestionChange}
              question={question}
              errors={errors}
              required={question.required}
              onInputChange={onInputChange}
              initialQuestionValues={initialQuestionValues}
              value={prefilledLocalValue ? prefilledLocalValue : question.value || false}
              validateOnBlur
              validationHelpers={validationHelpers}
              onRepeatableGroupChange={onRepeatableGroupChange}
              isInModal={isInModal}
            />
          );
        case 'CheckboxGroup':
          return (
            <CheckboxGroup
              key={question.id}
              question={question}
              name={question.questionKey}
              conditions={condition}
              onQuestionChange={onQuestionChange}
              value={prefilledLocalValue ?? question.value ?? ''}
              options={questionnaireChoices?.[question.choiceKey]?.map(createOptions)}
              onChange={onChangeHandler(question.questionKey)}
              initialQuestionValues={initialQuestionValues}
              onInputChange={onInputChange}
              errors={errors}
              required={question.required}
              isInModal={isInModal}
              validateOnBlur
              validationHelpers={validationHelpers}
            />
          );
        case 'Number':
          return (
            <NumberInput
              question={question}
              key={question.id}
              name={question.questionKey}
              validationHelpers={validationHelpers}
              onChange={onChangeHandler(question.questionKey)}
              value={prefilledLocalValue !== undefined ? prefilledLocalValue : question.value}
              onQuestionChange={onQuestionChange}
            />
          );
        case 'TextArea':
          return (
            <TextArea
              question={question}
              key={question.id}
              name={question.questionKey}
              validationHelpers={validationHelpers}
              onChange={onChangeHandler(question.questionKey)}
              value={prefilledLocalValue !== undefined ? prefilledLocalValue : question.value}
              onQuestionChange={onQuestionChange}
            />
          );
        default:
          return (
            <RenderQuestion
              name={question.questionKey}
              key={question.id}
              conditions={condition}
              onQuestionChange={onQuestionChange}
              question={question}
              validationHelpers={validationHelpers}
              onChange={onChangeHandler(question.questionKey)}
              value={prefilledLocalValue || question.value}
            />
          );
      }
    };

    useEffect(() => {
      setHasInitValuesBeenSet(true);
      try {
        const localAnswer = storageHandler.getLocalStorageAsJSON('answers');
        setLocalAnswers(localAnswer);
      } catch (e) {
        logError(e);
        storageHandler.removeLocalStorage('answers');
      }
    }, [group, errors]);

    return (
      hasInitValuesBeenSet && (
        <>
          {(group.title || group.subtitle) && (
            <GroupTitleContainer>
              {group.title && (
                <GroupTitle hasSubtitle={group.subtitle}>{t(group.title)}</GroupTitle>
              )}
              {group.subtitle && <GroupSubtitle>{t(group.subtitle)}</GroupSubtitle>}
            </GroupTitleContainer>
          )}
          <GroupsContainer>
            {group?.questions?.map((question) => (
              <GroupItems
                key={question.questionKey}
                isGroup={question.type === 'CheckboxGroup' || question.type === 'RadioButton'}
                repeatableCheckbox={question.type === 'RepeatingInformationCheckbox'}
              >
                {renderQuestionsByType(question)}
              </GroupItems>
            ))}
          </GroupsContainer>
        </>
      )
    );
  }
);
