import { FormStepConstants } from './constants';
import { requestJSONSca } from '../../Config';
import { checkJsonProp } from '../../../Utilities';

export const getToken = (user) => {
  let access_token = checkJsonProp(user, 'token');
  return 'Bearer ' + access_token;
};

export const FormStepsReducer = function (state = {}, action) {
  switch (action.type) {
    case FormStepConstants.GET_SEND_FORM_RQUEST:
      requestJSONSca.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: true,
        errors: null
      };
    case FormStepConstants.GET_SEND_FORM_SUCCESS:
      requestJSONSca.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: true,
        errors: null,
        data: action.payload
      };
    case FormStepConstants.GET_SEND_FORM_ERROR:
      return {
        ...state,
        send: true,
        errors: action.error
      };
    case FormStepConstants.POST_SEND_FORM_REQUEST:
      requestJSONSca.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: true,
        errors: null
      };
    case FormStepConstants.POST_SEND_FORM_SUCCESS:
      requestJSONSca.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: true,
        errors: null,
        data: action.payload
      };
    case FormStepConstants.POST_SEND_FORM_ERROR:
      return {
        ...state,
        send: true,
        errors: action.error
      };
    default:
      return state;
  }
};
