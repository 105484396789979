import React, { useEffect, useState } from 'react';
import { UserIdentification } from '../../UserIdentification';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../../../Redux';
import { useTranslation } from 'react-i18next';
import { logError } from '../../../../Utilities';

const RepresentativeChangeInitialStepper = [
  {
    title: 'representative_business_profile_verify_identity',
    completed: false,
    icon: 'RepresentativePerson',
    buttonTitle: 'representative_business_profile_verify_identity_button',
    buttonLink: '',
    isButtonActive: false,
    isActive: true
  },
  {
    title: 'representative_business_profile_update_profile',
    completed: false,
    icon: 'RepresentativeCompany',
    buttonTitle: 'representative_business_profile_update_profile_button',
    buttonLink: 'redirect_login',
    isButtonActive: false,
    isActive: true
  }
];

const RepresentativeChangeTitle = {
  header: 'representative_business_profile_header',
  subheader: 'representative_business_profile_subheader'
};

export const RepresentativeChange = () => {
  const [error, setError] = useState(false);
  const [appId, setAppId] = useState('');
  const [steps, setSteps] = useState(RepresentativeChangeInitialStepper);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const updateSteps = (data) => {
    setSteps((currentSteps) => {
      const updatedSteps = [...currentSteps];

      if (!data) {
        return RepresentativeChangeInitialStepper;
      }

      if (data.ondatoRedirect) {
        updatedSteps[0] = {
          ...updatedSteps[0],
          buttonLink: data.ondatoRedirect
        };
        return updatedSteps;
      }

      if (data.ondatoStatus === 'NotFound' && data.applicationStatus === 'NotFound') {
        updatedSteps[0] = {
          ...updatedSteps[0],
          isButtonActive: true
        };
        return updatedSteps;
      }

      if (data.ondatoStatus === 'Completed' && data.applicationStatus === 'InProgress') {
        updatedSteps[0] = {
          ...updatedSteps[0],
          completed: true,
          isButtonActive: false,
          isActive: true
        };
        updatedSteps[1] = { ...updatedSteps[1], isButtonActive: true, isActive: true };
        return updatedSteps;
      }

      if (data.ondatoStatus === 'Completed' && data.applicationStatus === 'Completed') {
        updatedSteps[0] = {
          ...updatedSteps[0],
          completed: true,
          isActive: true,
          isButtonActive: false
        };
        updatedSteps[1] = {
          ...updatedSteps[1],
          completed: true,
          isActive: true,
          isButtonActive: false
        };
        return updatedSteps;
      }
      return currentSteps;
    });
  };

  const statusCallback = async () => {
    try {
      const { payload } = await dispatch(
        AuthActions.getRepresentativeInformation(appId, i18n.language)
      );
      updateSteps(payload);
    } catch (e) {
      updateSteps();
      setError(true);
    }
  };

  const verificationCallback = async () => {
    try {
      const { payload } = await dispatch(
        AuthActions.startRepresentativeChangeVerification(appId, i18n.language)
      );
      updateSteps(payload);
    } catch (e) {
      logError(e);
    }
  };

  useEffect(() => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const pathname = window.location.pathname.split('/');
    const getInfo = async () => {
      const appId = pathname[pathname.length - 1];
      if (!regex.test(appId)) {
        setError(true);
        return;
      }
      setAppId(appId);
    };
    getInfo();
  }, []);

  return (
    <UserIdentification
      actionInfo={{
        appId,
        userIdentificationAction: 'RepresentativeChange',
        error
      }}
      steps={steps}
      statusCallback={statusCallback}
      verificationCallback={verificationCallback}
      title={RepresentativeChangeTitle}
    />
  );
};
