import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 17rem;
  }
`;

export const TextAndButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HomePageHeaderStyles = styled.div`
  font-size: 35px;
  margin-bottom: 50px;
  color: black;
  flex: 1;
`;

export const HomePageClientTextStyles = styled.div`
  font-size: 18px;
  font-weight: 200px;
  color: black;
  margin-bottom: 25px;
  flex: 1;
`;

export const HomePageInformationContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  flex: 1;
  align-items: ${(props) => props.row && 'center'};
  max-width: 1000px;
  img {
    width: 20rem;
    height: 20rem;
  }
`;

export const ScalableButton = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    height: auto;
  }
`;
