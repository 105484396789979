import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EncouragementBannerContainer,
  EncouragementBannerEmoji,
  EncouragementBannerPrimaryText,
  EncouragementBannerSecondaryText,
  EncouragementBannerText
} from './EncouragementBanner.style';

export const EncouragementBanner = () => {
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  return (
    <EncouragementBannerContainer>
      <EncouragementBannerEmoji>🤩</EncouragementBannerEmoji>
      <EncouragementBannerText>
        <EncouragementBannerPrimaryText>
          {t('encouragement_banner_primary_text')}
        </EncouragementBannerPrimaryText>
        <EncouragementBannerSecondaryText>
          {t('encouragement_banner_secondary_text', {
            organisation: isBank ? 'Finance' : 'Bank'
          })}
        </EncouragementBannerSecondaryText>
      </EncouragementBannerText>
    </EncouragementBannerContainer>
  );
};
