import { Notification, NOTIFICATION_TYPES } from '../../../Components';

export const successNotifier = (store) => (next) => (action) => {
  let result = next(action);
  if (action?.notify?.showSuccess && action?.notify?.message) {
    Notification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: action.notify.message
    });
  }
  return result;
};
