import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { userManager } from './Utilities';
import { store } from './Redux';
import { App } from './App';
import * as Sentry from '@sentry/react';
import DefaultTheme from './Containers/ApplicationKYC/LegalEntityQuestionnaire/styled-theme-styles/styled-components.d';
import { ThemeProvider } from 'styled-components';
import { BrowserTracing } from '@sentry/tracing';

import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './PageParts/PartContent/index.less';
import './PageParts/PartTop/index.less';
import './globalStyles.less';
import { useLocale } from './Utilities/useLocale';
import { storageHandler } from './Containers/SSOLoginContainer/storageHandler';

if (process.env.REACT_APP_CLIENT_ID === 'onboarding.prod' && process.env.REACT_APP_SENTRY_ID) {
  const modifiedToken = storageHandler.getSessionStorage('token');
  Sentry.getCurrentScope().setExtras({
    shared: 'current',
    current: {
      token: { ...modifiedToken, accessToken: !!modifiedToken?.accessToken },
      origin: storageHandler.getSessionStorage('origin'),
      message: storageHandler.getSessionStorage('message')
    }
  });
  Sentry.captureMessage('Something went wrong');
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0
  });
}

const Root = () => {
  const locale = useLocale();

  return (
    <OidcProvider store={store} userManager={userManager}>
      <Provider store={store}>
        <ThemeProvider theme={DefaultTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </OidcProvider>
  );
};

render(<Root />, document.querySelector('#root'));
