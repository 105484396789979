import styled, { css } from 'styled-components';
import { ButtonSectionStyle } from '../../components/buttonSection/buttonSection.styles';
import { Checkbox } from '@single-platform/components';
import { colors } from '@single-platform/components/dist/design/designVariables';

const defaultPaper = css`
  background-color: ${() => '#fff'};
  min-height: ${(props) => props.minHeight || 'auto'};
  border-radius: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;

  @media (min-width: 768px) {
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.maxHeight};
  }
`;

export const PaperStyle = styled.div`
  ${defaultPaper} {
    width: 100%;
    &:not(:first-child) {
      margin-bottom: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    padding: ${({ isRepeatable }) => (isRepeatable ? '0' : '1rem')};
  }
`;

export const Label = styled.div`
  font-size: 2.2rem;
  line-height: 2.4rem;
  margin-top: 1.6rem;
  margin-bottom: 5.6rem;
`;

export const StepperWrapper = styled.div`
  position: fixed;
  left: unset;
  display: flex;
  justify-content: center;
  max-width: 540px;
  z-index: 1001;
  width: 100%;
  top: 4.2rem;

  div > button > div {
    height: 4px;
    width: 8px;
    padding-left: 0.25rem;
  }
  div:not(:first-child) {
    height: 4px;
    padding-left: 0.5rem;
  }

  @media (max-width: 768px) {
    padding-right: 2rem;
  }
`;

export const QuestionnaireWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.isRepeatable ? 'wrap' : 'no-wrap')};
  flex-direction: column;
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0 auto;
    margin-bottom: ${({ isLastPage }) => isLastPage && '5rem'};
  }
  @media screen and (max-width: 280px) {
    margin-bottom: ${({ isLastPage }) => isLastPage && '7rem'};
  }
`;

export const FormStyles = styled.form`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PageHeader = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  position: initial;
  margin-bottom: 0;
`;

export const PageSubtitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${() => colors.base.black.shades[60]};
  margin-bottom: 0;
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:only-child) {
    gap: 0.5rem;
  }
`;

export const ButtonSectionWrapperStyle = styled(ButtonSectionStyle)`
  flex: 1;
  position: fixed;
  bottom: 0;
  background-color: #f4f4f5;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  border-top: ${(props) => (props.isAtBottom ? 'none' : '1px solid #e0e0e0')};
  height: auto;

  div {
    max-width: 540px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    gap: 1.5rem;

    button:last-child {
      flex: 1;
    }

    &:not(:only-child):not(:last-child) {
      padding: 0;
      padding-top: 1rem;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 768px) {
      padding: 1rem;
      height: auto;
      gap: 0.5rem;
      justify-content: flex-start;
      button:last-child {
        flex: 1;
      }
    }

    @media screen and (max-width: 470px) {
      button:last-child {
        flex: unset;
      }
    }
  }
`;

export const ApplicationConsentCheckboxStyles = styled(Checkbox)`
  margin-bottom: 0rem;
  padding: 0;

  div {
    padding: 0;
    margin-left: 0.5rem;
  }

  span > svg {
    width: unset;
    height: unset;
  }
  @media screen and (max-width: 768px) {
    margin-left: 1rem;
    height: auto;
  }
`;
