import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCompanyHeader } from '../CompanyModal/CompanyModal.style';
import { CompanyInfoCard } from './Components/CompanyInfoCard/CompanyInfoCard';
import { EncouragementBanner } from './Components/EncouragementBanner/EncouragementBanner';
import { Button } from '@single-platform/button';
import {
  BoldedText,
  ButtonContainer,
  LinkButtonStyles,
  WhyUsTextContainer
} from './CompanyModal.style';
import { CompanyRepresentativeContainer } from '../CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer.style';
import { Modal } from '@single-platform/components';

export const DataExchangeModal = ({ onCancel, onContinue }) => {
  const [isWhyAskModalOpen, setIsWhyAskModalOpen] = useState(false);
  const { t } = useTranslation();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const onClickHandler = () => {
    setIsWhyAskModalOpen(true);
  };

  const handleWhyAskClose = () => {
    setIsWhyAskModalOpen(false);
  };

  return (
    <>
      <CompanyRepresentativeContainer>
        <ModalCompanyHeader>{t('represented_company')}</ModalCompanyHeader>
        <CompanyInfoCard />
        <EncouragementBanner />
        <p>
          {t('agree_on_terms_and_conditions', {
            organisation: isBank ? 'Finance' : 'Bank'
          })}
        </p>
        <LinkButtonStyles onClick={onClickHandler}>{t('why_do_we_ask_text')}</LinkButtonStyles>

        <ButtonContainer>
          <Button variant="Stroked" color="Black" onClick={onCancel}>
            {t('button_disagree')}
          </Button>
          <Button onClick={onContinue}>{t('button_agree')}</Button>
        </ButtonContainer>
      </CompanyRepresentativeContainer>
      {isWhyAskModalOpen && (
        <Modal
          hideSecondaryAction={true}
          label={t('choose_company_title')}
          onCancel={handleWhyAskClose}
          isOpen={isWhyAskModalOpen}
          footer={
            <ButtonContainer>
              <Button variant="Stroked" color="Black" onClick={handleWhyAskClose}>
                {t('button_cancel')}
              </Button>
            </ButtonContainer>
          }
        >
          <ModalCompanyHeader>{t('represented_company')}</ModalCompanyHeader>
          <WhyUsTextContainer>
            <span>
              <BoldedText>{t('modal_data_exchange_attention')}</BoldedText>
              {t('modal_data_exchange_terms', {
                companyCode: isBank ? '304254910' : '305223469',
                organisation: isBank ? 'Finance' : 'Bank'
              })}
            </span>
          </WhyUsTextContainer>
        </Modal>
      )}
    </>
  );
};
