import React from 'react';
import { numberFormat } from '../../../Utilities';
import { Dropdown, Field, Input } from '@single-platform/components';
import { Button } from '@single-platform/button';
import { OrgStuctureFormStyles } from './OrgStructureForm.style';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormValidation } from '../LegalEntityQuestionnaire/validation/useFormValidation';

const OrgStructureForm = ({ submitForm, level, parentIndex, parentId }) => {
  const { t } = useTranslation();

  const initialValues = {
    type: '',
    share: '',
    title: ''
  };

  const validationSchema = Yup.object({
    type: Yup.string().required(t('validation_required_field')),
    share: Yup.string().required(t('validation_required_field')),
    title: Yup.string().required(t('validation_required_field'))
  });

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormValidation({
    initialValues,
    validationSchema
  });

  const onSubmit = async () => {
    await handleSubmit(
      (data) => {
        const result = Object.assign({}, data);
        if (data)
          submitForm?.(result.type, result.title, +result.share, level, parentIndex, parentId);
      },
      // on invalid case
      (data) => {
        setTouchedOnAll();
      }
    )();
  };

  return (
    <OrgStuctureFormStyles>
      <Field
        name={'type'}
        Component={Dropdown}
        options={[
          { label: t('choices_recipient_type_in_ru_or_by_natural_person'), value: 'p' },
          { label: t('choices_recipient_type_in_ru_or_by_legal_entity'), value: 'c' }
        ]}
        placeholder={t('question_payments_to_ru_or_by_citizens')}
        value={getValues('type')}
        validationHelpers={validationHelpers}
      />
      <Field
        name={'title'}
        Component={Input}
        value={getValues('title')}
        placeholder={t('org_structure_title')}
        validationHelpers={validationHelpers}
      />
      <Field
        name={'share'}
        type={'number'}
        Component={Input}
        value={getValues('share')}
        placeholder={t('share')}
        formatNumber={numberFormat().percent}
        validationHelpers={validationHelpers}
      />
      <Button onClick={onSubmit}>{t('repeatable_group.add_button')}</Button>
    </OrgStuctureFormStyles>
  );
};

export default OrgStructureForm;
