import React, { useEffect, useState } from 'react';
import { Input, Field } from '@single-platform/components';
import { useTranslation } from 'react-i18next';
import HintModal from '../../../components/hintModal/hintModal';
import { HintFieldContainer } from '../../../components/hintModal/hintModal.styles';
import { QuestionSubtitle, QuestionTitle, QuestionTitleContainer } from '../renderGroup.style';

export const RenderQuestion = ({
  question,
  name,
  validationHelpers,
  onChange,
  value,
  onQuestionChange,
  disabled
}) => {
  const [inputType, setInputType] = useState('text');
  const [inputValue, setInputValue] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value);
    setInputType(() => question.type.toLowerCase());
    onQuestionChange(question, 'add');
    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [value]);

  return (
    <HintFieldContainer>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <Field
        Component={Input}
        name={name}
        id={name}
        type={inputType}
        disabled={question.disabled || disabled}
        onChange={onChange}
        validateOnBlur
        value={inputValue}
        placeholder={
          question.required
            ? t(question.title)
            : t('placeholderOptional', {
                placeholder: t(question.title)
              }) + t('question_optional_label')
        }
        required={question.required}
        validationHelpers={validationHelpers}
        key={question.id}
      />
      <HintModal hint={question.hint ? question.hint : ''} />
    </HintFieldContainer>
  );
};
