import { DownloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TemplateDownload = () => {
  const [fileData, setFileData] = useState({});
  const { t } = useTranslation();
  const language = localStorage.getItem('language') || 'lt-LT';
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const getFileData = () => {
    if (isBank) {
      setFileData({
        href:
          language === 'lt-LT'
            ? `/doc/Igaliojimas_saskaitos_atidarymui_SMEB.doc`
            : `/doc/Igaliojimas_saskaitos_atidarymui_SMEB_EN.docx`,
        download:
          language === 'lt-LT'
            ? 'Igaliojimas_saskaitos_atidarymui.doc'
            : 'PoA_Template_for_account_opening.docx'
      });
    } else {
      setFileData({
        href:
          language === 'lt-LT'
            ? '/doc/Igaliojimas_saskaitos_atidarymui_SMEF.doc'
            : '/doc/Igaliojimas_saskaitos_atidarymui_SMEF.doc',
        download:
          language === 'lt-LT'
            ? 'Igaliojimas_saskaitos_atidarymui_SMEF.doc'
            : 'PoA_Template_for_account_opening_SMEF.docx'
      });
    }
  };

  useEffect(() => getFileData(), []);

  return (
    <div>
      <a href={fileData.href} download={fileData.download}>
        <DownloadOutlined className="mr-2" />
        <b>{t('provide_auth_template_text')}</b>
      </a>
    </div>
  );
};
