import React from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveOwnerComp } from './RemoveOwnerComp';

export const RenderPerson = ({ data }) => {
  const {
    title,
    share,
    level,
    isNotTheLast,
    className,
    index,
    parentIndex,
    parentId,
    id,
    isAddOwnerOn,
    onRemoveClick
  } = data;
  const { t } = useTranslation();

  return (
    <div key={index} className={'owners ' + className}>
      <div className="top">
        <div className="left">
          <div className="dot dot-person">
            {isAddOwnerOn?.level === level &&
            ((!isAddOwnerOn.index && isAddOwnerOn.index !== 0) ||
              isAddOwnerOn.index === parentIndex) &&
            isAddOwnerOn.id === parentId ? (
              <div className="corner outside" />
            ) : (
              <div className={isNotTheLast ? 'line' : ''} />
            )}
          </div>
        </div>
        <div className="middle">
          <div>
            <div className="owners-name">{title}</div>
            <div className="owners-type">
              {t('choices_recipient_type_in_ru_or_by_natural_person')}
            </div>
          </div>
          <div className="right">
            <div className="percent">{share}%</div>
            <div data-html2canvas-ignore="true">
              <RemoveOwnerComp id={id} onRemoveClick={onRemoveClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
