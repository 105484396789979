import { Checkbox } from '@single-platform/components';
import styled from 'styled-components';

export const CheckBoxGroupTitleStyles = styled.p`
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const CheckboxStyles = styled(Checkbox)`
  span > svg {
    width: auto;
  }
`;
