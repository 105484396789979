import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomePageClientTextStyles,
  HomePageInformationContainer,
  ImageWrapper
} from '../../IndexContainer/indexContainer.styles';

export const KycComplete = React.memo(({ translation }) => {
  const { t } = useTranslation();
  const environment = process.env.REACT_APP_TRADEMARK;
  return (
    <HomePageInformationContainer row>
      <HomePageClientTextStyles>
        {t(translation, { environment: environment.includes('Bank') ? 'SME Bank' : 'SME Finance' })}{' '}
      </HomePageClientTextStyles>
      <ImageWrapper>
        <img
          src="/images/slider/onboarding_complete.png"
          className="w-80"
          alt="onboarding complete logo"
        />
      </ImageWrapper>
    </HomePageInformationContainer>
  );
});
