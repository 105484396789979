import { colors } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const GroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: ${({ isCheckBoxGroup }) => (isCheckBoxGroup ? 'fit-content' : '100%')};
  gap: 1rem;
`;

export const GroupItems = styled.div`
  align-self: center;
  max-width: 100%;
  width: ${(props) => props.isGroup && '100%'};
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex: 0 0 100%;

  label {
    margin-bottom: 0px;
    span {
      word-break: break-word;
    }
  }
`;

export const GroupTitleContainer = styled.div`
  width: 100%;
`;

export const GroupTitle = styled.h4`
  margin-bottom: ${({ hasSubtitle }) => (hasSubtitle ? '0.5rem' : '1rem')};
  word-break: break-word;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  color: ${() => colors.base.black[90]};
`;

export const GroupSubtitle = styled.h5`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${() => colors.base.black[60]};
  margin-bottom: 1rem;
`;

export const QuestionTitle = styled.h4`
  margin-bottom: ${({ hasSubtitle }) => (hasSubtitle ? '0.5rem' : '1rem')};
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  color: ${() => colors.base.black[90]};
`;

export const QuestionSubtitle = styled.h5`
  margin-bottom: ${({ hasSubtitle }) => (hasSubtitle ? '0.5rem' : '1rem')};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${() => colors.base.black[60]};
`;

export const QuestionTitleContainer = styled.div`
  margin-top: 1rem;
`;
