import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AuthActions } from '@redux';
import { getFormattedLanguageFromStorage } from '@utilities';
import { history, routerPath } from '@config';
import { logError } from '../../Utilities';
import { useTranslation } from 'react-i18next';
import { Button } from '@single-platform/button';
import {
  HomePageClientTextStyles,
  HomePageHeaderStyles,
  HomePageInformationContainer,
  ImageWrapper,
  TextAndButtonsContainer
} from './indexContainer.styles';
import { setUrlQueryParameter } from '../ApplicationKYC/LegalEntityQuestionnaire/hooks/setUrlQueryParameter';
import i18next from 'i18next';

export const IndexContainer = () => {
  const [step, setStep] = useState(0);
  const [isBankApplication, setIsBankApplication] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nextStep = () => {
    setStep(1);
    setUrlQueryParameter('WhyUs');
  };

  const getIdentificationUrl = async () => {
    const windowReference = window.open();
    const language = getFormattedLanguageFromStorage();
    try {
      const response = await dispatch(AuthActions.getIdentificationUrl(language));
      windowReference.location = response?.payload?.redirectUrl;
    } catch (e) {
      logError(e);
    }
  };

  useEffect(() => {
    dispatch(AuthActions.reset());
    setIsBankApplication(process.env.REACT_APP_TRADEMARK.includes('Bank'));
    if (step === 0) setUrlQueryParameter('Intro');
    i18next.changeLanguage(i18next.language);
  }, [i18next.language]);

  return (
    <>
      {step === 0 && (
        <>
          <div>
            <HomePageHeaderStyles>
              {isBankApplication ? t('home_page_header_bank') : t('home_page_header')}
            </HomePageHeaderStyles>
            <HomePageClientTextStyles>
              {t('home_page_client_text')}
              <br />
              <br />
              {t('home_page_process_text')}
            </HomePageClientTextStyles>
            <div className="mt-md-3">
              <Button
                htmlType="submit"
                size="large"
                style={{ padding: '0 3.5625rem' }}
                onClick={nextStep}
              >
                {t('homeButtonText')}
              </Button>
            </div>
          </div>
          <ImageWrapper>
            <img src="/images/slider/onboarding_first.png" alt="start onboarding icon" />
          </ImageWrapper>
        </>
      )}
      {step === 1 && (
        <HomePageInformationContainer>
          <TextAndButtonsContainer>
            <div>
              <HomePageHeaderStyles>
                {isBankApplication
                  ? t('home_second_step_header_bank')
                  : t('home_second_step_header')}
              </HomePageHeaderStyles>
              <HomePageClientTextStyles>
                {t('home_first_need_verify_your_identity')}
              </HomePageClientTextStyles>
              <Button onClick={getIdentificationUrl} style={{ marginBottom: 20 }}>
                {t('identification_redirect_button')}
              </Button>

              <HomePageClientTextStyles>
                {isBankApplication
                  ? t('home_second_step_provide_documents_bank')
                  : t('home_second_step_provide_documents')}
              </HomePageClientTextStyles>

              <Button onClick={() => history.push(routerPath.login)}>
                {t('sign_in_button_text')}
              </Button>
            </div>
            <ImageWrapper>
              <img src="/images/slider/onboarding_second.png" alt="start onboarding icon" />
            </ImageWrapper>
          </TextAndButtonsContainer>
          <HomePageClientTextStyles>
            <br />
            <br />
            {t('home_second_steps_for_completing_questionnaire_heading')}
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              {t('home_second_steps_for_completing_questionnaire')
                .split('|')
                .map((steps) => (
                  <div key={steps}>
                    <b>{steps}</b>
                  </div>
                ))}
            </div>
            <div style={{ marginBottom: 30 }}>
              {isBankApplication
                ? t('home_second_if_additional_documents_bank')
                : t('home_second_if_additional_documents')}
            </div>
            <div>{t('home_second_legal_text')}</div>
          </HomePageClientTextStyles>
        </HomePageInformationContainer>
      )}
    </>
  );
};
