import formatThousands from 'format-thousands';
import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const CreateSchema = function () {
  let temp = [];
  const build = (fieldId, fieldType, choices, t, minValue, maxValue, maxLength, minLength) => {
    switch (fieldType) {
      case 'Dropdown':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_required_field'), (value) => {
                return choices.map((choice) => choice.value).includes(value);
              })
          }
        ];
        break;
      case 'Multiselect':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_required_field'), (value = '') => {
                const values = value.split('|');
                const choicesValues = choices.map((choice) => choice.value);
                return values.every((value) => choicesValues.includes(value));
              })
          }
        ];
        break;
      case 'Phone':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_incorrect_number'), (value = '') => {
                return isPossiblePhoneNumber(value);
              })
          }
        ];
        break;
      case 'CheckboxGroup':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string().test('test', t('validation_must_be_on_of'), (value = '') => {
              const values = value.split('|');
              const choicesValues = choices.map((choice) => choice.value);
              return values.some((value) => choicesValues.includes(value));
            })
          }
        ];
        break;
      case 'DateFromToday':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_date_entered_must_be_future_date'), (date = '') => {
                const newDate = new Date(date);
                return newDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
              })
          }
        ];
        break;
      case 'DateUntilToday':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test(
                'test',
                t('validation_date_entered_must_be_no_further_than_today'),
                (date = '') => {
                  const newDate = new Date(date);
                  return newDate.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
                }
              )
          }
        ];
        break;
      case 'Email':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .email(t('validation_email_is_not_valid'))
              .test('test', t('validation_field_too_big', { maxLength: maxLength }), (email) => {
                if (maxLength) {
                  return maxLength > email?.length;
                } else {
                  return true;
                }
              })
          }
        ];
        break;
      case 'Number':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test(
                'test',
                t('validation_number_field_too_big', { maxValue: formatThousands(maxValue, ',') }),
                (number) => {
                  if (maxValue) {
                    const parsedNumber = parseInt(String(number).replace(/,/g, ''));
                    return !(parsedNumber > maxValue);
                  } else {
                    return true;
                  }
                }
              )
              .test('test', t('validation_number_field_too_small', { minValue }), (number) => {
                if (minValue) {
                  const parsedNumber = parseInt(String(number).replace(/,/g, ''));
                  return !(parsedNumber < minValue);
                } else {
                  return true;
                }
              })
          }
        ];
        break;
      case 'Checkbox':
        temp = [...temp, { [fieldId]: Yup.boolean().optional() }];
        break;
      case 'TextArea':
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_field_too_big', { maxLength: maxLength }), (text) => {
                if (maxLength) {
                  return maxLength > text?.length;
                } else {
                  return true;
                }
              })
              .test('test', t('validation_field_too_small', { minLength: minLength }), (text) => {
                if (minLength) {
                  return text?.length >= minLength;
                } else {
                  return true;
                }
              })
          }
        ];
        break;
      default:
        temp = [
          ...temp,
          {
            [fieldId]: Yup.string()
              .required(t('validation_required_field'))
              .test('test', t('validation_field_too_big', { maxLength: maxLength }), (text) => {
                if (maxLength) {
                  return maxLength > text?.length;
                } else {
                  return true;
                }
              })
          }
        ];
        break;
    }
  };

  const create = () => {
    const validationScheme = Object.assign({}, ...temp);
    return Yup.object(validationScheme);
  };

  return {
    build,
    create
  };
};

export default CreateSchema;
