import React from 'react';
import { Placeholder, PlaceholderWrapper } from './placeholderWrapper.style';
import { useTranslation } from 'react-i18next';

export const PlaceholderValue = ({ children, placeholder, hasValue, mobileInput, required }) => {
  const { t } = useTranslation();
  return (
    <PlaceholderWrapper>
      {hasValue && (
        <Placeholder mobileInput={mobileInput}>
          {!required ? t(placeholder) + t('question_optional_label') : t(placeholder)}
        </Placeholder>
      )}
      {children}
    </PlaceholderWrapper>
  );
};
