import {
  aPIPaths,
  handleResponse,
  methods,
  requestJSON,
  requestWithToken,
  requestAuth
} from '@redux';

export const AuthServices = {
  startLogin,
  getAuthorizedCompanies,
  selectIdentification,
  getIdentificationUrl,
  refreshToken,
  smartIdInit,
  mobileIdInit,
  smartIdLogin,
  mobileIdLogin,
  authorizationStatus,
  otpAuthorization,
  biometricsInit,
  eideasyInit,
  getCompanies,
  dataExchangeCheck,
  authorizationStatusEIdEasy,
  addEmptyIdentification,
  refreshTokenWithoutSession,
  getRepresentativeInformation,
  getExternalPersonInformation,
  startExternalPersonVerification,
  startRepresentativeChangeVerification
};

function startLogin(data) {
  return requestJSON(aPIPaths.startLogin, {
    method: methods.post,
    data: data
  }).then(handleResponse);
}

function getAuthorizedCompanies(isWaitingRoom) {
  if (isWaitingRoom) {
    return requestWithToken(aPIPaths.GetAuthorizedCompanies).then(handleResponse);
  }
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  requestWithToken.defaults.headers['Authorization'] = `Bearer ${sessionToken.accessToken}`;
  return requestWithToken(aPIPaths.GetAuthorizedCompanies).then(handleResponse);
}

function selectIdentification(data) {
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  requestJSON.defaults.headers['Authorization'] = `Bearer ${sessionToken.accessToken}`;
  return requestJSON(aPIPaths.selectIdentification, {
    method: methods.post,
    data: data
  }).then(handleResponse);
}

function addEmptyIdentification() {
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  requestAuth.defaults.headers['Authorization'] = `Bearer ${sessionToken.accessToken}`;
  return requestAuth(aPIPaths.addEmptyIdentification, {
    method: methods.post,
    data: JSON.stringify({
      authType: 'SmartId',
      country: 'Lithuania'
    })
  }).then(handleResponse);
}

function getIdentificationUrl(language) {
  const queryParams = new URLSearchParams({ language });
  return requestJSON(`${aPIPaths.getIdentificationUrl}?${queryParams}`).then(handleResponse);
}

function smartIdInit(data) {
  return requestAuth(aPIPaths.smartIdInit, {
    method: methods.post,
    data
  }).then(handleResponse);
}

function biometricsInit(data) {
  return requestAuth(aPIPaths.biometricsInit, {
    method: methods.post,
    data
  }).then(handleResponse);
}

function mobileIdInit(data) {
  return requestAuth(aPIPaths.mobileIdInit, {
    method: methods.post,
    data
  }).then(handleResponse);
}

function smartIdLogin(sessionId) {
  return requestAuth(aPIPaths.smartIdLogin.replace('{sessionId}', sessionId), {
    method: methods.get
  }).then(handleResponse);
}

function mobileIdLogin(sessionId) {
  return requestAuth(aPIPaths.mobileIdLogin.replace('{sessionId}', sessionId), {
    method: methods.get
  }).then(handleResponse);
}

function authorizationStatus(token) {
  requestAuth.defaults.headers['Authorization'] = `Bearer ${token}`;
  requestAuth.defaults.withCredentials = true;
  return requestAuth(aPIPaths.authorizationStatus, {
    method: methods.get
  }).then(handleResponse);
}

function authorizationStatusEIdEasy(data) {
  const authData = JSON.parse(localStorage.getItem('eidEasy'));
  requestAuth.defaults.headers['Authorization'] = `Bearer ${authData.accessToken}`;
  requestAuth.defaults.withCredentials = true;
  return requestAuth(aPIPaths.eideasyStatus, {
    method: methods.post,
    data: JSON.stringify(data)
  }).then(handleResponse);
}

function otpAuthorization(data) {
  requestAuth.defaults.withCredentials = true;
  return requestAuth(aPIPaths.otpAuthorization, {
    method: methods.post,
    data: JSON.stringify({ code: data })
  }).then(handleResponse);
}

function eideasyInit(data) {
  requestAuth.defaults.withCredentials = true;
  return requestAuth(aPIPaths.eideasyInit, {
    method: methods.post,
    data: data
  }).then(handleResponse);
}

function refreshToken() {
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  const sessionId = sessionStorage.getItem('loginSessionId') || '';

  requestAuth.defaults.headers['Authorization'] = `Bearer ${sessionToken.refreshToken}`;
  requestAuth.defaults.withCredentials = true;
  return requestAuth(aPIPaths.refreshToken, {
    method: methods.post,
    data: JSON.stringify({
      refreshToken: sessionToken.refreshToken,
      sessionId
    })
  }).then(handleResponse);
}

function refreshTokenWithoutSession() {
  const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
  requestAuth.defaults.headers['Authorization'] = `Bearer ${sessionToken.refreshToken}`;
  requestAuth.defaults.withCredentials = true;
  return requestAuth(
    aPIPaths.refreshTokenWithoutSession.replace('{refreshToken}', sessionToken.refreshToken),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function getCompanies(companyName) {
  return requestJSON(aPIPaths.getCompanies.replace('{companyName}', companyName), {
    method: methods.get
  }).then(handleResponse);
}

function dataExchangeCheck(companyCode, country) {
  return requestJSON(
    aPIPaths.dataExchangeCheck.replace('{companyCode}', companyCode).replace('{country}', country),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function getRepresentativeInformation(appId) {
  return requestWithToken(
    aPIPaths.getRepresentativeInformation.replace('{applicationId}', appId)
  ).then(handleResponse);
}

function getExternalPersonInformation(groupId, externalPersonId) {
  return requestWithToken(
    aPIPaths.getExternalPersonInformation
      .replace('{groupId}', groupId)
      .replace('{externalPersonId}', externalPersonId)
  ).then(handleResponse);
}

function startExternalPersonVerification(groupId, externalPersonId, language) {
  return requestWithToken(
    aPIPaths.startExternalPersonVerification
      .replace('{groupId}', groupId)
      .replace('{externalPersonId}', externalPersonId)
      .replace('{language}', language)
  ).then(handleResponse);
}

function startRepresentativeChangeVerification(applicationId, language) {
  return requestWithToken(
    aPIPaths.startRepresentativeChangeVerification
      .replace('{applicationId}', applicationId)
      .replace('{language}', language)
  ).then(handleResponse);
}
