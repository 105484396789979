export const setUrlQueryParameter = (searchParams) => {
  const url = new URL(window.location.href);
  url.searchParams.set('step', searchParams);
  history.pushState({}, '', url.toString());
};

export const getUrlQueryParameter = (parameter) => {
  const url = new URL(window.location.href);
  const step = url.searchParams.get(parameter);
  return step;
};
