import { saveAs } from 'file-saver';
import moment from 'moment';
import * as XLSX from 'xlsx';

import { STORAGE_ITEMS } from '@config';

export const strPadLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

export const secondsToMinutesWithSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;

  const finalTime = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);
  return finalTime;
};

export const getFormattedLanguageFromStorage = () => {
  const language = localStorage.getItem(STORAGE_ITEMS.LANGUAGE);
  return language ? language.split('-')[0] : 'lt';
};

export const updateArrElementById = (arr, newElement) => {
  if (!arr) return [];

  const arrClone = [...arr];
  const elementToEditIdx = arrClone.findIndex((el) => el.id === newElement.id);
  if (elementToEditIdx >= 0) {
    arrClone.splice(elementToEditIdx, 1, { ...newElement });
  }
  return arrClone;
};

export const writeExcelFile = ({ items, fileName, colWidths }) => {
  const fileDate = moment(new Date()).format('DD-MM-YYYY HH-mm-ss');
  const sheetName = `${fileName} ${fileDate}.xlsx`;
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(items, { header: false });
  workSheet['!cols'] = colWidths;
  XLSX.utils.book_append_sheet(workBook, workSheet, fileName);
  XLSX.writeFile(workBook, sheetName);
};

export const downloadFile = ({ fileId, fileName, url }) => {
  const oReq = new XMLHttpRequest();
  oReq.open('GET', `${url}/${fileId}`, true);
  oReq.responseType = 'blob';
  // When the file request finishes
  // Is up to you, the configuration for error events etc.
  oReq.onload = () => {
    const file = new Blob([oReq.response], {
      type: oReq.response.type
    });
    // Generate file download directly in the browser !
    saveAs(file, fileName);
  };
  oReq.send();
};

export const b64DecodeUnicode = (base64) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  const text = window.atob(base64);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder(); // default is utf-8
  return decoder.decode(bytes);
};
