export const ToolsConstants = {
  POST_GENERATE_MARK_ID_PAYLOAD_REQUEST: 'POST_GENERATE_MARK_ID_PAYLOAD_REQUEST',
  POST_GENERATE_MARK_ID_PAYLOAD_SUCCESS: 'POST_GENERATE_MARK_ID_PAYLOAD_SUCCESS',
  POST_GENERATE_MARK_ID_PAYLOAD_ERROR: 'POST_GENERATE_MARK_ID_PAYLOAD_ERROR',

  GET_COMPANY_NAMES_REQUEST: 'POST_SEND_Tools_APPLICATION_REQUEST',
  GET_COMPANY_NAMES_SUCCESS: 'GET_COMPANY_NAMES_SUCCESS',
  GET_COMPANY_NAMES_ERROR: 'GET_COMPANY_NAMES_ERROR',

  POST_START_ONDATO_IDENTIFICATION_REQUEST: 'POST_START_ONDATO_IDENTIFICATION_REQUEST',
  POST_START_ONDATO_IDENTIFICATION_SUCCESS: 'POST_START_ONDATO_IDENTIFICATION_SUCCESS',
  POST_START_ONDATO_IDENTIFICATION_ERROR: 'POST_START_ONDATO_IDENTIFICATION_ERROR',

  CLEAR_ALL_PAYLOAD: 'CLEAR_ALL_PAYLOAD'
};
