import { AuthServices } from './services';
import { AuthConstants } from './constants';
import { LOGIN_TYPES } from '../../../Config';
import { STORAGE_ITEMS } from '../../../Config/storage';
import { QuestionnaireConstants } from '../Questionnaire';
import { OnboardingFlowConstants } from '../OnboardingFlowHandler/constants';
import { Logout } from '../../../Utilities';

export const AuthActions = {
  startLogin,
  getAuthorizedCompanies,
  selectIdentification,
  reset,
  getIdentificationUrl,
  refreshToken,
  smartIdInit,
  mobileIdInit,
  authorizationStatus,
  otpAuthorization,
  biometricsInit,
  eideasyInit,
  getCompanies,
  dataExchangeCheck,
  authorizationStatusEIdEasy,
  addEmptyIdentification,
  initiatedSSOLogin,
  resetSSOLogin,
  refreshTokenWithoutSession,
  getRepresentativeInformation,
  getExternalPersonInformation,
  startExternalPersonVerification,
  startRepresentativeChangeVerification
};

function startLogin(loginData) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.START_LOGIN_REQUEST });

    return AuthServices.startLogin(loginData).then(
      (data) => {
        dispatch({ type: AuthConstants.START_LOGIN_SUCCESS, payload: data });
        if (loginData.authType === LOGIN_TYPES.BIOMETRICS) {
          dispatch({ type: AuthConstants.BIOFACE_LOGIN_SIDE_EFFECTS });
          localStorage.setItem(STORAGE_ITEMS.LOGIN_TOKEN, data.accessToken);
          localStorage.setItem(STORAGE_ITEMS.SCA_TIMEOUT, data.scaTimeout);
          window.location = data.redirectUrl;
        }
      },
      (error) => {
        dispatch({ type: AuthConstants.START_LOGIN_ERROR, error });
        throw error;
      }
    );
  };
}

function getAuthorizedCompanies(isWaitingRoom = false) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.GET_AUTHORISED_COMPANIES_REQUEST });

    return AuthServices.getAuthorizedCompanies(isWaitingRoom).then(
      (data) => dispatch({ type: AuthConstants.GET_AUTHORISED_COMPANIES_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.GET_AUTHORISED_COMPANIES_ERROR, error });
        throw error;
      }
    );
  };
}

function selectIdentification(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.SELECT_IDENTIFICATION_REQUEST });

    return AuthServices.selectIdentification(data).then(
      (data) => dispatch({ type: AuthConstants.SELECT_IDENTIFICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: AuthConstants.SELECT_IDENTIFICATION_ERROR, error })
    );
  };
}

function addEmptyIdentification() {
  return (dispatch) => {
    return AuthServices.addEmptyIdentification().then(
      (data) => dispatch({ type: AuthConstants.ADD_EMPTY_IDENTIFICATION_REQUEST, payload: data }),
      (error) => dispatch({ type: AuthConstants.ADD_EMPTY_IDENTIFICATION_REQUEST, error })
    );
  };
}

function reset() {
  return (dispatch) => {
    dispatch({ type: AuthConstants.RESET_LOGIN });
    dispatch({ type: QuestionnaireConstants.RESET_QUESTIONNAIRE_STATE });
    dispatch({ type: OnboardingFlowConstants.RESET_QUESTIONNAIRE_FLOW_STATE });
  };
}

function getIdentificationUrl(language) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.GET_IDENTIFICATION_URL_REQUEST });

    return AuthServices.getIdentificationUrl(language).then(
      (data) => dispatch({ type: AuthConstants.GET_IDENTIFICATION_URL_SUCCESS, payload: data }),
      (error) => dispatch({ type: AuthConstants.GET_IDENTIFICATION_URL_ERROR, error })
    );
  };
}

function smartIdInit(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.SMARTID_REQUEST });
    return AuthServices.smartIdInit(data).then(
      (data) => dispatch({ type: AuthConstants.SMARTID_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.SMARTID_ERROR, error });
        throw error;
      }
    );
  };
}

function biometricsInit(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.BIOMETRICS_REQUEST });
    return AuthServices.biometricsInit(data).then(
      (data) => dispatch({ type: AuthConstants.BIOMETRICS_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.BIOMETRICS_ERROR, error });
        throw error;
      }
    );
  };
}

function mobileIdInit(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.MOBILEID_REQUEST });
    return AuthServices.mobileIdInit(data).then(
      (data) => dispatch({ type: AuthConstants.MOBILEID_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.MOBILEID_ERROR, error });
        throw error;
      }
    );
  };
}

function authorizationStatus(token) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.AUTHORIZATION_STATUS_REQUEST });
    return AuthServices.authorizationStatus(token).then(
      (data) => dispatch({ type: AuthConstants.AUTHORIZATION_STATUS_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.AUTHORIZATION_STATUS_ERROR, error });
        throw error;
      }
    );
  };
}

function authorizationStatusEIdEasy(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.EIDEASY_STATUS_REQUEST });
    return AuthServices.authorizationStatusEIdEasy(data).then(
      (data) => dispatch({ type: AuthConstants.EIDEASY_STATUS_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.EIDEASY_STATUS_ERROR, error });
        throw error;
      }
    );
  };
}

function otpAuthorization(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.OTP_AUTORIZATION_REQUEST });
    return AuthServices.otpAuthorization(data).then(
      (data) => dispatch({ type: AuthConstants.OTP_AUTORIZATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.OTP_AUTORIZATION_ERROR, error });
        throw error;
      }
    );
  };
}

function refreshToken() {
  return (dispatch) => {
    dispatch({ type: AuthConstants.REFRESH_TOKEN_REQUEST });
    return AuthServices.refreshToken().then(
      (data) => dispatch({ type: AuthConstants.REFRESH_TOKEN_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.REFRESH_TOKEN_ERROR, error });
        Logout('error');
        throw error;
      }
    );
  };
}
function refreshTokenWithoutSession() {
  return (dispatch) => {
    dispatch({ type: AuthConstants.REFRESH_TOKEN_REQUEST });
    return AuthServices.refreshTokenWithoutSession().then(
      (data) => dispatch({ type: AuthConstants.REFRESH_TOKEN_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.REFRESH_TOKEN_ERROR, error });
        Logout('error');
        throw error;
      }
    );
  };
}

function getCompanies(companyName) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.GET_COMPANIES_REQUEST });
    return AuthServices.getCompanies(companyName).then(
      (data) => dispatch({ type: AuthConstants.GET_COMPANIES_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.GET_COMPANIES_ERROR, error });
        throw error;
      }
    );
  };
}

function eideasyInit(data) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.EIDEASY_INIT_REQUEST });
    return AuthServices.eideasyInit(data).then(
      (data) => dispatch({ type: AuthConstants.EIDEASY_INIT_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.EIDEASY_INIT_ERROR, error });
        throw error;
      }
    );
  };
}

function dataExchangeCheck(companyCode, country) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.DATA_EXCHANGE_REQUEST });
    return AuthServices.dataExchangeCheck(companyCode, country).then(
      (data) => dispatch({ type: AuthConstants.DATA_EXCHANGE_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.DATA_EXCHANGE_ERROR, error });
        throw error;
      }
    );
  };
}

function initiatedSSOLogin() {
  return (dispatch) => {
    dispatch({ type: AuthConstants.INITIATED_SSO_LOGIN });
  };
}

function resetSSOLogin() {
  return (dispatch) => {
    dispatch({ type: AuthConstants.RESET_SSO_LOGIN });
  };
}

function getRepresentativeInformation(appId) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.GET_REPRESENTATIVE_INFORMATION_REQUEST });
    return AuthServices.getRepresentativeInformation(appId).then(
      (data) =>
        dispatch({ type: AuthConstants.GET_REPRESENTATIVE_INFORMATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.GET_REPRESENTATIVE_INFORMATION_ERROR, error });
        throw error;
      }
    );
  };
}
function getExternalPersonInformation(groupId, externalPersonId) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.GET_EXTERNAL_PERSON_INFORMATION_REQUEST });
    return AuthServices.getExternalPersonInformation(groupId, externalPersonId).then(
      (data) =>
        dispatch({ type: AuthConstants.GET_EXTERNAL_PERSON_INFORMATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.GET_EXTERNAL_PERSON_INFORMATION_ERROR, error });
        throw error;
      }
    );
  };
}

function startExternalPersonVerification(groupId, externalPersonId, language) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.START_PERSON_VERIFICATION_REQUEST });
    return AuthServices.startExternalPersonVerification(groupId, externalPersonId, language).then(
      (data) => dispatch({ type: AuthConstants.START_PERSON_VERIFICATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.START_PERSON_VERIFICATION_ERROR, error });
        throw error;
      }
    );
  };
}

function startRepresentativeChangeVerification(applicationId, language) {
  return (dispatch) => {
    dispatch({ type: AuthConstants.START_REPRESENTATIVE_VERIFICATION_REQUEST });
    return AuthServices.startRepresentativeChangeVerification(applicationId, language).then(
      (data) =>
        dispatch({ type: AuthConstants.START_REPRESENTATIVE_VERIFICATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: AuthConstants.START_REPRESENTATIVE_VERIFICATION_ERROR, error });
        throw error;
      }
    );
  };
}
